import React, { useState } from 'react';
import PageHeader from "../components/Shared/Section/PageHeader";

export default function Blogs() {

  const [headerData, setHeaderdata] = useState({
    mainHeading: 'Blogs',
    subHeadng: '',
    buttonText: ['']
  })

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate=""
      />
    </>
  )
}
