import { Quill } from 'react-quill';
import Cookies from 'universal-cookie';
// import { SideBarIcons } from '../components/Rawdata/Sidebar';
import { CompData } from '../components/Rawdata/TempCompData';

const cookies = new Cookies();

export const setCookies = (name, value, expiryAfterMinutes = 86400) => {
  let expire = new Date();
  // value = JSON.stringify(value)
  expire.setDate(expire.getDate() + expiryAfterMinutes);
  // if (name === 'tcebAdminData')
  //     cookies.set(name, value, { expires: expire, path: '/', secure: true });
  // else
  cookies.set(name, value, { expires: expire, path: '/' });
};

export const getCookies = (name) => {
  let value = cookies.get(name);
  if (value) return value
  else return false;
};

export const removeCookies = (name, options = { path: '/' }) => {
  cookies.remove(name, options);
};

export const validateEmailFormat = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// const handlingSubMenu = (subData, responseData) => {
//     const finalObj = subData.map(subs => {
//         let item
//         let menuarray
//         const submenuArray = subs.menu.split(" ")
//         if (submenuArray.length > 1) {
//             item = submenuArray.join("-").toLowerCase()
//         } else {
//             item = subs.menu.toLowerCase()
//         }
//         const subMenucheck = responseData.filter(sub => sub.parentId === subs.id)
//         let parentItemName = responseData.find(sub => sub.id === subs.parentId).menu
//         if (parentItemName.split(' ').length > 1) {
//             parentItemName = parentItemName.replace(' ', '-')
//         }

//         menuarray = {
//             title: subs.menu,
//             path: `/${parentItemName.toLowerCase()}/${item}`,
//         }
//         // subMenucheck.length > 0 && Object.assign(menuarray, {
//         //     menuItems: {
//         //         title: subs.menu,
//         //         path: `/${parentItemName}/${item}`,
//         //     }
//         // })
//         if (subMenucheck.length > 1) {
//             return handlingSubMenu(subMenucheck, responseData)
//         }
//         return menuarray
//     })
//     return finalObj
// }

// export const FormateSideBarData = (responseData) => {
//     let arrayObj
//     if (Array.isArray(responseData)) {
//         const arrobj = responseData.map((data) => {
//             const { id, menu, parentId } = data
//             if (parentId) return null
//             let item
//             const menuArray = menu.split(" ")
//             if (menuArray.length > 1) {
//                 item = menuArray.join("-").toLowerCase()
//             } else {
//                 item = menu.toLowerCase()
//             }
//             const subData = responseData.filter(sub => sub.parentId === id)
//             const finalObj = {
//                 id,
//                 parentId,
//                 item,
//                 title: menu,
//                 type: subData.length ? "dropdown" : "single",
//                 path: `/${item.toLowerCase()}`,
//                 menuItems: handlingSubMenu(subData, responseData),
//                 icon: SideBarIcons.find(data => data.iconName === menu).icon
//             }
//             return finalObj
//         })
//         arrayObj = arrobj.filter(objs => objs !== null)
//         console.log('finalObject', arrayObj)
//     } else {
//         arrayObj = []
//     }
//     return arrayObj
// }

export const parseUrl = (location, path = 'absolute') => {
  let paths
  const Comp_Name = location.pathname.replace('/', '')
  const Comp_Name_first = Comp_Name.includes('/') ? Comp_Name.split('/')[0] : Comp_Name
  const Comp_Name_last = Comp_Name.includes('/') ? Comp_Name.split('/')[1] : Comp_Name
  paths = path === 'absolute' ? Comp_Name : Comp_Name_last
  if (path === "parent") {
    paths = Comp_Name_first;
  }
  return paths
}

export const buildColumns = (location) => {
  let newColumn = CompData.find(data => data.identity.includes(location))
  newColumn = newColumn.tableData
  return newColumn
}

export const buildFormData = (location, data) => {
  const formData = new FormData();
  let object = {}
  const comp_data = CompData.find(dd => dd.identity.includes(location))
  comp_data.backendFields.forEach(keys => {
    if (keys === 'bg' || keys === 'media') {
      const value = `${location}Image`
      Object.assign(object, { [keys]: data[value] })
      return
    }
    if (keys === 'pageId') {
      Object.assign(object, { pageId: comp_data.pageId })
      return
    }
    Object.assign(object, { [keys]: data[keys] })
  })
  for (let key in object) {
    formData.append(key, object[key])
  }
  return { formData, object }
}

export const formateRecentDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  const formattedToday = dd + '/' + mm + '/' + yyyy;
  return formattedToday
}

export const getComponentItemId = (menu, parent, component) => {
  let itemId;
  const requiredMenuItem = menu.find(x => x.item === parent);
  if (parent === component) { itemId = requiredMenuItem._id }
  else {
    const requiredSubItem = requiredMenuItem.menuItems.find(x => x.path === "/" + component)
    itemId = requiredSubItem._id;
  }
  return itemId;
}

export const getImageUrl = (path) => {
  if (!path) {
    return "";
  }
  const fullUrl = `${process.env.REACT_APP_API_URL}/${path}`;
  return fullUrl;
}

export const getCurrentDate = () => {
  const date = new Date();
  let currentDate = date.toJSON().slice(0, 10);
  return currentDate;
}

export function capitalizeFirstLetter(string) {
  let saperator = (string.includes('-') ? '-' : '_');
  let addSpace = string.split(saperator);
  let captial = addSpace?.map(val => val.charAt(0).toUpperCase() + val.slice(1));
  let final = captial?.join(' ');
  return final;
}

export const emailValidator = (email) => {
  let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (pattern.test(email)) {
    return true
  } else {
    return false
  }
}

export const targetUrlValidator = (value) => {
  let result = value;
  let message = '';
  let checkUpperCase = /[A-Z]/.test(value);
  var checkSpecialCharacters = /[^a-zA-Z0-9 _-]/g.test(value);
  let checkIncludeSpace = value.includes(" ");

  if (checkIncludeSpace) {
    message = 'Space not allowed ...';
    let no_space = value.split(" ").join("");
    result = no_space.trim();
  }
  if (checkSpecialCharacters) {
    let no_space = value.split(" ").join("");
    message = 'Special Characters not allowed ...'
    result = no_space.replace(/[^a-zA-Z0-9 _-]/g, "");
  }
  if (checkUpperCase) {
    message = 'Uppercase not allowed ...';
    result = value.toLowerCase();
  }
  result = result.split(" ").join("");
  result = result.trim();
  result = result.replace(/[^a-zA-Z0-9 _-]/g, "");
  result = result.toLowerCase();

  return { result, message };
}

export function setRowIndex(setPage, setSize) {
  const options = {
    pageStartIndex: 1,
    onSizePerPageChange: (sizePerPage, page) => {
      setSize(sizePerPage);
      setPage(page);
    },
    onPageChange: (page, sizePerPage) => {
      setSize(sizePerPage);
      setPage(page);
    },
  };
  return options;
}

export const generateSlug = (name) => {
  let lCase = name.toLowerCase();
  let removeSpace = lCase.split(' ').join('-');
  let ranNo = Math.floor(Math.random() * 90000) + 10000;
  let slug = `${removeSpace}-${ranNo}`;
  return slug;
}