import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import CardLayout from "../../components/Layout/CardLayout";
import PageHeader from "../../components/Shared/Section/PageHeader";
import useToasts from "../../components/Toast/toastProvider";
import { DataContext } from "../../context/CustomGlobalContext";
import APIService from "../../utils/APIService";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaRegEdit, FaTrashAlt } from "react-icons/fa";
import ModalComp from "../../components/Shared/components/ModalComp";
import SectionBackground from "../../components/Shared/components2/SectionBackground";
import SectionButton from "../../components/Shared/components2/SectionButton";
import SectionImage from "../../components/Shared/components2/SectionImage";
import { getImageUrl, emailValidator, setRowIndex } from "../../utils/Helper";
import TextEditor from "../../components/Shared/components/TextEditor"
const FoundingMembers = () => {

  const { toggleToast } = useToasts();
  const { setPageData } = useContext(DataContext);

  const [tablePage, setTablePage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const paginationOption = setRowIndex(setTablePage, setSizePerPage);
  const [buttonState, setButtonState] = useState(false);

  const buildCols = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return ((tablePage - 1) * sizePerPage + rowIndex + 1);
        },
      },
      { dataField: "name", text: "Name" },
      { dataField: "position", text: "Position" },
      { dataField: "details", text: "Details" },
      { dataField: "email", text: "Email" },
      {
        dataField: "image",
        text: "Photo",
        formatter: (cell, row) => {
          return (
            <img src={getImageUrl(row?.image)} alt='img' width="100" height="100" />
          )
        }
      },
      {
        dataField: "edit",
        text: 'Edit',
        classes: 'w10',
        formatter: (cell, row) => {
          return (
            <>
              <FaRegEdit className='me-3 cursor-pointer' onClick={() => funUpdateData(row)} />
              <FaTrashAlt className='cursor-pointer' onClick={() => funDeleteData(row)} />
            </>
          )
        }
      },
      {
        dataField: "publish",
        text: 'Publish',
        formatter: (cell, row) => {
          return (
            <input className='cursor-pointer' type='checkbox' name='checkbox' onChange={e => UpdateActiveStatus(row)} defaultChecked={row.publish} />
          )
        }
      },
    ]
    return columnFields;
  }

  const headerData = {
    mainHeading: 'Founding Members',
    subHeadng: '',
    buttonText: ["Update"]
  }

  // ======================= TABLE FUNCTIONALITY =======================

  const formControls = ['name', 'position', 'email', 'details', 'image'];
  const imageId = 'fountingMembers_tableImage';

  const [search, setSearch] = useState("");
  const [tempData, setTempData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const initialInputState = {
    name: '',
    position: '',
    email: '',
    details: '',
    image: '',
  }
  const [foundingMembers, setFoundingMembers] = useState(initialInputState);

  useEffect(() => {
    const filtered = !search
      ? tempData
      : tempData.filter((person) =>
        person.name.toLowerCase().includes(search.toLowerCase()) ||
        person.position.toLowerCase().includes(search.toLowerCase()) ||
        person.details.toLowerCase().includes(search.toLowerCase()) ||
        person.email.toLowerCase().includes(search.toLowerCase())
      );
    setTableData(filtered);
  }, [search]);

  const fetchTableData = async () => {
    const res = await APIService.get(`/admin/home/members`)
    if (res.status === 200) {
      setTableData(res.data.data);
      setTempData(res.data.data);
    }
  }

  const foundingMembersCRUD = async (data) => {
    const res = await APIService.post(`/admin/home/members`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast('success', res.data.message);
      }
      else {
        toggleToast('success', res.data);
      }
      fetchTableData();
    }
  }

  const addFoundingMembers = async () => {
    let validation = formValidation(foundingMembers);
    if (validation !== false) {
      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        ...foundingMembers
      }
      foundingMembersCRUD(data);
      handleModal();
    }
  }

  let funUpdateData = (rowData) => {
    setFoundingMembers(rowData);
    setShowModal(!showModal);
    setPageData(prev => ({ ...prev, [imageId]: getImageUrl(rowData?.image) }))
  }

  const funDeleteData = (row) => {
    const confirmBox = window.confirm(
      "Do you really want to delete?"
    )
    if (confirmBox === true) {
      let data = {
        _id: row._id,
        isDeleted: true
      }
      foundingMembersCRUD(data);
    }
  }

  const UpdateActiveStatus = (rowData) => {
    let data = {
      _id: rowData._id,
      publish: !rowData.publish,
    }
    foundingMembersCRUD(data);
  }

  const handleInputChange1 = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    if (key === "search") {
      return setSearch(value);
    }
    setFoundingMembers({ ...foundingMembers, [key]: value });
  }

  const formValidation = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === undefined) {
          toggleToast('danger', `Please enter ${key}`)
          return false;
        }
        if (obj['email'] != '' || obj[key] == 'email') {
          if (!emailValidator(obj['email'])) {
            toggleToast('danger', `Please enter valid email.`)
            return false;
          }
        }
      }
    }
  }

  const handleModal = () => {
    setShowModal(!showModal);
    setFoundingMembers(initialInputState);
    setPageData(prev => ({ ...prev, [imageId]: '' }))
  }

  // ======================= SECTION FUNCTIONALITY =======================

  const pagename = "homeFoundingMembers";
  const imageId1 = 'fountingMembers_sectionImage';
  const bgImageId1 = 'fountingMembers_sectionBgImage';

  const defaultSection = {
    title: "",
    details: "",
    align: "",

    isMediaChecked: false,
    imageUrl: "",
    videoUrl: "",
    isImageChecked: true,
    isVideoChecked: false,

    backgroundUrl: "",
    backgroundColor: "",
    isBackgroundImageChecked: false,
    isBackgroundColorChecked: false,
    isBackgroundChecked: false,

    buttonUrl: "",
    buttonText: "",
    buttonAlign: "",
    buttonColor: "",
    isButtonChecked: false,
  };

  const [pageDataApiResponse, setPageDataApiResponse] = useState();

  useEffect(() => {
    fetchPageData();
    fetchTableData();
  }, []);

  const fetchPageData = async () => {
    const res = await APIService.get(`/admin/page/item?pagename=${pagename}`);
    if (res.status === 200) {
      let allData = res.data?.data?.[0];
      let data = allData.sections?.[0];

      setPageDataApiResponse(allData);
      allData.sections.map((val, i) => {
        allData[`${imageId1}-${i}`] = getImageUrl(val?.imageUrl);
        allData[`${bgImageId1}-${i}`] = getImageUrl(val?.backgroundUrl);
      });
      setPageData(allData);
    }
  };

  const submitData = async () => {
    let payload = { ...pageDataApiResponse };
    const res = await APIService.put(`/admin/page/${pagename}`, { payload });
    if (res.status === 200) {
      toggleToast("success", "Page updated successfully ");
    } else {
      toggleToast("failure", "Page not updated successfully ");
    }
  };

  const updatePageState = (updateSection, index) => {
    let progressBar = updateSection();
    if (progressBar?.progressBar) {
      setButtonState(true);
      return
    }
    let pageData = { ...pageDataApiResponse };
    let updatedSection = updateSection(pageData.sections[index]);
    pageData.sections[index] = updatedSection;
    setPageDataApiResponse(pageData);
    setButtonState(false);
  };

  const handleInputChange = (e, field, index) => {
    let pageData = { ...pageDataApiResponse };
    if (field === "checkbox") {
      pageData.sections[index] = { ...pageData.sections[index], [e.target.name]: e.target.checked };
    } else {
      pageData.sections[index] = { ...pageData.sections[index], [e.target.name]: e.target.value };
    }
    setPageDataApiResponse(pageData);
  };

  const handleInputChangeForEditor = (value, index) => {
    let pageData = { ...pageDataApiResponse };
    pageData.sections[index] = { ...pageData.sections[index], ['details']: value };
    setPageDataApiResponse(pageData);
  }

  const addNewSection = () => {
    let oldpageData = { ...pageDataApiResponse };
    let addNewSection = defaultSection
    let index = pageDataApiResponse?.sections.length;
    oldpageData[`${imageId1}-${index}`] = getImageUrl("");
    oldpageData[`${bgImageId1}-${index}`] = getImageUrl("");
    oldpageData.sections.push(addNewSection);
    setPageDataApiResponse(oldpageData);
    // setPageData({...pageData,sections:oldpageData})
  };

  const deleteSection = (index) => {
    const confirmBox = window.confirm(
      "Do you really want to delete this section?"
    )
    if (confirmBox === true) {
      let oldpageData = { ...pageDataApiResponse };
      let sectionFilter = oldpageData.sections.filter((val, i) => (i !== index));
      oldpageData.sections = sectionFilter;
      setPageDataApiResponse(oldpageData);
    }
  }

  return (
    <>

      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        buttonState={buttonState}
        handleUpdate={submitData}
      />

      {pageDataApiResponse?.sections &&
        pageDataApiResponse?.sections.length > 0 &&
        pageDataApiResponse?.sections.map((localdata, index) => (
          <>
            <CardLayout>
              <Row>
                <Col md={1}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Title</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={9}>
                  <Form className="mb-3" controlId="title">
                    <Form.Control
                      type="text"
                      name="title"
                      value={localdata.title ? localdata.title : ""}
                      onChange={(e) => handleInputChange(e, "title", index)}
                    />
                  </Form>
                </Col>
                <Col md={2}></Col>
              </Row>
              <Row>
                <Col md={1}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Details</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={9}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <TextEditor
                      value={localdata.details ? localdata.details : ""}
                      handleInputChange={(value) => handleInputChangeForEditor(value, index)}
                    />
                  </Form.Group>
                </Col>
                <Col md={2}></Col>
              </Row>
            </CardLayout>

            <CardLayout>
              <Row className="section-config">
                <Col className="offset-md-1" md={3}>
                  <SectionImage
                    localdata={localdata}
                    setLocalData={(updateSection) => updatePageState(updateSection, index)}
                    locationid={"founding-members-media"}
                    handleInputChange={(e, field) => handleInputChange(e, field, index)}
                    imageId={`${imageId1}-${index}`}
                  />
                </Col>
                <Col className="offset-md-1" md={3}>
                  <SectionBackground
                    index={index}
                    localdata={localdata}
                    setLocalData={(updateSection) => updatePageState(updateSection, index)}
                    locationid={"founding-members-background-media"}
                    handleInputChange={(e, field) => handleInputChange(e, field, index)}
                    bgImageId={`${bgImageId1}-${index}`}
                  />
                </Col>
                <Col className="offset-md-1" md={3}>
                  <SectionButton
                    localdata={localdata}
                    setLocalData={(updateSection) => updatePageState(updateSection, index)}
                    handleInputChange={(e, field) => handleInputChange(e, field, index)}
                  />
                </Col>
              </Row>
              {
                index && index > 0 && <Button onClick={e => deleteSection(index)} className="mt-5" variant="danger" >Delete Section</Button> || ''
              }
            </CardLayout>
          </>
        ))}

      <div className="add_section mb-3" onClick={addNewSection}>
        <span>add section</span> <span className="add_icon">+</span>
      </div>

      <PageHeader
        buttonText='New Founding Member'
        handleUpdate={() => setShowModal(!showModal)}
      />

      <CardLayout>
        <Form.Group >
          <Form.Control
            name="search"
            placeholder='Search...'
            value={search}
            onChange={e => handleInputChange1(e)}
            className='mb-2 w-25 ms-auto'
          ></Form.Control>
        </Form.Group>

        <Row>
          <BootstrapTable className="table-rc" keyField='id' data={tableData} columns={buildCols()} pagination={paginationFactory()} />
        </Row>
      </CardLayout>
      {/* {showModal ? renderModal() : null} */}
      {
        showModal
          ?
          <ModalComp
            imageId={imageId}
            showModal={showModal}
            CUD_API={addFoundingMembers}
            handleModal={handleModal}
            formControls={formControls}
            inputState={foundingMembers}
            handleInputChange={handleInputChange1}
            updateInputState={setFoundingMembers}
          />
          :
          null
      }

    </>
  );
};

export default FoundingMembers;