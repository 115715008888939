import React, { useContext, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import APIService from "../utils/APIService";
import { useNavigate } from "react-router-dom";
import { getCookies, setCookies, validateEmailFormat } from "../utils/Helper";
import useToasts from "../components/Toast/toastProvider";
import { DataContext } from "../context/CustomGlobalContext";

const Login = () => {
  const { refreshToken } = useContext(DataContext);
  const { toggleToast } = useToasts();

  const emailRef = useRef(null)
  const passRef = useRef(null)
  const navigate = useNavigate();

  const handleValidation = (email, pass) => {
    if (email && validateEmailFormat(email)) {
      return true
    }
    if (pass !== '') {
      return true
    }
    return false
  }

  const handleLogin = async (e) => {
    e.preventDefault()
    const email = emailRef.current.value
    const passwrd = passRef.current.value

    const validation = handleValidation(email, passwrd)
    if (!validation) return

    const response = await APIService.post(`/admin/auth/sign-in`, {
      email: email,
      password: passwrd
    })
    if (response?.status === 200) {
      const { data: { data } } = response;
      // refreshToken(data.token)
      refreshToken(data)
      navigate("/home");
      return
    }
    if (response.status === 400) {
      toggleToast('danger', response.data.error)
    }
  }

  return (
    <Row className="d-flex justify-content-center align-items-center vh-100 bg-custom-primary">
      <Col md={3}>
        <div className="login-logo mb-3 text-center">
          <img src="/assets/img/loginlogo.png" alt="logoImg" className="img-fluid" />
        </div>
        <form>
          <div className="mb-3">
            <input
              ref={emailRef}
              type="text"
              className="form-control border-danger"
              id="userName"
              placeholder="Username..."
            />
          </div>
          <div className="mb-3">
            <input
              ref={passRef}
              type="password"
              className="form-control border-danger"
              id="userPassword"
              placeholder="Password..."
            />
          </div>
          <button className="btn btn-primary w-50" type="button" onClick={handleLogin}>
            Login
          </button>
        </form>
      </Col>
    </Row>
  );
};

export default Login;
