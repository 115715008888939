import React, { useEffect, useState, useContext, useRef } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { FaEye, FaTelegramPlane } from "react-icons/fa";
import CardLayout from "../../components/Layout/CardLayout";
import PageHeader from "../../components/Shared/Section/PageHeader";
import { FaRegEdit, FaTrashAlt } from "react-icons/fa";
import APIService from "../../utils/APIService";
import axios from "axios";
import useToasts from "../../components/Toast/toastProvider";
import SelectMedia from "../../components/Shared/Media/SelectMedia";
import { DataContext } from "../../context/CustomGlobalContext";
import { getImageUrl } from "../../utils/Helper";
import TextEditor from "../../components/Shared/components/TextEditor";

export default function PrivacyPolicy() {
  const pagename = "settingsPrivacyPolicy";
  let imageId = "privacy_image";
  const { setPageData } = useContext(DataContext);
  const [headerData, setHeaderdata] = useState({
    mainHeading: "Privacy Policy",
    subHeadng: "Overview",
    buttonText: ["Update"],
  });

  const editorRef = useRef();
  const savedRef = useRef();
  const [counter, setCounter] = useState(0);
  const { toggleToast } = useToasts();
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [headerTitleData, setHeaderTitleData] = useState("");
  const [tableData, setTableData] = useState([]);
  const [pageDataApiResponse, setPageDataApiResponse] = useState();
  const [search, setSearch] = useState("");
  const [editSection, setEditSection] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("addNew");
  const [createPayload, setCreatePayload] = useState({
    name: "",
    targeturl: "",
    status: true,
    image: "",
  });
  const [updatePayload, setUpdatePayload] = useState({
    _id: "",
    name: "",
    targeturl: "",
    status: "",
    image: "",
  });
  const [localdata2, setLocalData2] = useState({
    head: "",
    subhead: "",
    image: "",
  });

  const fetchTableData = async () => {
    const res = await APIService.get(`/admin/page/item?pagename=${pagename}`);
    if (res.status === 200) {
      let allData = res.data.data[0];
      setPageDataApiResponse(allData);
      setPageData(allData);
      setPageData((prev) => ({ ...prev, [imageId]: getImageUrl(allData?.overview?.image) }));
      setLocalData2(allData.overview);
      setPrivacyPolicy(allData.policyContent);
    }
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  useEffect(() => { }, [search]);

  const buildCols = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return rowIndex + 1;
        },
      },
      { dataField: "name", text: "Social Media" },
      { dataField: "targeturl", text: "Target URL" },
      {
        dataField: "image",
        text: "Photo",
        formatter: (cell, row) => {
          return <img src={row.image} alt="img" width="100" height="100" />;
        },
      },
      {
        dataField: "edit",
        text: "Edit",
        formatter: (cell, row) => {
          return (
            <>
              <FaRegEdit className='me-3 cursor-pointer' onClick={() => funUpdateData(row)} />
              <FaTrashAlt className='cursor-pointer' onClick={() => funDeleteData(row)} />
            </>
          );
        },
      },
      {
        dataField: "publish",
        text: "Publish",
        formatter: (cell, row) => {
          return <input className="cursor-pointer" type="checkbox" name="checkbox" checked={row.published} />;
        },
      },
    ];
    return columnFields;
  };

  const handleCancel = () => {
    setShowModal(!showModal);
    setModalType("addNew");
    resetState();
  };

  const resetState = () => {
    setCreatePayload({
      name: "",
      targeturl: "",
      status: true,
      image: "",
    });
    setUpdatePayload({
      _id: "",
      name: "",
      targeturl: "",
      status: "",
      image: "",
    });
  };

  const handleInputChange = (e) => {
    if (e.target.name === "search") {
      setSearch(e.target.value);
      return;
    }
    setCreatePayload({ ...createPayload, [e.target.name]: e.target.value });
  };

  const handleUpdateChange = (e) => {
    setUpdatePayload({ ...updatePayload, [e.target.name]: e.target.value });
  };

  const addNewData = () => {
    return (
      <Container>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                size="sm"
                placeholder="--Name--"
                onChange={(e) => handleInputChange(e)}
              ></Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Target URL</Form.Label>
              <Form.Control
                name="targeturl"
                size="sm"
                placeholder="--Target URL--"
                onChange={(e) => handleInputChange(e)}
              ></Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Form.Select
                name="status"
                size="sm"
                // disabled={checkboxhandle}
                // value={localdata.buttonColor ? localdata.buttonColor : ''}
                // onChange={e => setLocalData(prev => ({ ...prev, btnColor: e.target.value }))}
                onChange={(e) => handleInputChange(e)}
              >
                <option disabled value="default">
                  {" "}
                  --Status--{" "}
                </option>
                <option value={true}>On</option>
                <option value={false}>Off</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <input
              name="image"
              type="file"
              accept="image/png, image/gif, image/jpeg"
              onChange={(e) => uploadImage(e, "create")}
            />
          </Col>
        </Row>
      </Container>
    );
  };

  const updateRowData = () => {
    return (
      <Container>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                size="sm"
                placeholder="--Name--"
                value={updatePayload.name}
                onChange={(e) => handleUpdateChange(e)}
              ></Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Target URL</Form.Label>
              <Form.Control
                name="targeturl"
                size="sm"
                placeholder="--Target URL--"
                value={updatePayload.targeturl}
                onChange={(e) => handleUpdateChange(e)}
              ></Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Form.Select
                name="status"
                size="sm"
                // disabled={checkboxhandle}
                value={updatePayload.status}
                // onChange={e => setLocalData(prev => ({ ...prev, btnColor: e.target.value }))}
                onChange={(e) => handleUpdateChange(e)}
              >
                <option disabled value="default">
                  {" "}
                  --Status--{" "}
                </option>
                <option value={true}>On</option>
                <option value={false}>Off</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <input
              name="image"
              type="file"
              accept="image/png, image/gif, image/jpeg"
              onChange={(e) => uploadImage(e, "updata")}
            />
          </Col>
        </Row>
      </Container>
    );
  };

  const funCreateAPI = async (data) => {
    const res = await APIService.post(`/admin/partners`, data);
    if (res.status === 200) {
      fetchTableData();
    }
  };

  const PublishData = async () => {
    if (!createPayload.name || !createPayload.targeturl || !createPayload.status || !createPayload.image) {
      // alert('Please fill all the inputs properly...');
      // toggleToast('success', 'Page updated successfully ')
      toggleToast("failure", "Please fill all the inputs properly... ");
    } else {
      let data = {
        name: createPayload.name,
        targeturl: createPayload.targeturl,
        image: createPayload.image,
        published: createPayload.status,
        isDeleted: false,
      };
      funCreateAPI(data);
      handleCancel();
    }
  };

  const funDeleteData = (row) => {
    let data = {
      _id: row._id,
      isDeleted: true,
    };
    funCreateAPI(data);
  };

  const funUpdateData = (row) => {
    setModalType("update");
    setShowModal(!showModal);
    setUpdatePayload({
      _id: row._id,
      name: row.name,
      targeturl: row.targeturl,
      status: row.published,
      image: row.image,
    });
  };

  const onClickUpdate = () => {
    if (!updatePayload.name || !updatePayload.targeturl || !updatePayload.image) {
      toggleToast("failure", "Please fill all the inputs properly... ");
    } else {
      let data = {
        _id: updatePayload._id,
        name: updatePayload.name,
        targeturl: updatePayload.targeturl,
        image: updatePayload.image,
        published: updatePayload.status,
        isDeleted: false,
      };
      funCreateAPI(data);
      handleCancel();
    }
  };

  const handleValueChange = (e, field) => {
    setLocalData2({ ...localdata2, [field]: e.target.value });
  };

  const submitData = async () => {
    let payload = { ...pageDataApiResponse, overview: localdata2 };
    // call the put api for the data update
    const res = await APIService.put(`/admin/page/${pagename}`, { payload });
    if (res.status === 200) {
      toggleToast("success", "Page updated successfully ");
    } else {
      toggleToast("failure", "Page not updated successfully ");
    }
  };

  const updatePrivacy = async () => {
    let payload = { ...pageDataApiResponse, policyContent: privacyPolicy };
    // call the put api for the data update
    const res = await APIService.put(`/admin/page/${pagename}`, { payload });
    if (res.status === 200) {
      setEditSection(false);
      setCounter(prev => prev + 1);
      toggleToast("success", "Page updated successfully ");
    } else {
      toggleToast("failure", "Page not updated successfully ");
    }
  };

  const renderModal = (modalFor = "addNew") => {
    if (modalFor === "addNew") {
      return (
        <Modal size="lg" centered={true} show={showModal}>
          <Modal.Body>{addNewData()}</Modal.Body>
          <Modal.Footer>
            <Button className="me-1" variant="success" size="sm" onClick={() => handleCancel()}>
              <i class="fas fa-band-aid">
                <FaEye />
              </i>{" "}
              Preview
            </Button>
            <Button className="me-1" variant="primary" size="sm" onClick={PublishData}>
              <i class="fas fa-band-aid">
                <FaTelegramPlane />
              </i>{" "}
              Publish
            </Button>
          </Modal.Footer>
        </Modal>
      );
    } else {
      return (
        <Modal size="lg" centered={true} show={showModal}>
          <Modal.Body>{updateRowData()}</Modal.Body>
          <Modal.Footer>
            <Button className="me-1" variant="success" size="sm" onClick={() => onClickUpdate()}>
              Update
            </Button>
            <Button className="me-1" variant="success" size="sm" onClick={() => handleCancel()}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  };

  const uploadImage = async (e, condition) => {
    let file = e.target.files[0];
    let formdata = new FormData();
    formdata.append("media", file, file.name);
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/admin/page/upload`, formdata, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (res.status === 200) {
      if (condition === "create") {
        setCreatePayload({ ...createPayload, [e.target.name]: res?.data?.path });
      } else {
        setUpdatePayload({ ...updatePayload, [e.target.name]: res?.data?.path });
      }
    } else {
      console.log("Failed in uploading BLOODY FILE");
    }
  };

  useEffect(() => {
    if (editSection) {
      editorRef?.current?.scrollIntoView();
    }
    else {
      savedRef?.current?.scrollIntoView();
    }
  }, [counter])

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate={submitData}
      />
      <CardLayout>
        <Row ref={savedRef}>
          <Col md={8}>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Head</Form.Label>
                <Form.Control
                  type="text"
                  value={localdata2?.head ? localdata2.head : ""}
                  name="head"
                  onChange={(e) => handleValueChange(e, "head")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Sub-head</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  style={{ resize: "none" }}
                  name="subhead"
                  value={localdata2.subhead ? localdata2.subhead : ""}
                  onChange={(e) => handleValueChange(e, "subhead")}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col md={3} className="d-flex justify-content-center align-self-center offset-md-1">
            <SelectMedia
              type={"image"}
              name="image"
              id={imageId}
              imgSrc={localdata2?.image ? localdata2?.image : ""}
              updateInputState={setLocalData2}
            />
          </Col>
        </Row>
      </CardLayout>

      {/* <CardLayout>
        <Row>
          <Col md={8}>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Head</Form.Label>
                <Form.Control
                  type="text"
                  // value={localdata2?.head ? localdata2.head : ''}
                  name="head"
                  // onChange={(e) => handleValueChange(e, "head")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Sub-head</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  style={{ resize: "none" }}
                  name="subhead"
                  // value={localdata2.subhead ? localdata2.subhead : ''}
                  // onChange={(e) => handleValueChange(e, "subhead")}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col md={3} className="d-flex justify-content-center align-self-center offset-md-1">
            <SelectMedia type={"image"} name={"media"} id="home-image-media" />
          </Col>
        </Row>
      </CardLayout> */}
      <hr className="mt-5" />
      <Row className="justify-content-center">
        <Col md={9}>
          <div className="text-end">
            <Button
              className="me-1 mb-3"
              size="lg"
              onClick={() => {
                setEditSection(true);
                setCounter(prev => prev + 1);
              }}
            >
              Edit
            </Button>
          </div> 
          <CardLayout>
            <p dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
          </CardLayout>
          {editSection ? (
            <>
              <div className="text-end">
                <Button className="me-1 mb-3" size="lg" onClick={updatePrivacy}>
                  Update
                </Button>
              </div>
              <CardLayout >
                <h5 ref={editorRef} >Edit Privacy Policy</h5>
                <TextEditor 
                  value={privacyPolicy ? privacyPolicy : ""}
                  handleInputChange={(value) => setPrivacyPolicy(value)}
                />
              </CardLayout>
            </>
          ) : (
              ""
            )}
          {showModal ? renderModal(modalType) : null}
        </Col>
      </Row>
    </>
  );
}
