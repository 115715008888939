import React, { useState, useEffect } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.css";

const ReactDateRangePicker = (props) => {
  const {
    dateFormat = "YYYY-MM-DD",
    type = "default",
    stDate = moment(),
    edDate = moment(),
    updateRange = null,
    selectionRange = {
      // Today: [moment(), moment()],
      // Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      // "This Week": [moment().startOf("week"), moment().endOf("week")],
      // "Last Week": [moment().subtract(1, "weeks").startOf("weeks"), moment().subtract(1, "weeks").endOf("weeks")],
      // "Last 30 Days": [moment().subtract(29, "days"), moment()],
      // "This Month": [moment().startOf("month"), moment().endOf("month")],
      // "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
    },
  } = props;

  const sendFormattedDate = (e, picker = null) => {
    let sDate = stDate;
    let eDate = edDate;
    sDate = `${picker.startDate.format(dateFormat)}`;
    eDate = `${picker.endDate.format(dateFormat)}`;
    updateRange(sDate, eDate);
  };

  if (type === "single") {
    return (
      <div className="date_range_wrapper">
        <DateRangePicker
          initialSettings={{
            startDate: stDate,
            autoApply: true,
            singleDatePicker: true,
          }}
          onApply={sendFormattedDate}
        >
          <input type="text" className={`form-control `} name="date" placeholder="Select Date" />
        </DateRangePicker>
      </div>
    );
  }

  return (
    <div className="date_range_wrapper">
      <DateRangePicker
        initialSettings={{
          // dateLimit: dateLimit,
          startDate: stDate,
          endDate: edDate,
          ranges: selectionRange,
          // maxDate: moment(),
        }}
        onApply={sendFormattedDate}
      >
        <input type="text" className={`form-control `} name="date" placeholder="Select Date" />
      </DateRangePicker>
    </div>
  );
};

export default ReactDateRangePicker;
