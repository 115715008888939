import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from "react-bootstrap";

import CardLayout from "../components/Layout/CardLayout";
import PageHeader from "../components/Shared/Section/PageHeader";
import useToasts from '../components/Toast/toastProvider';
import { DataContext } from '../context/CustomGlobalContext';
import APIService from '../utils/APIService';
import { emailValidator } from '../utils/Helper';

export default function AdminProfile() {

  const { user, refreshToken } = useContext(DataContext);

  const { toggleToast } = useToasts();
  const headerData = {
    mainHeading: 'Admin',
    subHeadng: 'Profile',
    buttonText: []
  };

  const [localdata, setLocalData] = useState({
    name: "",
    email: ""
  });

  useEffect(() => {
    setLocalData({ ...user });
  }, [user]);

  const handleInputChange = (e) => {
    setLocalData({ ...localdata, [e.target.name]: e.target.value });
  };

  const submitData = async () => {
    let validation = formValidation(localdata);
    if (validation !== false) {
      let payload = localdata;

      const res = await APIService.post(`/admin/auth/update-user`, payload);
      if (res.status === 200) {
        let updated = res?.data?.data;
        toggleToast('success', 'Page updated successfully ');
        refreshToken(updated)
      } else {
        toggleToast('danger', res?.data?.message)
      }
    }
  }
  console.log("user", user);
  const formValidation = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === undefined) {
          toggleToast('danger', `Please enter ${key}`)
          return false;
        }
      }
      if (key === 'email') {
        if (!emailValidator(obj['email'])) {
          toggleToast('danger', `Please enter valid email.`)
          return false;
        }
      }
    }
  }

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate=""
      />

      <CardLayout>

        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            name="name"
            size="md"
            value={(localdata?.name) ? localdata?.name : ""}
            onChange={e => handleInputChange(e)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>E-mail</Form.Label>
          <Form.Control
            name="email"
            size="md"
            value={(localdata?.email) ? localdata?.email : ""}
            onChange={e => handleInputChange(e)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Mobile</Form.Label>
          <Form.Control
            name="mobile"
            size="md"
            value={(localdata?.mobile) ? localdata?.mobile : ""}
            onChange={e => handleInputChange(e)}
          ></Form.Control>
        </Form.Group>

        <Button
          className="me-1"
          variant='success'
          size='md'
          onClick={submitData}
        >
          Save
        </Button>

      </CardLayout>

    </>
  )
}