import React, { useState, useEffect } from "react";
import StyleEditor from "react-style-editor";
import PageHeader from "../../components/Shared/Section/PageHeader";
import CardLayout from "../../components/Layout/CardLayout";
import useToasts from "../../components/Toast/toastProvider";
import APIService from "../../utils/APIService";

export default function CustomCss() {
  const pagename = "settingsCustomCss";
  const { toggleToast } = useToasts();
  const [headerData, setHeaderdata] = useState({
    mainHeading: "Custom CSS",
    subHeadng: "",
    buttonText: ["Update"],
  });
  const [defaultCss, setDefaultCss] = useState("");
  const [pageDataApiResponse, setPageDataApiResponse] = useState();

  const fetchCookeiData = async () => {
    const res = await APIService.get(`/admin/page/item?pagename=${pagename}`);
    if (res.status === 200) {
      let allData = res.data.data[0];
      setPageDataApiResponse(allData);
      setDefaultCss(allData.customCss);
    }
  };

  useEffect(() => {
    fetchCookeiData();
  }, []);

  const submitData = async () => {
    let payload = { ...pageDataApiResponse, customCss: defaultCss };
    // call the put api for the data update
    const res = await APIService.put(`/admin/page/${pagename}`, { payload });
    if (res.status === 200) {
      toggleToast("success", "CSS updated successfully ");
    } else {
      toggleToast("failure", "CSS not updated successfully ");
    }
  };

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate={submitData}
      />
      <CardLayout>
        <div className="custom_css">
          <StyleEditor defaultValue={defaultCss} value={defaultCss} onChange={(data) => setDefaultCss(data)} />
        </div>
      </CardLayout>
    </>
  );
}
