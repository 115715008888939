import React, { useEffect, useState } from 'react';
import { Button, Form } from "react-bootstrap";

import CardLayout from "../../components/Layout/CardLayout";
import PageHeader from "../../components/Shared/Section/PageHeader";
import useToasts from '../../components/Toast/toastProvider';
import APIService from '../../utils/APIService';
import { emailValidator } from '../../utils/Helper';

export default function ContactDetails() {

  const { toggleToast } = useToasts();
  const headerData = {
    mainHeading: 'Contact Us',
    subHeadng: 'Details',
    buttonText: []
  };
  const pagename = "contactUsDetails";
  const [pageDataApiResponse, setPageDataApiResponse] = useState();
  const [localdata, setLocalData] = useState({
    name: "",
    footerheading: "",
    address: "",
    email: "",
    tel: "",
    fax: "",
    mobile: "",
    map: ""
  });

  useEffect(() => {
    fetchPageData()
  }, []);

  const fetchPageData = async () => {
    const res = await APIService.get(`/admin/page/item?pagename=${pagename}`)
    if (res.status === 200) {
      let allData = res.data?.data?.[0];
      setPageDataApiResponse(allData);
      setLocalData(allData?.contactDetails);
    }
  }

  const handleInputChange = (e, field) => {
    setLocalData({ ...localdata, [e.target.name]: e.target.value });
  };

  const submitData = async () => {
    let validation = formValidation(localdata);
    if (validation !== false) {
      let payload = { ...pageDataApiResponse };
      payload.contactDetails = { ...localdata };

      const res = await APIService.put(`/admin/page/${pagename}`, { payload });
      if (res.status === 200) {
        toggleToast('success', 'Page updated successfully ')
      } else {
        toggleToast('failure', 'Page not updated successfully ')
      }
    }
  }

  const formValidation = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === undefined) {
          toggleToast('danger', `Please enter ${key}`)
          return false;
        }
      }
      if (key === 'email') {
        if (!emailValidator(obj['email'])) {
          toggleToast('danger', `Please enter valid email.`)
          return false;
        }
      }
    }
  }

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate=""
      />

      <CardLayout>

        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            name="name"
            size="md"
            value={(localdata?.name) ? localdata?.name : ""}
            onChange={e => handleInputChange(e)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Footer Heading</Form.Label>
          <Form.Control
            name="footerheading"
            as="textarea"
            rows={3}
            size="md"
            value={(localdata?.footerheading) ? localdata?.footerheading : ""}
            onChange={e => handleInputChange(e)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Address</Form.Label>
          <Form.Control
            name="address"
            size="md"
            value={(localdata?.address) ? localdata?.address : ""}
            onChange={e => handleInputChange(e)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>E-mail</Form.Label>
          <Form.Control
            name="email"
            size="md"
            value={(localdata?.email) ? localdata?.email : ""}
            onChange={e => handleInputChange(e)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Tel</Form.Label>
          <Form.Control
            name="tel"
            size="md"
            value={(localdata?.tel) ? localdata?.tel : ""}
            onChange={e => handleInputChange(e)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Fax</Form.Label>
          <Form.Control
            name="fax"
            size="md"
            value={(localdata?.fax) ? localdata?.fax : ""}
            onChange={e => handleInputChange(e)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Mobile</Form.Label>
          <Form.Control
            name="mobile"
            size="md"
            value={(localdata?.mobile) ? localdata?.mobile : ""}
            onChange={e => handleInputChange(e)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Map</Form.Label>
          <Form.Control
            name="map"
            size="md"
            value={(localdata?.map) ? localdata?.map : ""}
            onChange={e => handleInputChange(e)}
          ></Form.Control>
        </Form.Group>

        <Button
          className="me-1"
          variant='success'
          size='md'
          onClick={submitData}
        >
          Save
        </Button>

      </CardLayout>

    </>
  )
}
