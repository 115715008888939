import React, { useContext, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Spinner from "../Shared/spinner";
import { DataContext } from "../../context/CustomGlobalContext";
import { FaHome, FaGlobe, FaIdBadge, FaQuoteLeft, FaHeadset, FaSitemap, FaCalendarAlt, FaThList, FaBlog, FaNewspaper, FaImage, FaRegAddressCard, FaCog, FaFile, FaTrashAlt, FaRegEdit, FaCircle } from 'react-icons/fa';

const SideBar = () => {

  const { mainData } = useContext(DataContext)
  const location = useLocation();
  const iconArr = [FaHome, FaGlobe, FaIdBadge, FaQuoteLeft, FaHeadset, FaSitemap, FaCalendarAlt, FaNewspaper, FaNewspaper, FaNewspaper, FaImage, FaRegAddressCard, FaFile, FaCog]
  const [dropDownState, setDropDownState] = useState([]);
  const [loading, setLoading] = useState(false)

  const toggleDropDown = (dropdown, isfor) => {
    if (dropDownState.includes(dropdown)) {
      let localSubItems = dropDownState.filter(e => e !== dropdown)
      return setDropDownState(localSubItems);
    }
    if (isfor === 'subItems') {
      setDropDownState([...dropDownState, dropdown]);
    }
    else {
      setDropDownState([dropdown])
    }
  }

  const isDropDownVisible = (dropdown) => {
    if (dropDownState.includes(dropdown)) {
      return "show";
    }
    else {
      return "collapse";
    }
  };

  const renderSideBarDropdownItems = (sidebarItem, itemsField) => {
    return (
      <div className={`ms-4 ${isDropDownVisible(sidebarItem.item)} nav-drop`}>
        <ul className="navbar-nav">
          {sidebarItem[itemsField] &&
            sidebarItem[itemsField].length > 0 &&
            sidebarItem[itemsField].map((menuItem, index) => {
              if (!menuItem.subItems) {
                return (
                  <NavLink
                    to={menuItem.path}
                    className={({ isActive }) =>
                      isActive && menuItem.path === location.pathname + location.search ? "active nav-item" : "nav-item"
                    }
                    key={sidebarItem.title + "-" + menuItem.title}
                    end
                  >
                    <div className="nav-link px-2">{menuItem.title}</div>
                  </NavLink>
                );
              } else {
                return (
                  <div
                    key={sidebarItem.title + "-" + menuItem.title}
                  >
                    <NavLink to={menuItem.path}
                      className={({ isActive }) =>
                        isActive && menuItem.path === location.pathname + location.search ? "active nav-item" : "nav-item"
                      }
                      end
                    >
                      <div
                        className={`nav-link px-2 d-flex align-items-center gap-2 dropdown-toggle justify-content-between`}
                        onClick={() => toggleDropDown(menuItem.item, "subItems")}
                        key={sidebarItem.title + "-" + menuItem.title}
                      >
                        <span className="me-auto">{menuItem.title}</span>
                      </div>
                    </NavLink>
                    {renderSideBarDropdownItems(menuItem, "subItems")}
                  </div>
                );
              }
            })}
        </ul>
      </div>
    );
  };

  const renderSideBarItem = (sidebarItem, i) => {
    let CustomIcon = iconArr?.[i] || FaCog;
    return (
      <div key={sidebarItem.item}>
        <NavLink to={sidebarItem.path}
          className={({ isActive }) =>
            isActive && sidebarItem.path === location.pathname + location.search ? "active nav-item" : "nav-item"
          }
          end
        >
          <div
            className={`nav-link px-2 d-flex align-items-center gap-2 justify-content-between ${sidebarItem.menuItems && sidebarItem.menuItems.length ? "dropdown-toggle" : ""
              }`}
            onClick={() => toggleDropDown(sidebarItem.item, "menuItems")}
          >
            <div className="d-flex align-items-center">
              <i><CustomIcon size={15} className="iconSize" /></i>
              <span className="me-auto sideLabel">{sidebarItem.title}</span>
            </div>
          </div>
        </NavLink>
        {sidebarItem.menuItems && sidebarItem.menuItems.length &&
          renderSideBarDropdownItems(sidebarItem, "menuItems")}
      </div>
    );
  };

  const MainSection = () => {
    return (
      <div
        className="offcanvas offcanvas-start navbar-Theme text-nowrap"
        tabIndex="-1"
        id="sidebar"
        aria-label="sidebar"
      >
        <div className="offcanvas-header p-0 bg-white">
          <div className="logo nav-link px-0 d-flex align-items-center">
            <img src="/assets/img/logo.png" alt="logoImg" className="img-fluid" />
            <span className="logoTitle">
              <img src="/assets/img/favicon1.png" alt="" className="img-fluid p-xxl-1 p-md-2" />
            </span>
          </div>
          <button
            type="button"
            className="btn-close btn-close-white"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body px-2 py-3 h-100" data-simplebar>
          <ul className="navbar-nav mb-4" id="mainMenu">
            <NavLink to="/" className="nav-item" end >
              <div className="nav-link px-2 d-flex align-items-center gap-2 justify-content-between">
                <div className="d-flex align-items-center">
                  <i><FaHome size={15} className="iconSize" /></i><span>Dashboard</span>
                </div>
              </div>
            </NavLink>
            {mainData && mainData.length > 0 &&
              mainData.map((sidebarItem, i) => renderSideBarItem(sidebarItem, i))}
          </ul>
        </div>
      </div>
    );
  }

  return (
    <>
      {
        loading
          ?
          <Spinner />
          :
          <MainSection />
      }
    </>
  )
};

export default SideBar;
