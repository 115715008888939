import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

const SectionButton = ({
  localdata,
  setLocalData,
  handleInputChange,
  isDisable = false,
  forButton = ''
}) => {
  const [checkboxhandle, setCheckboxHandle] = useState(true)

  return (
    <Row className="d-flex justify-content-center">
      <Row className="d-flex justify-content-between mb-2">
        <Col className="section-primary">Button</Col>
        <Col className="d-flex justify-content-end align-items-center">
          <input
            title={isDisable ? "Disabled" : ''}
            disabled={isDisable}
            type="checkbox"
            className="cursor-pointer"
            name={`isButtonChecked${forButton}`}
            value={localdata[`isButtonChecked${forButton}`]}
            checked={localdata[`isButtonChecked${forButton}`]}
            // onChange={() => setCheckboxHandle(!checkboxhandle)}
            onChange={e => handleInputChange(e, "checkbox")}
          />
        </Col>
      </Row>
      <Row>
        <Form.Group className="mb-3">
          <Form.Label>Text</Form.Label>
          <Form.Control
            // disabled={checkboxhandle}
            type="text"
            name={`buttonText${forButton}`}
            size="md"
            placeholder="--Text--"
            value={localdata[`buttonText${forButton}`] ? localdata[`buttonText${forButton}`] : ''}
            // onChange={e => setLocalData(prev => ({ ...prev, btnText: e.target.value }))}
            onChange={e => handleInputChange(e)}
          ></Form.Control>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group className="mb-3">
          <Form.Label>Color</Form.Label>
          <Form.Select
            name={`buttonColor${forButton}`}
            size="md"
            // disabled={checkboxhandle}
            value={localdata[`buttonColor${forButton}`] ? localdata[`buttonColor${forButton}`] : ''}
            // onChange={e => setLocalData(prev => ({ ...prev, btnColor: e.target.value }))}
            onChange={e => handleInputChange(e)}
          >
            <option disabled value="default">
              {" "}
              --Color--{" "}
            </option>
            <option value="linear-gradient(90deg,#abebc1, #469c64)">Green</option>
            <option value="linear-gradient(90deg,#FCDA59, #DC9044)">Orange</option>
            <option value="linear-gradient(90deg,#9a9ad3, #0000ff)">Blue</option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group className="mb-3">
          <Form.Label>Align</Form.Label>
          <Form.Select
            name={`buttonAlign${forButton}`}
            size="md"
            // disabled={checkboxhandle}
            value={localdata[`buttonAlign${forButton}`] ? localdata[`buttonAlign${forButton}`] : ''}
            // onChange={e => setLocalData(prev => ({ ...prev, buttonAlign: e.target.value }))}
            onChange={e => handleInputChange(e)}
          >
            <option disabled value="default">
              {" "}
              --Align--{" "}
            </option>
            <option value="left">Left</option>
            <option value="right">Right</option>
            <option value="center">Center</option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group className="mb-3">
          <Form.Label>URL</Form.Label>
          <Form.Control
            // disabled={checkboxhandle}
            type="text"
            name={`buttonUrl${forButton}`}
            size="md"
            placeholder="--URL--"
            value={localdata[`buttonUrl${forButton}`] ? localdata[`buttonUrl${forButton}`] : ''}
            onChange={e => handleInputChange(e)}
          // onChange={e => setLocalData(prev => ({ ...prev, buttonUrl: e.target.value }))}
          ></Form.Control>
        </Form.Group>
      </Row>
    </Row>
  );
};

export default SectionButton;
