import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import RootLayout from "./layout/RootLayout";
import Mainpage from "./pages/mainpage";

import Login from "./pages/login";
import AdminProfile from "./pages/admin-profile"
import ResetPassword from "./pages/reset-password";

//Importing the Home pages
import Home from "./pages/home";
import Aboutus from "./pages/home/aboutus.jsx";
import Ambassadors from "./pages/home/ambassadors.jsx";
import CTaskForce from "./pages/home/ctaskforce.jsx";
import FoundingMembers from "./pages/home/foundingmembers.jsx";
import InTheSpotlight from "./pages/home/inthespotlight.jsx";
import Support from "./pages/home/support.jsx";

//Importing the Why-Thailand Pages
import WhyThailand from "./pages/why-thailand";
import Destination from "./pages/why-thailand/destination.jsx";
import RobustIndustries from "./pages/why-thailand/robustindustries.jsx";
import RobustindustriesCreate from "./pages/why-thailand/robustindustries-create.jsx";
import Sustainability from "./pages/why-thailand/sustainability.jsx";
import WhyThailandCategory from "./pages/why-thailand/wtcategory.jsx";
import CreateSubCategory from "./pages/why-thailand/create-subcategory";
import CreateCategoryPage from "./pages/why-thailand/create-category-page";

// Importing the Ambassadors Pages
import ConventionAmbassadors from "./pages/convention-ambassadors";
import CACategory from "./pages/convention-ambassadors/cacategory.jsx";
import CACreate from "./pages/convention-ambassadors/cacreate.jsx";
import Listing from "./pages/convention-ambassadors/listing";
import ProgramInfo from "./pages/convention-ambassadors/programinfo";

// Importing the Success Stories Pages
import SuccessStories from "./pages/success-stories";
import CaseStudies from "./pages/success-stories/casestudies";
import PreviousConventions from "./pages/success-stories/previousconventions.jsx";
import Testimonials from "./pages/success-stories/testimonials";

// Importing the Resources Pages
import Resources from "./pages/resources";
import ResourcesCategory from "./pages/resources/category";
import ResourcesCreate from "./pages/resources/create-resources";
import ResourcesDynamicPages from "./pages/resources/resources-dynamic";

// Importing the Plan Your Event Pages
import PlanYourEvent from "./pages/plan-event";
import GovernmentalFacilitation from "./pages/plan-event/goverment-facilities";
import RFS from "./pages/plan-event/rfs";
import PYESupport from "./pages/plan-event/support";
import SupportServices from "./pages/plan-event/support-services";

// Importing the Team Pages
import Team from "./pages/team";
import TeamCategory from "./pages/team/teamcategory";
import TeamList from "./pages/team/teamlist";

// Importing the Calendar Pages
import Calendar from "./pages/calendar";
import ConventionLineup from "./pages/calendar/convention-lineup";

// Importing the Contents Pages
import Contents from "./pages/contents";
import ContentsCategory from "./pages/contents/category";
import ContentsCreate from "./pages/contents/create";

// Importing the Blog Pages
import Blog from "./pages/blogs";

// Importing the News Pages
import News from "./pages/news";

// Importing the Gallery Pages
import Gallery from "./pages/gallery";
import CreateAlbums from "./pages/gallery/createalbums";
import GalleryAlbums from "./pages/gallery/galleryalbums";

// Importing the Contact-Us Pages
import Contactus from "./pages/contact-us";
import ContactDetails from "./pages/contact-us/contactdetails";
import ContactSubscribeList from "./pages/contact-us/contactussubs";
import OurTeamCategories from "./pages/contact-us/out-team";
import OurTeamList from "./pages/contact-us/out-team/team-list";

// Importing the Settings Pages
import CustomCss from "./pages/settings/custom-css";
import OurPartners from "./pages/settings/our-partners";
import CookiePolicy from "./pages/settings/cookie-policy";
import PrivacyPolicy from "./pages/settings/privacy-policy";
import SocialMedia from "./pages/settings/social-media";
import OwnershipCopyright from "./pages/settings/ownership-copyright";

import CustomPages from "./pages/pages";
import PageContent from "./pages/pages/page-content";

import { ToastComponent } from "./components/Toast/toasts";
import { DataContext } from "./context/CustomGlobalContext";
import APIService from "./utils/APIService";

const RequireAuth = ({ children }) => {
  const { token } = useContext(DataContext);

  if (!token) {
    return <Navigate to={`/login`} replace={true} />;
  }
  return (
    <RootLayout>
      <Outlet />
    </RootLayout>
  );
};

const PublicRoutes = ({ children }) => {
  const { token } = useContext(DataContext);

  if (token) {
    return <Navigate to={`/home`} replace={true} />;
  }
  return (
    <>
      <Outlet />
    </>
  );
};

const AppRouter = () => {
  const { setMainDatahandle, token } = useContext(DataContext);
  const [mainData, setMainData] = useState([]);

  const getSidebarData = async () => {
    const response = await APIService.get("/admin/menu");
    if (response.status === 200) {
      // const sidebarDatas = FormateSideBarData(response.data.data)
      setMainData(response.data.data);
      setMainDatahandle(response.data.data);
    }
  };

  const NestedRoutes = (mainRoute, submenu) => {
    if (!submenu || (submenu && submenu.length <= 0)) return;
    return submenu.map((data, index) => {
      const routArray = Array.isArray(data) || data.path.split("/");
      const lastElement = routArray.length - 1;
      const routes = Array.isArray(data) ? "no-route" : routArray[lastElement];
      return <Route key={index} path={routes} element={<Mainpage />}></Route>;
    });
  };

  const RenderRoutes = () => {
    return mainData.map((data, index) => {
      const mainRoute = data.path.replace("/", "").toLowerCase();
      return (
        <Route path={mainRoute} key={index}>
          <Route index={true} element={<Mainpage />} exact></Route>
          {NestedRoutes(mainRoute, data.menuItems)}
        </Route>
      );
    });
  };

  useEffect(() => {
    getSidebarData();
  }, []);

  return (
    <>
      <ToastComponent />
      <Routes>
        <Route path="/" element={<PublicRoutes />}>
          <Route path="/" element={<Login />} />
          <Route path="login" element={<Login />} />
        </Route>

        <Route path="/" element={<RequireAuth />}>
          {
            // mainData.length && RenderRoutes()
            mainData.length ? (
              <>
                {/* Admin Profile Page Routes */}
                <Route path="/adminProfile" element={<AdminProfile />} />
                <Route path="/reset-password" element={<ResetPassword />} />

                {/* Home Page Routes */}
                <Route path="/home" element={<Home />} />
                <Route path="/home/about-us" element={<Aboutus />} />
                <Route path="/home/c-taskforce" element={<CTaskForce />} />
                <Route path="/home/ambassadors" element={<Ambassadors />} />
                <Route path="/home/founding-members" element={<FoundingMembers />} />
                <Route path="/home/in-the-spotlight" element={<InTheSpotlight />} />
                <Route path="/home/support" element={<Support />} />

                {/* Why Thailand */}

                <Route path="/why-thailand" element={<WhyThailand />} />
                <Route path="/why-thailand/robust-industries" element={<RobustIndustries />} />
                <Route path="/why-thailand/robust-industries/create" element={<RobustindustriesCreate />} />
                <Route path="/why-thailand/destination" element={<Destination />} />
                {/* <Route path="/why-thailand/sustainability" element={<Sustainability />} /> */}
                <Route path="/why-thailand/category" element={<WhyThailandCategory />} />
                <Route path="/why-thailand/subcategory" element={<CreateSubCategory />} />
                <Route path="/why-thailand/:category" element={<CreateCategoryPage />} />

                {/* Why Convention Ambassadors  */}

                <Route path="/convention-ambassadors" element={<ConventionAmbassadors />} />
                <Route path="/convention-ambassadors/listing" element={<Listing />} />
                <Route path="/convention-ambassadors/program-info" element={<ProgramInfo />} />
                <Route path="/convention-ambassadors/category" element={<CACategory />} />
                <Route path="/convention-ambassadors/create" element={<CACreate />} />

                {/* Plan Your Event  */}

                <Route path="/plan-event" element={<PlanYourEvent />} />
                <Route path="/plan-event/rfs" element={<RFS />} />
                <Route path="/plan-event/goverment-facilities" element={<GovernmentalFacilitation />} />
                <Route path="/plan-event/support" element={<PYESupport />} />
                <Route path="/plan-event/support-and-services" element={<SupportServices />} />

                {/* Resources  */}

                <Route path="/resources" element={<Resources />} />
                <Route path="/resources/category" element={<ResourcesCategory />} />
                <Route path="/resources/create-resource" element={<ResourcesCreate />} />
                <Route path="/resources/:category" element={<ResourcesDynamicPages />} />

                {/* Success Stories  */}

                <Route path="/success-stories" element={<SuccessStories />} />
                <Route path="/success-stories/previous-conventions" element={<PreviousConventions />} />
                <Route path="/success-stories/testimonials" element={<Testimonials />} />
                <Route path="/success-stories/case-studies" element={<CaseStudies />} />

                {/* Team  */}
                <Route path="/team" element={<Team />} />
                <Route path="/team/teamcategory" element={<TeamCategory />} />
                <Route path="/team/teamlist" element={<TeamList />} />

                {/* Calendar  */}
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/calendar/convention-lineup" element={<ConventionLineup />} />

                {/* Contents  */}
                <Route path="/contents" element={<Contents />} />
                <Route path="/contents/category" element={<ContentsCategory />} />
                <Route path="/contents/create" element={<ContentsCreate />} />

                {/* Blogs  */}
                <Route path="/blogs" element={<Blog />} />

                {/* News  */}
                <Route path="/news" element={<News />} />

                {/* Gallery  */}
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/gallery/gallery-albums" element={<GalleryAlbums />} />
                <Route path="/gallery/create-albums" element={<CreateAlbums />} />

                {/* Contact Us  */}
                <Route path="/contact-us" element={<Contactus />} />
                <Route path="/contact-us/details" element={<ContactDetails />} />
                <Route path="/contact-us/subscribe-list" element={<ContactSubscribeList />} />
                <Route path="/contact-us/our-team" element={<OurTeamCategories />} />
                <Route path="/contact-us/our-team/list" element={<OurTeamList />} />
                <Route path="/contact-us/our-team/categories" element={<OurTeamCategories />} />
                {/* <Route path="/contact-us/gallery-albums" element={<Contactus />} />
              <Route path="/contact-us/create-albums" element={<CreateAlbums />} /> */}

                {/* Settings Us  */}
                <Route path="/settings" element={<PrivacyPolicy />} />
                <Route path="/settings/custom-css" element={<CustomCss />} />
                <Route path="/settings/our-partners" element={<OurPartners />} />
                <Route path="/settings/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/settings/cookie-policy" element={<CookiePolicy />} />
                <Route path="/settings/social-media" element={<SocialMedia />} />
                <Route path="/settings/ownership-copyright" element={<OwnershipCopyright />} />

                {/* Custom Pages  */}
                <Route path="/pages" element={<CustomPages />} />
                <Route path="/pages/page-content" element={<PageContent />} />
              </>

            ) : (
              ""
            )
          }
        </Route>
      </Routes>
    </>
  );
};

export default AppRouter;
