import React, { useContext, useEffect, useState } from 'react';
import { Form, Row } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa';
import CardLayout from "../../../components/Layout/CardLayout";
import ModalComp from '../../../components/Shared/components/ModalComp';
import PageHeader from "../../../components/Shared/Section/PageHeader";
import useToasts from "../../../components/Toast/toastProvider";
import { DataContext } from '../../../context/CustomGlobalContext';
import APIService from "../../../utils/APIService";
import { emailValidator, getImageUrl, setRowIndex } from '../../../utils/Helper';

export default function OurTeamList() {

  const { setPageData } = useContext(DataContext);
  const { toggleToast } = useToasts();

  const [tablePage, setTablePage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const paginationOption = setRowIndex(setTablePage, setSizePerPage);

  const buildCols = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return ((tablePage - 1) * sizePerPage + rowIndex + 1);
        },
      },
      {
        dataField: "name",
        text: "Name",
        style: () => ({ maxWidth: '150px' })
      },
      {
        dataField: "position",
        text: "Position",
        style: () => ({ maxWidth: '150px' })
      },
      {
        dataField: "email",
        text: "Email",
        formatter: (cell, row) => (row.email) ? row.email : '-',
        style: () => ({ maxWidth: '150px' })
      },
      {
        dataField: "image",
        text: "Photo",
        formatter: (cell, row) => {
          return (
            <img src={getImageUrl(row.image)} alt='img' width="100" height="100" />
          )
        }
      },
      {
        dataField: "category",
        text: "Category",
        formatter: (cell, row) => filterCategory(row?.category),
        style: () => ({ maxWidth: '150px' })
      },
      // {
      //   dataField: "order",
      //   text: 'Sort Order',
      //   formatter: (cell, row) => (row?.order) ? row.order : '-'
      // },
      {
        dataField: "edit",
        text: 'Edit',
        formatter: (cell, row) => {
          return (
            <>
              <FaRegEdit className='me-3 cursor-pointer' onClick={() => funUpdateData(row)} />
              <FaTrashAlt className='cursor-pointer' onClick={() => funDeleteData(row)} />
            </>
          )
        }
      },
      {
        dataField: "publish",
        text: 'Publish',
        formatter: (cell, row) => {
          return (
            <input className='cursor-pointer' type='checkbox' name='checkbox' onChange={e => UpdateActiveStatus(row)} checked={row.publish} />
          )
        }
      },
    ]
    return columnFields;
  }

  const filterCategory = (id) => {
    let localAmbassador = dropDownData.filter(val => val._id === id);
    return (localAmbassador.length) ? localAmbassador?.[0]?.name : "-";
  }

  const headerData = {
    mainHeading: 'Contact Us',
    subHeadng: 'Our Team List',
    buttonText: ['Add New']
  };

  const formControls = ['name', 'position', 'email', 'details', 'dyncamiCategory', 'country', 'shortNo', 'image', 'nofooter'];
  const imageId = 'contactUs_teamList_tableImage';

  const [dropDownData, setDropDownData] = useState([]);
  const [search, setSearch] = useState("");
  const [tempData, setTempData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  let initialInputState = {
    name: "",
    position: "",
    email: "",
    details: "",
    category: "",
    country: "",
    order: (tableData?.length) ? tableData?.length + 1 : 1,
    image: ""
  }
  const [modalInputs, setModalInputs] = useState(initialInputState);
  const [previewId, setPreviewId] = useState("");

  useEffect(() => {
    const filtered = !search
      ? tempData
      : tempData.filter((person) =>
        person.name.toLowerCase().includes(search.toLowerCase()) ||
        person.position.toLowerCase().includes(search.toLowerCase()) ||
        person.email.toLowerCase().includes(search.toLowerCase()) ||
        person.category.toLowerCase().includes(search.toLowerCase())
      );
    setTableData(filtered);
  }, [search]);

  useEffect(() => {
    fetchTableData();
    fetchDropdownData();
  }, []);

  const fetchTableData = async () => {
    const res = await APIService.get(`/admin/contact-us/our-team`)
    if (res.status === 200) {
      setTableData(res.data.data);
      setTempData(res.data.data);
    }
  }

  const fetchDropdownData = async () => {
    const res = await APIService.get(`/admin/contact-us/category`)
    if (res.status === 200) {
      const allData = res?.data?.data;
      const allName = (allData && allData.length > 0 && allData.map(e => e.name)
      )
      setDropDownData(allData);
    }
  }

  const ourTeamCRUD = async (data) => {
    const res = await APIService.post(`/admin/contact-us/our-team`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast('success', res.data.message);
      }
      else {
        toggleToast('success', res.data);
      }
      fetchTableData();
    }
  }

  const addOurTeam = async () => {
    let validation = formValidation(modalInputs);
    if (validation !== false) {
      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        ...modalInputs
      }
      ourTeamCRUD(data);
      handleModal();
    }
  }

  const funUpdateData = (rowData) => {
    setModalInputs(rowData);
    setShowModal(!showModal);
    setPageData(prev => ({ ...prev, [imageId]: getImageUrl(rowData?.image) }))
  }

  const funDeleteData = (row) => {
    const confirmBox = window.confirm(
      "Do you really want to delete?"
    )
    if (confirmBox === true) {
      let data = {
        _id: row._id,
        isDeleted: true
      }
      ourTeamCRUD(data);
    }
  }

  const UpdateActiveStatus = (rowData) => {
    let data = {
      _id: rowData._id,
      publish: !rowData.publish,
    }
    ourTeamCRUD(data);
  }

  const handleInputChange = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    if (key === "search") {
      return setSearch(value);
    }
    setModalInputs({ ...modalInputs, [key]: value });
  }

  const formValidation = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === undefined) {
          if (key === "order") {
            toggleToast('danger', `Please enter integer value to set order`)
            return false;
          }
          toggleToast('danger', `Please enter ${key}`)
          return false;
        }
        if (key === 'email') {
          if (!emailValidator(obj['email'])) {
            toggleToast('danger', `Please enter valid email.`)
            return false;
          }
        }
      }
    }
  }

  const handleModal = () => {
    setShowModal(!showModal);
    setModalInputs(initialInputState);
    setPageData(prev => ({ ...prev, [imageId]: '' }))
  }

  const Preview_CRUD = async (data) => {
    let redirectTo = "contact-us";
    if (previewId !== "") {
      data = { ...data, _id: previewId }
    };
    const res = await APIService.post(`/admin/contact-us/preview`, data)
    if (res.status === 200) {
      let allData = res?.data?.data;
      setPreviewId(allData?._id);
      window.open(`${process.env.REACT_APP_FORNTEND_HOST}${redirectTo}?ispreview=true&previewid=${allData?._id}`, '_blank', 'windowFeatures');
    }
  }

  const addPreview = async () => {
    let validation = formValidation(modalInputs);
    if (validation !== false) {
      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        ...modalInputs
      }
      Preview_CRUD(data);
    }
  }

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate={handleModal}
      />

      <CardLayout>
        <Form.Group>
          <Form.Control
            name="search"
            placeholder="Search..."
            value={search}
            onChange={e => handleInputChange(e)}
            className='mb-2 w-25 ms-auto'
          ></Form.Control>
        </Form.Group>

        <Row>
          <BootstrapTable className="table-rc" keyField='id' data={tableData} columns={buildCols()} pagination={paginationFactory(paginationOption)} />
        </Row>
      </CardLayout>

      {
        showModal
          ?
          <ModalComp
            imageId={imageId}
            showModal={showModal}
            CUD_API={addOurTeam}
            Preview_API={addPreview}
            handleModal={handleModal}
            formControls={formControls}
            inputState={modalInputs}
            handleInputChange={handleInputChange}
            updateInputState={setModalInputs}
            dropdownData={dropDownData}
          />
          :
          null
      }
    </>
  )
}