import React, { useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CardLayout from '../../components/Layout/CardLayout';
import PageHeader from "../../components/Shared/Section/PageHeader";
import useToasts from '../../components/Toast/toastProvider';
import APIService from '../../utils/APIService';
import { setRowIndex, capitalizeFirstLetter } from '../../utils/Helper';

export default function ResourcesDynamicPages() {

  const navigate = useNavigate();
  const { category } = useParams();
  const { state } = useLocation();
  const headerData = {
    mainHeading: 'Resources',
    subHeadng: capitalizeFirstLetter(category),
    buttonText: [`New ${capitalizeFirstLetter(category)}`]
  };

  const { toggleToast } = useToasts();

  const [search, setSearch] = useState("");
  const [tempData, setTempData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tablePage, setTablePage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const paginationOption = { ...setRowIndex(setTablePage, setSizePerPage), page: tablePage, sizePerPage: sizePerPage };

  const buildCols = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return ((tablePage - 1) * sizePerPage + rowIndex + 1);
        },
      },
      {
        dataField: "companyName",
        text: "Company",
        style: () => ({ maxWidth: '250px' })
      },
      {
        dataField: "contactPerson",
        text: "Contact Person",
        formatter: (cell, row) => {
          if (row.contactPerson.length) {
            return row.contactPerson.map((val, i) => {
              return (
                <li key={i} style={{ listStyleType: "none" }} >{val.contactName}</li>
              )
            })
          }
          return "-";
        }
      },
      { dataField: "tel", text: "Phone" },
      { dataField: "publishedDate", text: "Date" },
      { dataField: "region.label", text: "Province" },
      {
        dataField: "edit",
        text: 'Edit',
        formatter: (cell, row) => {
          return (
            <>
              <FaRegEdit className='me-3 cursor-pointer' onClick={() => funUpdateData(row)} />
              <FaTrashAlt className='cursor-pointer' onClick={() => funDeleteResourcesData(row)} />
            </>
          )
        }
      },
      {
        dataField: "publish",
        text: 'Publish',
        formatter: (cell, row) => {
          return (
            <input className='cursor-pointer' type='checkbox' name='checkbox' onChange={e => UpdateActiveStatus(row)} defaultChecked={row.publish} />
          )
        }
      },
    ]
    return columnFields;
  }

  useEffect(() => {
    const filtered = !search
      ? tempData
      : tempData.filter((person) =>
        person?.companyName.toLowerCase().includes(search.toLowerCase()) ||
        person?.tel.toLowerCase().includes(search.toLowerCase()) ||
        person?.publishedDate.toLowerCase().includes(search.toLowerCase())
      );
    setTableData(filtered);
  }, [search])

  useEffect(() => {
    if (state !== null) {
      setTablePage(state.tablePage);
      setSizePerPage(state.sizePerPage);
    }
    else {
      setTablePage(1);
      setSizePerPage(10);
    }
    fetchTableData();
  }, [category])

  const fetchTableData = async () => {
    const res = await APIService.get(`/admin/resources/create/${category}`)
    if (res.status === 200) {
      let allData = res?.data?.data;
      setTableData(allData);
      setTempData(allData);
    }
    setLoading(false);
  }

  const resourcesCRUD = async (data) => {
    const res = await APIService.post(`/admin/resources/create`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast('success', res.data.message);
      }
      else {
        toggleToast('success', res.data);
      }
      fetchTableData();
    }
  }

  const funDeleteResourcesData = (row) => {
    const confirmBox = window.confirm(
      "Do you really want to delete?"
    )
    if (confirmBox === true) {
      let data = {
        _id: row._id,
        isDeleted: true
      }
      resourcesCRUD(data);
    }
  }

  const UpdateActiveStatus = (rowData) => {
    let data = {
      _id: rowData._id,
      publish: !rowData.publish,
    }
    resourcesCRUD(data);
  }

  let funUpdateData = (rowData) => {
    navigate(`/resources/create-resource?id=${rowData._id}&resource=${category}`, { state: { tablePage, sizePerPage } });
  }

  const redirectToCreatePage = () => {
    navigate(`/resources/create-resource?resource=${category}`);
  }

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate={redirectToCreatePage}
      />

      <CardLayout>
        <Form.Group >
          <Form.Control
            name="search"
            placeholder='Search...'
            value={search}
            onChange={e => setSearch(e.target.value)}
            className='mb-2 w-25 ms-auto'
          ></Form.Control>
        </Form.Group>

        <Row>
          {
            loading === false &&
            <BootstrapTable className="table-rc" keyField='id' data={tableData} columns={buildCols()} pagination={paginationFactory(paginationOption)} />
          }
        </Row>
      </CardLayout>

    </>
  )
}