import React, { useState, useEffect } from "react";
import { getCookies, setCookies, removeCookies } from "../utils/Helper";

const DataContext = React.createContext();

const CustomGlobalContextProvider = (props) => {
  const [token, setToken] = useState(false);

  const [user, setUser] = useState(null);
  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [mainData, setMainData] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [pagedata, setPageData] = useState({});
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    let userData = getCookies("tcebAdminData");
    refreshToken(userData);
  }, []);

  const refreshToken = (newToken = null) => {
    if (newToken) {
      // const name = `${newToken.firstName} ${newToken.lastName}`;
      setCookies("name", newToken.name);
      setCookies("email", newToken.email);
      setCookies("tcebAdminData", newToken);
    } else {
      removeCookies("tcebAdminData");
      removeCookies("email");
      removeCookies("name");
    }
    setToken(newToken?.password);
    setUser(newToken);
  };

  const toggleToast = (typeVal, messageVal) => {
    setShow(true);
    setType(typeVal);
    setMessage(messageVal);
  };

  const hideToast = () => setShow(false);
  const setMainDatahandle = (data) => setMainData(data);
  const setUpPercentage = (data) => setPercentage(data);
  const setShowcomponent = (data) => setShowComponent(data);

  const allprops = {
    show,
    type,
    message,
    pagedata,
    setPageData,
    toggleToast,
    hideToast,
    mainData,
    setMainDatahandle,
    showComponent,
    setUpPercentage,
    setShowcomponent,
    percentage,
    user,
    token,
    refreshToken,
  };

  return <DataContext.Provider value={allprops}>{props.children}</DataContext.Provider>;
};

export { CustomGlobalContextProvider, DataContext };
