import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa'
import TextEditor from "../../components/Shared/components/TextEditor";
import { Link } from 'react-router-dom'
import CardLayout from '../../components/Layout/CardLayout'
import ModalComp from '../../components/Shared/components/ModalComp'
import SelectMedia from '../../components/Shared/Media/SelectMedia'
import PageHeader from '../../components/Shared/Section/PageHeader'
import useToasts from '../../components/Toast/toastProvider'
import { DataContext } from '../../context/CustomGlobalContext'
import APIService from '../../utils/APIService'
import { getImageUrl, setRowIndex, targetUrlValidator } from '../../utils/Helper'

const Destination = () => {
  const { setPageData } = useContext(DataContext)
  const [showModal, setShowModal] = useState(false)
  const headerData = {
    mainHeading: 'Why Thailand',
    subHeadng: 'Destination',
    buttonText: ['Update']
  }

  const formControls = ['name', 'details', 'targeturl_disable', 'image'];
  const pagename = "whyThailandDestination";
  const imageId = 'destination_tableImage';
  const imageId1 = "destination_sectionImage1";
  const imageId2 = "destination_sectionImage2";

  const { toggleToast } = useToasts()

  const [tempData, setTempData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [pageDataApiResponse, setPageDataApiResponse] = useState();
  const [search, setSearch] = useState("");

  const [localdata, setLocalData] = useState({
    details: '',
    imageUrl: ''
  })

  const [localdata2, setLocalData2] = useState({
    head: '',
    subhead: '',
    image: ''
  })

  let initialInputState = {
    name: "",
    targeturl: "",
    details: "",
    status: "Public",
    image: ""
  }
  const [modalInputs, setModalInputs] = useState(initialInputState);

  const [tablePage, setTablePage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const paginationOption = setRowIndex(setTablePage, setSizePerPage);

  const buildCols = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return ((tablePage - 1) * sizePerPage + rowIndex + 1);
        },
      },
      { dataField: "name", text: "Destination" },
      {
        dataField: "targeturl",
        text: "Target URL",
        style: () => ({ maxWidth: '150px' })
      },
      {
        dataField: "image",
        text: "Image",
        formatter: (cell, row) => {
          return (
            <img src={getImageUrl(row?.image)} alt='img' width="100" height="100" />
          )
        }
      },
      {
        dataField: "status",
        text: "Status",
        formatter: (cell, row) => (row?.publish) ? "Public" : "Private"
      },
      {
        dataField: "created",
        text: "Date",
        formatter: (cell, row) => {
          let date = new Date(row.created);
          let final = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          return final;
        }
      },
      {
        dataField: "edit",
        text: 'Edit',
        formatter: (cell, row) => {
          return (
            <div className='mt-2' >
              <FaRegEdit className='me-3 cursor-pointer' onClick={() => funUpdateData(row)} />
              <FaTrashAlt className='cursor-pointer' onClick={() => funDeleteData(row)} />
              <div className='mt-2' >
                <Link
                  size='sm'
                  to={`/why-thailand/subcategory?slug=${row?.targeturl}&parent=${pageDataApiResponse?._id}&isdestination=${true}`}
                  className='cursor-pointer'
                >
                  Content
                </Link>
              </div>
            </div>
          )
        }
      },
      {
        dataField: "publish",
        text: 'Publish',
        formatter: (cell, row) => {
          return (
            <input className='cursor-pointer' type='checkbox' name='checkbox' onChange={e => UpdateActiveStatus(row)} defaultChecked={row.publish} />
          )
        }
      },
    ]
    return columnFields;
  }

  const fetchPageData = async () => {
    const res = await APIService.get(`/admin/page/item?pagename=${pagename}`)
    if (res.status === 200) {
      let allData = res.data?.data?.[0];
      let data = allData.sections?.[0];
      setPageDataApiResponse(allData);
      setLocalData2(allData?.overview);
      setLocalData(prev => ({ ...prev, ...data }))
      setPageData(prev => ({ ...prev, [imageId1]: getImageUrl(allData?.overview?.image), [imageId2]: getImageUrl(data?.imageUrl) }));
    }
  }

  const submitOverviewData = async () => {
    let payload = { ...pageDataApiResponse };
    payload.overview = localdata2;
    // call the put api for the data update
    const res = await APIService.put(`/admin/page/${pagename}`, { payload });
    if (res.status === 200) {
      toggleToast('success', 'Page updated successfully ')
    } else {
      toggleToast('failure', 'Page not updated successfully ')
    }
  }

  const submitData = async () => {
    let payload = { ...pageDataApiResponse };
    payload.sections = [{ ...localdata }];
    // call the put api for the data update
    const res = await APIService.put(`/admin/page/${pagename}`, { payload });
    if (res.status === 200) {
      toggleToast('success', 'Page updated successfully ')
    } else {
      toggleToast('failure', 'Page not updated successfully ')
    }
  }

  const handleModal = () => {
    setShowModal(!showModal);
    setModalInputs(initialInputState);
    setPageData(prev => ({ ...prev, [imageId]: '' }))
  }

  const funDeleteData = (row) => {
    const confirmBox = window.confirm(
      "Do you really want to delete?"
    )
    if (confirmBox === true) {
      let data = {
        _id: row._id,
        isDeleted: true
      }
      destinationCRUD(data);
    }
  }

  const UpdateActiveStatus = (rowData) => {
    let data = {
      _id: rowData._id,
      publish: !rowData.publish,
    }
    destinationCRUD(data);
  }

  const addDestination = async () => {
    let validation = formValidation(modalInputs);
    if (validation !== false) {
      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        ...modalInputs
      }
      destinationCRUD(data);
    }
  }

  let funUpdateData = (rowData) => {
    setModalInputs(rowData);
    setShowModal(!showModal);
    setPageData(prev => ({ ...prev, [imageId]: getImageUrl(rowData?.image) }))
  }

  useEffect(() => {
    const filtered = !search
      ? tempData
      : tempData.filter((person) =>
        person?.name.toLowerCase().includes(search.toLowerCase()) ||
        person?.targeturl.toLowerCase().includes(search.toLowerCase()) ||
        person?.status.toLowerCase().includes(search.toLowerCase())
      );
    setTableData(filtered);
  }, [search])

  useEffect(() => {
    fetchTableData();
    fetchPageData();
  }, [])

  const fetchTableData = async () => {
    const res = await APIService.get(`/admin/why-thailand/destination`)
    if (res.status === 200) {
      setTableData(res.data.data);
      setTempData(res.data.data);
    }
  }

  const formValidation = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === undefined) {
          toggleToast('danger', `Please enter ${key}`)
          return false;
        }
      }
    }
  }

  const handleInputChange = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    if (key === "search") {
      return setSearch(value);
    }
    if (key === "targeturl") {
      let validValue = targetUrlValidator(e.target.value);
      if (validValue?.message !== "") {
        toggleToast('danger', validValue?.message);
      }
      return setModalInputs({ ...modalInputs, [key]: validValue.result });
    }
    setModalInputs({ ...modalInputs, [key]: value });
  }

  const destinationCRUD = async (data) => {
    const res = await APIService.post(`/admin/why-thailand/destination`, data)
    if (res?.status === 200) {
      if (res?.data?.message) {
        toggleToast('success', res?.data?.message);
      }
      else {
        toggleToast('success', res?.data);
      }
      fetchTableData();
      setModalInputs(initialInputState);
      setPageData(prev => ({ ...prev, [imageId]: '' }))
      setShowModal(false);
    }
    else {
      toggleToast('danger', res?.data?.message);
    }
  }

  const deleteMedia = (mediaId, mediaName, index) => {
    localdata[mediaName] = '';
    setPageData(prev => {
      return { ...prev, [mediaId]: '' }
    });
  }

  return (
    <>
      {/* Page Header */}
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate={submitOverviewData}
      />
      <>
        <CardLayout>
          <Row>
            <Col md={8}>
              <Form>
                <Form.Group
                  className='mb-3'
                  controlId='exampleForm.ControlInput1'
                >
                  <Form.Label>Head</Form.Label>
                  <Form.Control
                    type='text'
                    value={localdata2?.head ? localdata2.head : ''}
                    name='head'
                    onChange={e => setLocalData2({ ...localdata2, ['head']: e.target.value })}
                  />
                </Form.Group>
                <Form.Group
                  className='mb-3'
                  controlId='exampleForm.ControlTextarea1'
                >
                  <Form.Label>Sub-head</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={2}
                    style={{ resize: 'none' }}
                    name='subhead'
                    value={localdata2?.subhead ? localdata2.subhead : ''}
                    onChange={e => setLocalData2({ ...localdata2, ['subhead']: e.target.value })}
                  />
                </Form.Group>
              </Form>
            </Col>
            <Col
              md={3}
              className='d-flex justify-content-center align-self-center offset-md-1'
            >
              <SelectMedia
                type={'image'}
                name="image"
                id={imageId1}
                imgSrc={(localdata2?.image) ? localdata2?.image : ''}
                updateInputState={setLocalData2}
              />
            </Col>
          </Row>
        </CardLayout>

        <hr />

        {/* Page Header */}
        <PageHeader
          buttonText={headerData.buttonText[0]}
          handleUpdate={submitData}
        />
        <CardLayout>
          <Row>
            <Col md={8}>
              <Form>
                <Form.Group
                  className='mb-3'
                  controlId='exampleForm.ControlInput1'
                >
                  <Form.Label>Details</Form.Label>
                  <TextEditor
                    value={localdata.details ? localdata.details : ""}
                    handleInputChange={(value) => setLocalData(prev => ({ ...prev, details: value }))}
                  />
                </Form.Group>
              </Form>
            </Col>
            <Col
              md={3}
              className='align-self-center col-md-3 offset-md-1'
            >
              {localdata?.imageUrl &&
                <button
                  type="button"
                  className="btn btn-danger btn-circle float-end"
                  title='Remove Media'
                  onClick={() => deleteMedia(imageId2, 'imageUrl')}
                >
                  X
                </button>
              }
              <div className='mt-5' >
                <SelectMedia
                  type={'image'}
                  name="imageUrl"
                  id={imageId2}
                  imgSrc={(localdata?.imageUrl) ? localdata?.imageUrl : ''}
                  updateInputState={setLocalData}
                />
              </div>
            </Col>
          </Row>
        </CardLayout>

        <div className="w-100" >
          <Button
            className="me-1 float-end"
            variant='primary'
            size='sm'
            onClick={() => setShowModal(!showModal)}
          >
            Add New
          </Button>
          <br />
          <br />
        </div>

        {pageDataApiResponse?._id &&
          <CardLayout>
            <Form.Group>
              <Form.Control
                name="search"
                placeholder="Search..."
                value={search}
                onChange={e => handleInputChange(e)}
                className='mb-2 w-25 ms-auto'
              ></Form.Control>
            </Form.Group>

            <Row>
              <BootstrapTable className="table-rc" keyField='id' data={tableData} columns={buildCols()} pagination={paginationFactory(paginationOption)} />
            </Row>
          </CardLayout>
        }
        {
          showModal
            ?
            <ModalComp
              imageId={imageId}
              showModal={showModal}
              CUD_API={addDestination}
              handleModal={handleModal}
              formControls={formControls}
              inputState={modalInputs}
              handleInputChange={handleInputChange}
              updateInputState={setModalInputs}
            />
            :
            null
        }

      </>
    </>
  )
}

export default Destination