import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router";
import WhyThailand from ".";
import { CompData } from "../../components/Rawdata/TempCompData";
import PageHeader from "../../components/Shared/Section/PageHeader";
import Suspense from "../../components/Shared/spinner/suspender";
import useToasts from "../../components/Toast/toastProvider";
import { DataContext } from "../../context/CustomGlobalContext";
import APIService from "../../utils/APIService";
import { getComponentItemId, getImageUrl, parseUrl, setRowIndex } from "../../utils/Helper";

import { FaRegEdit, FaTrashAlt } from 'react-icons/fa';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import CardLayout from "../../components/Layout/CardLayout";
import { Row, Col, Form, Container, Modal, Button } from "react-bootstrap";
import ModalComp from "../../components/Shared/components/ModalComp";
import TextEditor from "../../components/Shared/components/TextEditor";
const OverView = React.lazy(() => import('../../components/Shared/Section/OverView'));
const Content = React.lazy(() => import('../../components/Shared/Section/Content'));
const SectionTable = React.lazy(() => import('../../components/Shared/Section/SectionTable'));
const SectionNewTable = React.lazy(() => import('../../components/Shared/Section/NewSectionTable'));
const SectionDetails = React.lazy(() => import('../../components/Shared/Section/DetailImageSection'));
const RobustIndustries = React.lazy(() => import('../../components/Shared/pages/RobustInd'));
const RecursiveSection = React.lazy(() => import('../../components/Shared/Section/RecursiveSection'));

const CACategory = () => {

  const { toggleToast } = useToasts();
  const { pagedata, setPageData, showComponent, setShowcomponent, mainData } = useContext(DataContext)
  const [pageSpecificComponent, setPageSpecificComponent] = useState([])

  const [columns, setColumns] = useState([])
  const [headerData, setHeaderdata] = useState({
    mainHeading: 'Convention Ambassadors',
    subHeadng: 'Category',
    buttonText: []
  })

  const [tablePage, setTablePage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const paginationOption = setRowIndex(setTablePage, setSizePerPage);

  const buildCols = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return ((tablePage - 1) * sizePerPage + rowIndex + 1);
        },
      },
      { dataField: "name", text: "Category Name" },
      {
        dataField: "edit",
        text: 'Edit',
        formatter: (cell, row) => {
          return (
            <>
              <FaRegEdit className='me-3 cursor-pointer' onClick={() => funUpdateData(row)} />
              <FaTrashAlt className='cursor-pointer' onClick={() => funDeleteData(row)} />
            </>
          )
        }
      },
      {
        dataField: "publish",
        text: 'Publish',
        formatter: (cell, row) => {
          return (
            <input className='cursor-pointer' type='checkbox' name='checkbox' onChange={e => UpdateActiveStatus(row)} defaultChecked={row.publish} />
          )
        }
      }
    ]
    return columnFields;
  }

  const formControls = ['Convention Ambassadors Category'];
  const imageId = 'inTheSpotLight';

  const [search, setSearch] = useState("");
  const [tempData, setTempData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  let initialInputState = {
    name: ""
  }
  const [modalInputs, setModalInputs] = useState(initialInputState);

  useEffect(() => {
    fetchTableData();
  }, [])

  useEffect(() => {
    const filtered = !search
      ? tempData
      : tempData.filter((person) =>
        person.name.toLowerCase().includes(search.toLowerCase())
      );
    setTableData(filtered);
  }, [search])

  const fetchTableData = async () => {
    const res = await APIService.get(`/admin/convention/category`)
    if (res.status === 200) {
      setTableData(res.data.data);
      setTempData(res.data.data);
    }
  }

  let funUpdateData = (rowData) => {
    setModalInputs(rowData);
    setShowModal(!showModal);
    setPageData(prev => ({ ...prev, [imageId]: getImageUrl(rowData?.image) }))
  }

  const funDeleteData = (row) => {
    const confirmBox = window.confirm(
      "Do you really want to delete?"
    )
    if (confirmBox === true) {
      let data = {
        _id: row._id,
        isDeleted: true
      }
      categoryCRUD(data);
    }
  }

  const handleInputChange = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    if (key === "search") {
      return setSearch(value);
    }
    setModalInputs({ ...modalInputs, [key]: value });
  }

  const handleModal = () => {
    setShowModal(!showModal);
    setModalInputs(initialInputState);
    setPageData(prev => ({ ...prev, [imageId]: '' }))
  }

  const addNewCategory = async () => {
    let validation = formValidation(modalInputs);
    if (validation !== false) {
      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        ...modalInputs
      }
      categoryCRUD(data);
      handleModal();
    }
  }

  const UpdateActiveStatus = (rowData) => {
    let data = {
      _id: rowData._id,
      publish: !rowData.publish,
    }
    categoryCRUD(data);
  }

  const formValidation = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === undefined) {
          toggleToast('danger', `Please enter ${key}`)
          return false;
        }
      }
    }
  }

  const categoryCRUD = async (data) => {
    const res = await APIService.post(`/admin/convention/category`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast('success', res.data.message);
      }
      else {
        toggleToast('success', res.data);
      }
      fetchTableData();
    }
  }

  return (
    <>
      {/* Page Header */}
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
      />

      <div className="w-100" >
        <Button
          className="me-1 float-end"
          variant='primary'
          size='sm'
          onClick={handleModal}
        >
          New Category
        </Button>
        <br />
        <br />
      </div>

      <CardLayout>
        <Form.Group >
          <Form.Control
            name="search"
            placeholder='Search...'
            value={search}
            onChange={e => handleInputChange(e)}
            className='mb-2 w-25 ms-auto'
          ></Form.Control>
        </Form.Group>

        <Row>
          <BootstrapTable className="table-rc" keyField='id' data={tableData} columns={buildCols()} pagination={paginationFactory(paginationOption)} />
        </Row>
      </CardLayout>

      {
        showModal
          ?
          <ModalComp
            imageId={imageId}
            showModal={showModal}
            CUD_API={addNewCategory}
            handleModal={handleModal}
            formControls={formControls}
            inputState={modalInputs}
            handleInputChange={handleInputChange}
            updateInputState={setModalInputs}
          />
          :
          null
      }

    </>
  );
};

export default CACategory;