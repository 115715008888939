import React, { useContext, useState } from 'react'
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { DataContext } from '../../context/CustomGlobalContext';
import ProgressBar from 'react-bootstrap/ProgressBar';

export const ToastComponent = () => {
    const { show, type, message, hideToast, percentage } = useContext(DataContext);
    return (
        <>
            <ToastContainer position="top-end" className="p-3 position-fixed mt-5" style={{ zIndex: "1056" }}>
                <Toast
                    className="d-inline-block m-1"
                    bg={type}
                    show={show}
                    autohide
                    onClose={hideToast}
                >
                    <Toast.Header>
                        <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                        />
                        {/* <strong className="me-auto">Warning</strong> */}
                        {/* <small>11 mins ago</small> */}
                    </Toast.Header>
                    <Toast.Body className='text-dark'>
                        {message}
                        {/* <ProgressBar animated now={percentage} variant="success" /> */}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    )
}

