import React from "react";
import { Card } from "react-bootstrap";

const CardLayout = ({ children }) => {
  return (
    <Card className="text-left mb-4">
      <Card.Body>{children}</Card.Body>
    </Card>
  );
};

export default CardLayout;
