import React, { useState } from 'react'
import PageHeader from '../../components/Shared/Section/PageHeader'

export default function CaseStudies() {

  const [headerData, setHeaderdata] = useState({
    mainHeading: 'Case Studies',
    subHeadng: '',
    buttonText: [""]
  })

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate=''
      />
    </>
  )
}
