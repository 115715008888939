import React, { useContext, useEffect, useState } from "react";
import { Col, Form, ProgressBar, Row } from "react-bootstrap";
import { DataContext } from "../../../context/CustomGlobalContext";
import { getImageUrl } from "../../../utils/Helper";
import SelectMedia from "../Media/SelectMedia";

const ImageSection = ({
  localdata,
  setLocalData,
  handleInputChange,
  imageId,
  isDisable = false,
  forImage = ""
}) => {

  const { pagedata, setPageData } = useContext(DataContext);
  const [mediaType, setMediaType] = useState();
  const [checkboxhandle, setCheckboxHandle] = useState(false)
  const [locId, setLocId] = useState()
  const [progress, setProgress] = useState();
  const checkMediaType = (mediaType === "image") ? `imageUrl${forImage}` : `videoUrl${forImage}`;

  useEffect(() => {
    let savedMediaType = (localdata?.[`isImageChecked${forImage}`]) ? "image" : "video";
    setMediaType(savedMediaType);
    let updateContext = (localdata?.[`isImageChecked${forImage}`]) ? localdata?.[`imageUrl${forImage}`] : localdata?.[`videoUrl${forImage}`];
    setPageData(prev => ({ ...prev, [imageId]: getImageUrl(updateContext) }))
  }, [])

  useEffect(() => {
    let updateContext = (mediaType === "image") ? localdata?.[`imageUrl${forImage}`] : localdata?.[`videoUrl${forImage}`];
    setPageData(prev => ({ ...prev, [imageId]: getImageUrl(updateContext) }))

    if (mediaType === "video") {
      setLocalData(prev => ({ ...prev, [`isVideoChecked${forImage}`]: true, [`isImageChecked${forImage}`]: false }));
    }
    else {
      setLocalData(prev => ({ ...prev, [`isVideoChecked${forImage}`]: false, [`isImageChecked${forImage}`]: true }));
    }
  }, [mediaType])

  const createProgressBar = (data) => {
    let progressBar = data();
    if (progressBar?.progressBar) {
      setProgress(progressBar?.progressBar);
      return
    }
    setProgress();
  };

  const deleteMedia = (mediaId, mediaName, index) => {
    setLocalData(prev => ({ ...prev, [mediaName]: '' }));
    setPageData(prev => {
      return { ...prev, [mediaId]: '' }
    });
  }

  return (
    <Row className="d-flex justify-content-center">
      <Row className="d-flex justify-content-between mb-2">
        <Col className="section-primary">Image {forImage}</Col>
        <Col className="d-flex justify-content-end align-items-center">
          <input
            title={isDisable ? "Disabled" : ''}
            disabled={isDisable}
            type="checkbox"
            className="cursor-pointer"
            name={`isMediaChecked${forImage}`}
            value={localdata?.[`isMediaChecked${forImage}`]}
            checked={localdata?.[`isMediaChecked${forImage}`]}
            onChange={e => handleInputChange(e, "checkbox")}
          />
        </Col>
        {localdata?.[checkMediaType] &&
          <Col className="d-flex justify-content-end align-items-center">
            <button
              type="button"
              className="btn btn-danger btn-circle float-end"
              title='Remove Media'
              onClick={() => deleteMedia(imageId, checkMediaType)}
            >
              X
            </button>
          </Col>
        }
      </Row>
      {progress && <ProgressBar animated now={progress} label={`${progress}%`} className='w-100 mb-2' />}
      <Row>
        <SelectMedia
          type={mediaType}
          name={(mediaType === "image") ? `imageUrl${forImage}` : `videoUrl${forImage}`}
          id={imageId}
          imgSrc={(localdata?.media) ? localdata?.media : ''}
          updateInputState={data => {
            createProgressBar(data);
            let locally = data();
            return setLocalData(prev => ({ ...prev, ...locally }))
          }}

          isFor="sectionImage"
        />
      </Row>
      <Row>
        <div className="mt-2">
          <Form
            className="d-flex justify-content-between"
          >
            <Form.Check
              inline
              label="Image"
              name="group1"
              type="radio"
              className="cursor-pointer"
              value="image"
              checked={mediaType === "image"}
              onChange={(e) => {
                setMediaType(e.target.value);
              }}
            />
            <Form.Check
              inline
              label="Video"
              name="group1"
              type="radio"
              className="cursor-pointer"
              value="video"
              checked={mediaType === "video"}
              onChange={(e) => {
                setMediaType(e.target.value);
              }}
            />
          </Form>
        </div>
      </Row>
    </Row>
  );
};

export default ImageSection;