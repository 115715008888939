import React from "react";
import { Button } from "react-bootstrap";

const PageHeader = ({ mainHeading, subHeadng, buttonText, buttonState = false, handleUpdate, secondButtonText, secondHandleUpdate }) => {
  return (
    <div className="d-flex justify-content-between mb-4">
      <h1 className="page-header">
        {mainHeading}
        {subHeadng && <span> - {subHeadng}</span>}
      </h1>
      <div>
        {buttonText &&
          <Button variant="primary" onClick={handleUpdate} disabled={buttonState}>
            {buttonText}
          </Button>
        }
        {secondButtonText &&
          <Button variant="primary" onClick={secondHandleUpdate} className='ms-2'>
            {secondButtonText}
          </Button>
        }
      </div>
    </div>
  );
};

export default PageHeader;
