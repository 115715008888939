import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router";
import { CompData } from "../../components/Rawdata/TempCompData";
import PageHeader from "../../components/Shared/Section/PageHeader";
import Suspense from "../../components/Shared/spinner/suspender";
import useToasts from "../../components/Toast/toastProvider";
import { DataContext } from "../../context/CustomGlobalContext";
import APIService from "../../utils/APIService";
import { getComponentItemId, parseUrl } from "../../utils/Helper";

const OverView = React.lazy(() => import('../../components/Shared/Section/OverView'));
const Content = React.lazy(() => import('../../components/Shared/Section/Content'));
const SectionTable = React.lazy(() => import('../../components/Shared/Section/SectionTable'));
const SectionNewTable = React.lazy(() => import('../../components/Shared/Section/NewSectionTable'));
const SectionDetails = React.lazy(() => import('../../components/Shared/Section/DetailImageSection'));
const RobustIndustries = React.lazy(() => import('../../components/Shared/pages/RobustInd'));
const RecursiveSection = React.lazy(() => import('../../components/Shared/Section/RecursiveSection'));

const TeamCategory = () => {
  const { pagedata, setPageData, showComponent, setShowcomponent, mainData } = useContext(DataContext)
  const [pageSpecificComponent, setPageSpecificComponent] = useState([])
  const [headerData, setHeaderdata] = useState({
    mainHeading: '',
    subHeadng: '',
    buttonText: []
  })
  const location = useLocation();
  const { toggleToast } = useToasts();

  const cmpName = parseUrl(location, 'absolute')
  const cmpParentName = parseUrl(location, 'parent')
  const componentName = parseUrl(location, 'noObs')

  const itemId = getComponentItemId(mainData, cmpParentName, cmpName)
//   const itemId = getPageItemId(mainData, cmpParentName, cmpName)
    //  let thisPageName = "home"   
    //  const itemId = getPageItemId(mainData, "home")


// console.log('get the pageid', pagedata, mainData, itemId)

  const validations = () => {
    for (let key in pagedata) {
      if (pagedata.hasOwnProperty(key)) {
        if (!pagedata[key]) {
          toggleToast('danger', "All fields are required!")
          return false
        }
      }
    }
    return true
  }

  const submitData = async () => {
    // const componentName = parseUrl(location, 'noObs')
    // const { formData, object } = buildFormData(componentName, pagedata)
    // console.log('formdata', formData.get('media'))
    // const response = await APIService.post('/admin/page', formData, { "Content-Type": "multipart/form-data" })
    // if (response.status === 200) {
    //   let tempObj = {}
    //   for (let key in object) {
    //     tempObj = { ...tempObj, [key]: '' }
    //   }
    //   setPageData(tempObj)
    //   toggleToast('success', response.data.message)
    // }
  }

  const fetchPageData = async () => {
    // if(!itemId) return;
    const res = await APIService.get(`/admin/page/item?itemid=${itemId}`)
    if(res.status === 200){
      setPageData(res.data.data[0])
    }
  }



  return (
    <>
        <h1> I am TeamCategoryTeamCategoryTeamCategory </h1>
    </>
  );
};

export default TeamCategory;