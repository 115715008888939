import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa';
import { Link, useNavigate } from "react-router-dom";
import CardLayout from "../../components/Layout/CardLayout";
import ModalComp from "../../components/Shared/components/ModalComp";
import PageHeader from "../../components/Shared/Section/PageHeader";
import useToasts from "../../components/Toast/toastProvider";
import { DataContext } from "../../context/CustomGlobalContext";
import APIService from "../../utils/APIService";
import { getImageUrl, setRowIndex } from "../../utils/Helper";

const Listing = () => {

  const navigate = useNavigate();
  const { toggleToast } = useToasts();
  const headerData = {
    mainHeading: 'Ambassadors',
    subHeadng: 'Listing',
    buttonText: ['New Ambassador']
  }
  const [localdata2, setLocalData2] = useState({
    head: "",
    subhead: ""
  });

  const [pageDataApiResponse, setPageDataApiResponse] = useState();
  const { setPageData } = useContext(DataContext);
  const pagename = "conventionAmbassadorsInterviewListing";

  const handleValueChange = (e, field) => {
    setLocalData2({ ...localdata2, [field]: e.target.value });
  };

  const submitData = async () => {
    let payload = { ...pageDataApiResponse };
    payload.overview = localdata2;
    // call the put api for the data update
    const res = await APIService.put(`/admin/page/${pagename}`, { payload });
    if (res.status === 200) {
      toggleToast('success', 'Page updated successfully ')
    } else {
      toggleToast('failure', 'Page not updated successfully ')
    }
  }

  const fetchPageData = async () => {
    const res = await APIService.get(`/admin/page/item?pagename=${pagename}`)
    if (res.status === 200) {
      let allData = res.data?.data?.[0];
      let data = allData?.overview;
      setPageDataApiResponse(allData);
      setLocalData2(data);
      setPageData(allData)
      setPageData(prev => ({ ...prev, [imageId]: getImageUrl(data?.image) }));
    }
  }

  // -------------------------------Ambassadors - Listing CODE-------------------------------

  const formControls1 = ['name', 'position', 'category', 'details', 'image'];
  const imageId = 'update_ambassadors';

  const [tablePage1, setTablePage1] = useState(1);
  const [sizePerPage1, setSizePerPage1] = useState(10);
  const paginationOption1 = setRowIndex(setTablePage1, setSizePerPage1);

  const buildCols1 = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return ((tablePage1 - 1) * sizePerPage1 + rowIndex + 1);
        },
      },
      { dataField: "name", text: "Name" },
      {
        dataField: "position",
        text: "Position",
        style: () => ({ maxWidth: '150px' })
      },
      {
        dataField: "detailsambassadors",
        text: "Details",
        style: () => ({ maxWidth: '250px' })
      },
      {
        dataField: "email",
        text: "Email",
        style: () => ({ maxWidth: '100px' })
      },
      { dataField: "category", text: "Category" },
      {
        dataField: "image",
        text: "Photo",
        formatter: (cell, row) => {
          return (
            <img src={getImageUrl(row?.image)} alt='img' width="100" height="100" />
          )
        }
      },
      {
        dataField: "edit",
        text: 'Edit',
        classes: 'w10',
        formatter: (cell, row) => {
          return (
            <>
              <FaRegEdit className='me-3 cursor-pointer' onClick={() => funUpdateData1(row)} />
              <FaTrashAlt className='cursor-pointer' onClick={() => funDeleteAmbassadorData(row)} />
            </>
          )
        }
      },
      {
        dataField: "publish",
        text: 'Publish',
        formatter: (cell, row) => {
          return (
            <input className='cursor-pointer' type='checkbox' name='checkbox' onChange={e => UpdateActiveStatus1(row)} defaultChecked={row.publish} />
          )
        }
      },
    ]
    return columnFields;
  }
  const [search1, setSearch1] = useState("");
  const [tempData1, setTempData1] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [showModal1, setShowModal1] = useState(false);
  let initialInputState1 = {
    name: ""
  }
  const [modalInputs1, setModalInputs1] = useState(initialInputState1);

  useEffect(() => {
    const filtered = !search1
      ? tempData1
      : tempData1.filter((person) =>
        person.name.toLowerCase().includes(search1.toLowerCase()) ||
        person.position.toLowerCase().includes(search1.toLowerCase()) ||
        person.detailshome.toLowerCase().includes(search1.toLowerCase()) ||
        person.email.toLowerCase().includes(search1.toLowerCase()) ||
        person.category.toLowerCase().includes(search1.toLowerCase())
      );
    setTableData1(filtered);
  }, [search1])

  const fetchTableData1 = async () => {
    const res = await APIService.get(`/admin/convention/ambassadors`)
    if (res.status === 200) {
      let allName = (res?.data?.data && res?.data?.data.length > 0 && res?.data?.data.map(e => e.name)
      )
      setDropDownData(allName);
      setTableData1(res.data.data);
      setTempData1(res.data.data);
    }
  }

  const handleModal1 = () => {
    setShowModal1(!showModal1);
    setModalInputs1(initialInputState1);
    setPageData(prev => ({ ...prev, [imageId]: '' }))
  }

  let funUpdateData1 = (rowData) => {
    // setModalInputs1(rowData);
    // setShowModal1(!showModal1);
    // setPageData(prev => ({ ...prev, [imageId]: getImageUrl(rowData?.image) }))
    navigate(`/convention-ambassadors/create?id=${rowData._id}`);
  }

  const handleInputChange1 = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    if (key === "search1") {
      return setSearch1(value);
    }
    setModalInputs1({ ...modalInputs1, [key]: value });
  }

  const AmbassadorListingCRUD = async (data) => {
    const res = await APIService.post(`/admin/convention/ambassadors`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast('success', res.data.message);
      }
      else {
        toggleToast('success', res.data);
      }
      fetchTableData1();
    }
  }

  const updateAmbassadorListing = () => {
    let validation = formValidation(modalInputs1);
    if (validation !== false) {
      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        ...modalInputs1
      }
      AmbassadorListingCRUD(data);
      handleModal1();
    }
  }

  const funDeleteAmbassadorData = (row) => {
    const confirmBox = window.confirm(
      "Do you really want to delete?"
    )
    if (confirmBox === true) {
      let data = {
        _id: row._id,
        isDeleted: true
      }
      AmbassadorListingCRUD(data);
    }
  }

  const UpdateActiveStatus1 = (rowData) => {
    let data = {
      _id: rowData._id,
      publish: !rowData.publish,
    }
    AmbassadorListingCRUD(data);
  }

  // -------------------------------Interview - Listing CODE-------------------------------

  const formControls2 = ['New Interview'];

  const [dropDownData, setDropDownData] = useState([]);

  const [tablePage2, setTablePage2] = useState(1);
  const [sizePerPage2, setSizePerPage2] = useState(10);
  const paginationOption2 = setRowIndex(setTablePage2, setSizePerPage2);

  const buildCols2 = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return ((tablePage2 - 1) * sizePerPage2 + rowIndex + 1);
        },
      },
      { dataField: "name", text: "Name" },
      { dataField: "question", text: "Question" },
      { dataField: "answer", text: "Answer" },
      {
        dataField: "edit",
        text: 'Edit',
        formatter: (cell, row) => {
          return (
            <>
              <FaRegEdit className='me-3 cursor-pointer' onClick={() => funUpdateData2(row)} />
              <FaTrashAlt className='cursor-pointer' onClick={() => funDeleteInterviewData(row)} />
            </>
          )
        }
      },
      {
        dataField: "publish",
        text: 'Publish',
        formatter: (cell, row) => {
          return (
            <input className='cursor-pointer' type='checkbox' onChange={e => UpdateActiveStatus2(row)} name='checkbox' defaultChecked={row.publish} />
          )
        }
      },
    ]
    return columnFields;
  }
  const [search2, setSearch2] = useState("");
  const [tempData2, setTempData2] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [showModal2, setShowModal2] = useState(false);
  let initialInputState2 = {
    name: "",
    question: "",
    answer: ""
  }
  const [modalInputs2, setModalInputs2] = useState(initialInputState2);

  useEffect(() => {
    fetchTableData1();
    fetchTableData2();
    fetchPageData();
  }, [])

  useEffect(() => {
    const filtered = !search2
      ? tempData2
      : tempData2.filter((person) =>
        person.name.toLowerCase().includes(search2.toLowerCase()) ||
        person.question.toLowerCase().includes(search2.toLowerCase()) ||
        person.answer.toLowerCase().includes(search2.toLowerCase())
      );
    setTableData2(filtered);
  }, [search2])

  const fetchTableData2 = async () => {
    const res = await APIService.get(`/admin/convention/interview`)
    if (res.status === 200) {
      setTableData2(res.data.data);
      setTempData2(res.data.data);
    }
  }

  const interviewCRUD = async (data) => {
    const res = await APIService.post(`/admin/convention/interview`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast('success', res.data.message);
      }
      else {
        toggleToast('success', res.data);
      }
      fetchTableData2();
    }
  }

  const addNewInterview = async () => {
    let validation = formValidation(modalInputs2);
    if (validation !== false) {
      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        ...modalInputs2
      }
      interviewCRUD(data);
      handleModal2();
    }
  }

  const UpdateActiveStatus2 = (rowData) => {
    let data = {
      _id: rowData._id,
      publish: !rowData.publish,
    }
    interviewCRUD(data);
  }

  let funUpdateData2 = (rowData) => {
    setModalInputs2(rowData);
    setShowModal2(!showModal2);
  }

  const funDeleteInterviewData = (row) => {
    const confirmBox = window.confirm(
      "Do you really want to delete?"
    )
    if (confirmBox === true) {
      let data = {
        _id: row._id,
        isDeleted: true
      }
      interviewCRUD(data);
    }
  }

  const handleModal2 = () => {
    setShowModal2(!showModal2);
    setModalInputs2(initialInputState2);
  }

  const handleInputChange2 = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    if (key === "search2") {
      return setSearch2(value);
    }
    setModalInputs2({ ...modalInputs2, [key]: value });
  }

  const formValidation = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === undefined) {
          toggleToast('danger', `Please enter ${key}`)
          return false;
        }
      }
    }
  }

  const NavigatToAmbassadorsCreatePage = () => {
    navigate('/convention-ambassadors/create');
  }

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText}
        handleUpdate={NavigatToAmbassadorsCreatePage}
      />

      <CardLayout>
        <Form.Group >
          <Form.Control
            name="search1"
            placeholder='Search...'
            value={search1}
            className='mb-2 w-25 ms-auto'
            onChange={e => handleInputChange1(e)}
          ></Form.Control>
        </Form.Group>

        <Row>
          <BootstrapTable className="table-rc" keyField='id' data={tableData1} columns={buildCols1()} pagination={paginationFactory(paginationOption1)} />
        </Row>
      </CardLayout>

      <PageHeader
        mainHeading="Interview"
        subHeadng="Listing"
        buttonText="Update"
        handleUpdate={submitData}
      />

      <CardLayout>
        <Row>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Head</Form.Label>
              <Form.Control
                type="text"
                value={localdata2?.head ? localdata2.head : ''}
                name="head"
                onChange={(e) => handleValueChange(e, "head")}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Sub-head</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                style={{ resize: "none" }}
                name="subhead"
                value={localdata2.subhead ? localdata2.subhead : ''}
                onChange={(e) => handleValueChange(e, "subhead")}
              />
            </Form.Group>
          </Form>
        </Row>
      </CardLayout>

      <PageHeader
        buttonText="New Interview"
        handleUpdate={handleModal2}
      />

      <CardLayout>
        <Form.Group >
          <Form.Control
            name="search2"
            placeholder='Search...'
            value={search2}
            className='mb-2 w-25 ms-auto'
            onChange={e => handleInputChange2(e)}
          ></Form.Control>
        </Form.Group>

        <Row>
          <BootstrapTable className="table-rc" keyField='id' data={tableData2} columns={buildCols2()} pagination={paginationFactory(paginationOption2)} />
        </Row>
      </CardLayout>

      {
        showModal1
          ?
          <ModalComp
            imageId={imageId}
            showModal={showModal1}
            CUD_API={updateAmbassadorListing}
            handleModal={handleModal1}
            formControls={formControls1}
            inputState={modalInputs1}
            handleInputChange={handleInputChange1}
            updateInputState={setModalInputs1}
          />
          :
          showModal2
            ?
            <ModalComp
              imageId=''
              showModal={showModal2}
              CUD_API={addNewInterview}
              handleModal={handleModal2}
              formControls={formControls2}
              inputState={modalInputs2}
              handleInputChange={handleInputChange2}
              updateInputState={setModalInputs2}
              dropdownData={dropDownData}
            />
            :
            null
      }

    </>
  );
};

export default Listing;