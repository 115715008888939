import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../context/CustomGlobalContext";
import APIService from "../../../utils/APIService";
import useToasts from "../../Toast/toastProvider";


const SelectMedia = ({ type, name, id, disabled = true, updateInputState, localdata, isFor }) => {
  const { toggleToast } = useToasts();
  const { pagedata, setPageData } = useContext(DataContext)
  const [tempStorage, setTempStorage] = useState('')
  const [clr, setClr] = useState('')
  const [vidURL, setvidURL] = useState()

  useEffect(() => {
    let localsetvidURL = (localdata?.isVideoChecked) ? localdata?.videoUrl : "";
    setvidURL(localsetvidURL);
  }, [localdata]);

  const handleSelect = async (e) => {
    if (type === 'color') {
      setClr(e.target.value)
      updateInputState(prev => ({ ...prev, backgroundColor: e.target.value }))
      return
    }
    else if (type === 'video') {
      const file = e.target.files[0]
      const key = id
      setPageData(prev => ({ ...prev, [key]: file }))
      updateInputState(prev => ({ ...prev, buttonState: true }))
      await uploadVideo(file)
      e.target.value = ''
      return
    }
    const file = e.target.files[0]
    const key = id
    setPageData(prev => ({ ...prev, [key]: file }))
    await uploadImage(file)
    e.target.value = ''
  }

  // Send this to server and get the url
  const uploadImage = async (file) => {
    let formdata = new FormData();
    formdata.append("media", file, file.name);
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/admin/page/upload`, formdata, {
      headers: { "Content-Type": "multipart/form-data" }
    })
      .catch(err => {
        let errMessage = err?.response?.data?.message;
        toggleToast('danger', errMessage);
      });

    if (res.status === 200) {
      /// [`${id}-path`] THIS WILL GIVE YOU THE URL OF THE IMAGE
      setPageData(prev => ({ ...prev, [`${id}-path`]: `${process.env.REACT_APP_API_URL}/${res.data.path}` }))
      // updateInputState(prev => ({ ...prev, ['image']: `${res.data.path}` }))
      updateInputState(prev => ({ ...prev, [name]: `${res.data.path}`, buttonState: false }))
    } else {
      console.log('Failed in uploading BLOODY FILE')
    }
  }

  const uploadVideo = async (file) => {
    let formdata = new FormData();
    formdata.append("media", file, file.name);

    const res = await axios.post(`${process.env.REACT_APP_API_URL}/admin/page/uploadvideo`, formdata, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: data => {
        let progress = (Math.round((100 * data.loaded) / data.total));
        updateInputState(prev => ({ ...prev, progressBar: progress }));
      }
    })
    if (res.status === 200) {
      /// [`${id}-path`] THIS WILL GIVE YOU THE URL OF THE VIDEO

      if (res?.data?.status === false) {
        toggleToast('danger', res?.data?.message)
      }
      else {
        setPageData(prev => ({ ...prev, [`${id}-path`]: `${process.env.REACT_APP_API_URL}/${res.data.path}` }))
        updateInputState(prev => ({ ...prev, ['videoUrl']: `${res.data.path}` }))
        updateInputState(prev => ({ ...prev, [name]: `${res.data.path}`, buttonState: false }))
      }
    } else {
      console.log('Failed in uploading BLOODY FILE')
    }
  }

  // useEffect(() => {
  //   if (type === 'color' && pagedata[id]) {
  //     // setTempStorage(pagedata[id])
  //     setPageData(prev => ({ ...prev, [id]: '' }))

  //   }
  //   if (type === 'image' && tempStorage) {
  //     setClr('')
  //     // setPageData(prev => ({ ...prev, [id]: tempStorage }))
  //   }
  // }, [type])

  const renderLabel = (type) => {
    let label = "";
    switch (type) {
      case "image":
        label = "Select Image";
        break;
      case "video":
        label = "Select Video";
        break;
      case "color":
        label = "Select Color";
        break;
      default:
        break;
    }
    return label;
  };

  const renderInput = (type) => {
    let input = "";
    switch (type) {
      case "image":
        input = (
          <input
            type="file"
            id={`upload-${id}`}
            accept=".png, .jpg, .jpeg"
            onChange={(e) => handleSelect(e, 'image')}
            name="image"
            className="d-none"
          />
        );
        break;
      case "video":
        input = (
          <input
            type="file"
            id={`upload-${id}`}
            accept=".mp4, .avi, .mkv"
            onChange={(e) => handleSelect(e, 'video')}
            className="d-none"
            name="video"
          />
        );
        break;
      case "color":
        input = (
          <input
            type="color"
            id={`upload-${id}`}
            value={(localdata?.backgroundColor) ? localdata?.backgroundColor : clr}
            onChange={(e) => {
              handleSelect(e, 'color')
            }}
            className="hidden"
          />
        );
        break;
      default:
        break;
    }
    return input;
  };

  return (

    <>
      {
        isFor === "backgroundImage"
          ?
          <label
            className={(type === 'image' && pagedata[id]) || `media-select-square ${disabled || 'pe-none'}`}
            htmlFor={`upload-${id}`}
            style={{ background: (localdata?.isBackgroundColorChecked) ? localdata?.backgroundColor : clr }}
          >
            {
              pagedata[id] && type === 'image'
                ?
                <img
                  src={(typeof pagedata[id] !== 'object') ? pagedata[id] : URL.createObjectURL(pagedata[id])}
                  alt="no-alt"
                  className="media-select-squareImage"
                  name={name}
                />
                :
                <span className="content">{renderLabel(type)}</span>
            }
          </label>
          :
          <label
            className={pagedata[id] || `media-select-square ${disabled || 'pe-none'}`}
            htmlFor={`upload-${id}`}
            style={{ background: (localdata?.isBackgroundColorChecked) ? localdata?.backgroundColor : clr }}
          >
            {
              pagedata[id] && type === 'image' ?
                <img
                  src={(typeof pagedata[id] !== 'object') ? pagedata[id] : URL.createObjectURL(pagedata[id])}
                  alt="no-alt"
                  className="media-select-squareImage"
                  name={name}
                />
                :
                <span className="content cursor-pointer" style={{ color: '#135179' }} >{renderLabel(type)}</span>
            }
            {
              type !== "image" && pagedata[id] &&
              <video
                width="250"
                height="150"
                controls
                src={(typeof pagedata[id] !== 'object') ? pagedata[id] : URL.createObjectURL(pagedata[id])}
              />
            }

          </label>
      }
      {renderInput(type)}
    </>
  );
};

export default SelectMedia;