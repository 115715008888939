import React, { useState } from 'react';
import PageHeader from '../../components/Shared/Section/PageHeader';

export default function PreviousConventions() {

  const [headerData, setHeaderdata] = useState({
    mainHeading: 'Previous Conventions',
    subHeadng: '',
    buttonText: [""]
  })

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate=''
      />
    </>
  )
}
