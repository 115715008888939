import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from "react-bootstrap";

import CardLayout from "../components/Layout/CardLayout";
import PageHeader from "../components/Shared/Section/PageHeader";
import useToasts from '../components/Toast/toastProvider';
import { DataContext } from '../context/CustomGlobalContext';
import APIService from '../utils/APIService';
import { emailValidator } from '../utils/Helper';

export default function ResetPassword() {

  const { user, refreshToken } = useContext(DataContext);

  const { toggleToast } = useToasts();
  const headerData = {
    mainHeading: 'Reset',
    subHeadng: 'Password',
    buttonText: []
  };

  const initialState = {
    oldpassword: "",
    newpassword: ""
  }
  const [localdata, setLocalData] = useState(initialState);

  const handleInputChange = (e) => {
    setLocalData({ ...localdata, [e.target.name]: e.target.value });
  };

  const submitData = async () => {
    let validation = formValidation(localdata);
    if (validation !== false) {
      let payload = { _id: user._id, ...localdata };

      const res = await APIService.post(`/admin/auth/update-user`, payload);
      if (res.status === 200) {
        let updated = res?.data?.data;
        toggleToast('success', res?.data?.message);
        setLocalData(initialState);
        refreshToken(updated)
      } else {
        toggleToast('danger', res?.data?.message)
      }
    }
  }

  const formValidation = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === undefined) {
          toggleToast('danger', `Please enter ${key}`)
          return false;
        }
      }
      if (key === 'email') {
        if (!emailValidator(obj['email'])) {
          toggleToast('danger', `Please enter valid email.`)
          return false;
        }
      }
    }
  }

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate=""
      />

      <CardLayout>

        <Form.Group className="mb-3">
          <Form.Label>Old Password</Form.Label>
          <Form.Control
            placeholder="Please enter old password"
            name="oldpassword"
            type="password"
            size="md"
            value={(localdata?.oldpassword) ? localdata?.oldpassword : ""}
            onChange={e => handleInputChange(e)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            placeholder="Please enter new password"
            name="newpassword"
            type="password"
            size="md"
            value={(localdata?.newpassword) ? localdata?.newpassword : ""}
            onChange={e => handleInputChange(e)}
            autoComplete="off"
          ></Form.Control>
        </Form.Group>

        <Button
          className="me-1"
          variant='success'
          size='md'
          onClick={submitData}
        >
          Save
        </Button>

      </CardLayout>

    </>
  )
}