import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import CardLayout from "../../components/Layout/CardLayout";
import PageHeader from "../../components/Shared/Section/PageHeader";
import useToasts from "../../components/Toast/toastProvider";
import { DataContext } from "../../context/CustomGlobalContext";
import APIService from "../../utils/APIService";

import SectionBackground from "../../components/Shared/components2/SectionBackground";
import SectionButton from "../../components/Shared/components2/SectionButton";
import SectionImage from "../../components/Shared/components2/SectionImage";
import { getImageUrl } from "../../utils/Helper";
import TextEditor from "../../components/Shared/components/TextEditor"

const GovernmentalFacilitation = () => {

  const { toggleToast } = useToasts();
  const { setPageData } = useContext(DataContext);

  const headerData = {
    mainHeading: 'Plan Your Event',
    subHeadng: 'Governmental Facilitation',
    buttonText: ["Update"]
  }

  const pagename = "planYourEventGovernmentalFacilitation";
  const imageId = "planYourEvent_governmentalFacilitation_image";
  const bgImageId = "planYourEvent_governmentalFacilitation_bgimage";

  const defaultSection = {
    title: "",
    details: "",
    align: "",

    isMediaChecked: false,
    imageUrl: "",
    videoUrl: "",
    isImageChecked: true,
    isVideoChecked: false,

    backgroundUrl: "",
    backgroundColor: "",
    isBackgroundImageChecked: false,
    isBackgroundColorChecked: false,
    isBackgroundChecked: false,

    buttonUrl: "",
    buttonText: "",
    buttonAlign: "",
    buttonColor: "",
    isButtonChecked: false,
  };

  const [pageDataApiResponse, setPageDataApiResponse] = useState();
  const [buttonState, setButtonState] = useState(false);

  useEffect(() => {
    fetchPageData();
  }, []);

  const fetchPageData = async () => {
    const res = await APIService.get(`/admin/page/item?pagename=${pagename}`);
    if (res.status === 200) {
      let allData = res.data?.data?.[0];
      let data = allData.sections?.[0];

      setPageDataApiResponse(allData);
      allData.sections.map((val, i) => {
        allData[`${imageId}-${i}`] = getImageUrl(val?.imageUrl);
        allData[`${bgImageId}-${i}`] = getImageUrl(val?.backgroundUrl);
      });
      setPageData(allData);
    }
  };

  const updatePageState = (updateSection, index) => {
    let progressBar = updateSection();
    if (progressBar?.progressBar) {
      setButtonState(true);
      return
    }
    let pageData = { ...pageDataApiResponse };
    let updatedSection = updateSection(pageData.sections[index]);
    pageData.sections[index] = updatedSection;
    setPageDataApiResponse(pageData);
    setButtonState(false);
  };

  const addNewSection = () => {
    let oldpageData = { ...pageDataApiResponse };
    let addNewSection = defaultSection
    let index = pageDataApiResponse?.sections.length;
    oldpageData[`${imageId}-${index}`] = getImageUrl("");
    oldpageData[`${bgImageId}-${index}`] = getImageUrl("");
    oldpageData.sections.push(addNewSection);
    setPageDataApiResponse(oldpageData);
    // setPageData({...pageData,sections:oldpageData})
  };

  const submitData = async () => {
    let payload = { ...pageDataApiResponse };
    const res = await APIService.put(`/admin/page/${pagename}`, { payload });
    if (res.status === 200) {
      toggleToast("success", "Page updated successfully ");
    } else {
      toggleToast("failure", "Page not updated successfully ");
    }
  };

  const handleInputChange = (e, field, index) => {

    let pageData = { ...pageDataApiResponse };
    if (field === "checkbox") {
      pageData.sections[index] = { ...pageData.sections[index], [e.target.name]: e.target.checked };
    } else {
      pageData.sections[index] = { ...pageData.sections[index], [e.target.name]: e.target.value };
    }
    setPageDataApiResponse(pageData);
  };

  const handleInputChangeForEditor = (value, index) => {
    let pageData = { ...pageDataApiResponse };
    pageData.sections[index] = { ...pageData.sections[index], ['details']: value };
    setPageDataApiResponse(pageData);
  }

  const deleteSection = (index) => {
    const confirmBox = window.confirm(
      "Do you really want to delete this section?"
    )
    if (confirmBox === true) {
      let oldpageData = { ...pageDataApiResponse };
      let sectionFilter = oldpageData.sections.filter((val, i) => (i !== index));
      oldpageData.sections = sectionFilter;
      setPageDataApiResponse(oldpageData);
    }
  }

  return (
    <>

      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        buttonState={buttonState}
        handleUpdate={submitData}
      />

      {pageDataApiResponse?.sections &&
        pageDataApiResponse?.sections.length > 0 &&
        pageDataApiResponse?.sections.map((localdata, index) => (
          <>
            <CardLayout>
              <Row>
                <Col md={1}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Title</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={9}>
                  <Form className="mb-3" controlId="title">
                    <Form.Control
                      type="text"
                      name="title"
                      value={localdata.title ? localdata.title : ""}
                      onChange={(e) => handleInputChange(e, "title", index)}
                    />
                  </Form>
                </Col>
                <Col md={2}></Col>
              </Row>
              <Row>
                <Col md={1}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Details</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={9}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <TextEditor
                      value={localdata.details ? localdata.details : ""}
                      handleInputChange={(value) => handleInputChangeForEditor(value, index)}
                    />
                  </Form.Group>
                </Col>
                <Col md={2}></Col>
              </Row>
            </CardLayout>

            <CardLayout>
              <Row className="section-config">
                <Col className="offset-md-1" md={3}>
                  <SectionImage
                    localdata={localdata}
                    setLocalData={(updateSection) => updatePageState(updateSection, index)}
                    locationid={"about-us-media"}
                    handleInputChange={(e, field) => handleInputChange(e, field, index)}
                    imageId={`${imageId}-${index}`}
                  />
                </Col>
                <Col className="offset-md-1" md={3}>
                  <SectionBackground
                    index={index}
                    localdata={localdata}
                    setLocalData={(updateSection) => updatePageState(updateSection, index)}
                    locationid={"about-us-background-media"}
                    handleInputChange={(e, field) => handleInputChange(e, field, index)}
                    bgImageId={`${bgImageId}-${index}`}
                  />
                </Col>
                <Col className="offset-md-1" md={3}>
                  <SectionButton
                    localdata={localdata}
                    setLocalData={(updateSection) => updatePageState(updateSection, index)}
                    handleInputChange={(e, field) => handleInputChange(e, field, index)}
                  />
                </Col>
              </Row>
              {
                index && index > 0 && <Button onClick={e => deleteSection(index)} className="mt-5" variant="danger" >Delete Section</Button> || ''
              }
            </CardLayout>
          </>
        ))}

      <div className="add_section" onClick={addNewSection}>
        <span>add section</span> <span className="add_icon">+</span>
      </div>

    </>
  );
};

export default GovernmentalFacilitation;