import React, { useContext, useEffect, useState } from "react";
import PageHeader from "../../components/Shared/Section/PageHeader";
import useToasts from "../../components/Toast/toastProvider";
import { DataContext } from "../../context/CustomGlobalContext";
import APIService from "../../utils/APIService";

import { Form, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaRegEdit, FaTrashAlt } from "react-icons/fa";
import CardLayout from "../../components/Layout/CardLayout";
import ModalComp from "../../components/Shared/components/ModalComp";
import { getImageUrl, setRowIndex, targetUrlValidator } from "../../utils/Helper";

const ResourcesCategory = () => {

  const { toggleToast } = useToasts();
  const { setPageData, setMainDatahandle } = useContext(DataContext);

  const headerData = {
    mainHeading: "Resources",
    subHeadng: "Category",
    buttonText: ["New Category", "New Certification"]
  };

  const [tablePage, setTablePage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const paginationOption = setRowIndex(setTablePage, setSizePerPage);

  const buildCols = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return ((tablePage - 1) * sizePerPage + rowIndex + 1);
        },
      },
      { dataField: "name", text: "Category Name" },
      {
        dataField: "edit",
        text: "Edit",
        formatter: (cell, row) => {
          return (
            <>
              <FaRegEdit className='me-3 cursor-pointer' onClick={() => funUpdateData1(row)} />
              <FaTrashAlt className='cursor-pointer' onClick={() => funDeleteData1(row)} />
            </>
          )
        }
      },
      {
        dataField: "publish",
        text: "Publish",
        formatter: (cell, row) => {
          return (
            <input className='cursor-pointer' type="checkbox" name="checkbox" onChange={e => UpdateActiveStatus1(row)} defaultChecked={row.publish} />
          )
        }
      },
    ];
    return columnFields;
  };

  const formControls1 = ["Category", "slug_noLabel", "name_noLabel", "image"];
  const formControls2 = ['Certification', 'name_noLabel', 'image'];
  const imageId1 = "resources_category_image";
  const imageId2 = "resources_certification_image";

  const [search1, setSearch1] = useState("");
  const [tempData1, setTempData1] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [showModal1, setShowModal1] = useState(false);
  let initialInputState1 = {
    name: "",
    slug: "",
    image: ""
  };
  const [modalInputs1, setModalInputs1] = useState(initialInputState1);

  useEffect(() => {
    fetchTableData1();
    fetchTableData2();
  }, []);

  useEffect(() => {
    const filtered = !search1
      ? tempData1
      : tempData1.filter((person) =>
        person.name.toLowerCase().includes(search1.toLowerCase())
      );
    setTableData1(filtered);
  }, [search1]);

  const fetchTableData1 = async () => {
    const res = await APIService.get(`/admin/resources/category`)
    if (res.status === 200) {
      setTableData1(res.data.data);
      setTempData1(res.data.data);
    }
  };

  let funUpdateData1 = (rowData) => {
    setModalInputs1(rowData);
    setShowModal1(!showModal1);
    setPageData(prev => ({ ...prev, [imageId1]: getImageUrl(rowData?.image) }))
  };

  const funDeleteData1 = (row) => {
    const confirmBox = window.confirm(
      "Do you really want to delete?"
    )
    if (confirmBox === true) {
      let data = {
        _id: row._id,
        slug: row.slug,
        isDeleted: true
      }
      categoryCRUD(data);
    }
  };

  const UpdateActiveStatus1 = (rowData) => {
    let data = {
      _id: rowData._id,
      publish: !rowData.publish,
    }
    categoryCRUD(data);
  };

  const handleInputChange1 = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    if (key === "search1") {
      return setSearch1(value);
    }
    else if (key === "slug") {
      let validValue = targetUrlValidator(value);
      if (validValue?.message !== "") {
        toggleToast('danger', validValue?.message);
      }
      return setModalInputs1({ ...modalInputs1, [key]: validValue.result });
    }
    setModalInputs1({ ...modalInputs1, [key]: value });
  };

  const handleModal1 = () => {
    setShowModal1(!showModal1);
    setModalInputs1(initialInputState1);
    setPageData(prev => ({ ...prev, [imageId1]: "" }));
  };

  const addNewCategory1 = async () => {
    let validation = formValidation(modalInputs1);
    if (validation !== false) {
      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        ...modalInputs1
      }
      categoryCRUD(data);
    }
  };

  const formValidation = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === "" || obj[key] === undefined) {
          toggleToast("danger", `Please enter ${key}`);
          return false;
        }
      }
    }
  };

  const categoryCRUD = async (data) => {
    const res = await APIService.post(`/admin/resources/category`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast("success", res.data.message);
      }
      else {
        toggleToast("success", res.data);
      }
      getSidebarData();
      fetchTableData1();
      setShowModal1(false);
      setModalInputs1(initialInputState1);
      setPageData(prev => ({ ...prev, [imageId1]: "" }));
    }
    else {
      toggleToast('danger', res?.data?.message);
    }
  }

  const getSidebarData = async () => {
    const response = await APIService.get("/admin/menu");
    if (response.status === 200) {
      setMainDatahandle(response.data.data);
    }
  };

  // CERTIFICATION CODE

  const [search2, setSearch2] = useState("");
  const [tempData2, setTempData2] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [showModal2, setShowModal2] = useState(false);
  let initialInputState2 = {
    name: "",
    image: ""
  }
  const [modalInputs2, setModalInputs2] = useState(initialInputState2);

  useEffect(() => {
    const filtered = !search2
      ? tempData2
      : tempData2.filter((person) =>
        person?.name.toLowerCase().includes(search2.toLowerCase())
      );
    setTableData2(filtered);
  }, [search2]);

  const addNewCertification = async () => {
    let validation = formValidation(modalInputs2);
    if (validation !== false) {
      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        ...modalInputs2
      }
      certificationCRUD(data);
      handleModal2();
    }
  }

  const certificationCRUD = async (data) => {
    const res = await APIService.post(`/admin/resources/certification`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast('success', res.data.message);
      }
      else {
        toggleToast('success', res.data);
      }
      fetchTableData2();
    }
  }

  const fetchTableData2 = async () => {
    const res = await APIService.get(`/admin/resources/certification`)
    if (res.status === 200) {
      setTableData2(res.data.data);
      setTempData2(res.data.data);
    }
  }

  const handleModal2 = () => {
    setShowModal2(!showModal2);
    setModalInputs2(initialInputState2);
    setPageData(prev => ({ ...prev, [imageId2]: '' }))
  }

  const handleInputChange2 = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    if (key === "search2") {
      return setSearch2(value);
    }
    setModalInputs2({ ...modalInputs2, [key]: value });
  }

  const [tablePage2, setTablePage2] = useState(1);
  const [sizePerPage2, setSizePerPage2] = useState(10);
  const paginationOption2 = setRowIndex(setTablePage2, setSizePerPage2);

  const buildCols2 = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return ((tablePage2 - 1) * sizePerPage2 + rowIndex + 1);
        },
      },
      { dataField: "name", text: "Certification Name" },
      {
        dataField: "image",
        text: "Photo",
        formatter: (cell, row) => {
          return (
            <img src={getImageUrl(row?.image)} alt='img' width="100" height="100" />
          )
        }
      },
      {
        dataField: "publish",
        text: "Status",
        formatter: (cell, row) => (row.publish) ? "Public" : "Private",
        style: () => ({ maxWidth: '200px' })
      },
      {
        dataField: "edit",
        text: 'Edit',
        formatter: (cell, row) => {
          return (
            <>
              <FaRegEdit className='me-3 cursor-pointer' onClick={() => funUpdateData2(row)} />
              <FaTrashAlt className='cursor-pointer' onClick={() => funDeleteCertificationData(row)} />
            </>
          )
        }
      },
      {
        dataField: "publish",
        text: 'Publish',
        formatter: (cell, row) => {
          return (
            <input className='cursor-pointer' type='checkbox' name='checkbox' onChange={e => UpdateActiveStatus2(row)} defaultChecked={row.publish} />
          )
        }
      },
    ]
    return columnFields;
  }

  let funUpdateData2 = (rowData) => {
    setModalInputs2(rowData);
    setShowModal2(!showModal2);
    setPageData(prev => ({ ...prev, [imageId2]: getImageUrl(rowData?.image) }))
  }

  const funDeleteCertificationData = (row) => {
    const confirmBox = window.confirm(
      "Do you really want to delete?"
    )
    if (confirmBox === true) {
      let data = {
        _id: row._id,
        isDeleted: true
      }
      certificationCRUD(data);
    }
  }

  const UpdateActiveStatus2 = (rowData) => {
    let data = {
      _id: rowData._id,
      publish: !rowData.publish,
    }
    certificationCRUD(data);
  }

  return (
    <>
      {/* Page Header */}
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate={handleModal1}
      />

      <CardLayout>
        <Form.Group >
          <Form.Control
            name="search1"
            placeholder="Search..."
            value={search1}
            onChange={e => handleInputChange1(e)}
            className="mb-2 w-25 ms-auto"
          ></Form.Control>
        </Form.Group>

        <Row>
          <BootstrapTable className="table-rc" keyField="id" data={tableData1} columns={buildCols()} pagination={paginationFactory(paginationOption)} />
        </Row>
      </CardLayout>

      <PageHeader
        buttonText={headerData.buttonText[1]}
        handleUpdate={handleModal2}
      />

      <CardLayout>
        <Form.Group >
          <Form.Control
            name="search2"
            placeholder='Search...'
            value={search2}
            onChange={e => handleInputChange2(e)}
            className='mb-2 w-25 ms-auto'
          ></Form.Control>
        </Form.Group>

        <Row>
          <BootstrapTable className="table-rc" keyField='id' data={tableData2} columns={buildCols2()} pagination={paginationFactory(paginationOption2)} />
        </Row>
      </CardLayout>

      {
        showModal1
          ?
          <ModalComp
            imageId={imageId1}
            showModal={showModal1}
            CUD_API={addNewCategory1}
            handleModal={handleModal1}
            formControls={formControls1}
            inputState={modalInputs1}
            handleInputChange={handleInputChange1}
            updateInputState={setModalInputs1}
          />
          :
          showModal2
            ?
            <ModalComp
              imageId={imageId2}
              showModal={showModal2}
              CUD_API={addNewCertification}
              handleModal={handleModal2}
              formControls={formControls2}
              inputState={modalInputs2}
              handleInputChange={handleInputChange2}
              updateInputState={setModalInputs2}
            />
            :
            null
      }

    </>
  );
};

export default ResourcesCategory;