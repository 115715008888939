import React from "react";
import Header from "../components/Layout/Header";
import SideBar from "../components/Layout/SideBar";

const RootLayout = ({ children }) => {
  return (
    <div id="wrapper" className="fixed-sidebar fixed-navbar">
      <SideBar />
      <div id="main">
        <Header />
        <div id="main-body">{children}</div>
      </div>
    </div>
  );
};

export default RootLayout;
