import { useState } from "react";
import Select from "react-select";

const CustomSelect = (props) => {
  const { options = [], isMulti = false, onChange, defaultOption = [], selectedOption = [], onFocus } = props;
  // const [selectedOption, setSelectedOption] = useState(defaultOption);

  const handleChange = (selectedOption) => {
    // setSelectedOption(selectedOption);
    onChange(selectedOption);
  };

  return (
    <Select
      defaultValue={defaultOption}
      value={selectedOption}
      onChange={handleChange}
      options={options}
      isMulti={isMulti}
      onFocus={onFocus}
    />
  );
};

export default CustomSelect;
