export const CompData = [
    {
        identity: 'home',
        heading: 'Home',
        subHeading: 'Overview',
        button: ['Update'],
        components: ['Heading-SubHeading'],
        backendFields: ['head', 'subhead', 'media', "pageId"],
        pageId: 1
    },
    {

        identity: 'home/about',
        heading: 'About Us',
        subHeading: '',
        button: ['Update'],
        components: ['Title-Details', 'Image-Video-Button', 'AddSection'],
        backendFields: ['title', 'detail', 'media', "mediaAlign", "mediaType", "bg", "bgType", "btnText", "btnColor", "btnAlign", "btnUrl", "pageId"],
        pageId: 1
    },
    {
        identity: 'home/taskforce',
        heading: 'C Taskforce',
        subHeading: '',
        button: ['Update', 'New C Taskforce'],
        components: ['Title-Details', 'Image-Video-Button', 'Table'],
        backendFields: ['title', 'detail', 'media', "mediaAlign", "mediaType", "bg", "bgType", "btnText", "btnColor", "btnAlign", "btnUrl", "pageId"],
        pageId: 1,
        tableData: [
            {
                dataField: 'id',
                text: 'ID',
            },
            {
                dataField: 'name',
                text: 'Name',
            },
            {
                dataField: 'position',
                text: 'Position',
            },
            {
                dataField: 'detail',
                text: 'Details',
            },
            {
                dataField: 'email',
                text: 'Email',
            }
        ]
    },
    {
        identity: 'home/founding-members',
        heading: 'Founding Members',
        subHeading: '',
        button: ['Update', 'New Founding Members'],
        pageId: 1,
        backendFields: ['title', 'detail', 'media', "mediaAlign", "mediaType", "bg", "bgType", "btnText", "btnColor", "btnAlign", "btnUrl", "pageId"],
        components: ['Title-Details', 'Image-Video-Button', 'AddSection', 'Table'],
        tableData: [
            {
                dataField: 'id',
                text: 'ID',
            },
            {
                dataField: 'name',
                text: 'Name',
            },
            {
                dataField: 'position',
                text: 'Position',
            },
            {
                dataField: 'detail',
                text: 'Details',
            },
            {
                dataField: 'email',
                text: 'Email',
            }
        ]
    },
    {
        identity: 'home/spotlight',
        heading: 'In the Spotlight',
        subHeading: '',
        pageId: 1,
        button: ['Update', 'New Spotlight'],
        components: ['Title', 'Details', 'Image-Video-Button', 'AddSection', 'Table'],
        backendFields: ['title', 'detail', 'media', "mediaAlign", "mediaType", "bg", "bgType", "btnText", "btnColor", "btnAlign", "btnUrl", "pageId"],
        tableData: [
            {
                dataField: 'id',
                text: 'ID',
            },
            {
                dataField: 'name',
                text: 'Name',
            },
            {
                dataField: 'targeturl',
                text: 'Target Url',
            },
            {
                dataField: 'category',
                text: 'Category',
            },
            {
                dataField: 'date',
                text: 'Date',
            }
        ]
    },
    {
        identity: 'why-thailand',
        pageId: 2,
        heading: 'Why Thailand',
        subHeading: 'Overview',
        button: ['Update', 'Update'],
        components: ['Heading-SubHeading', 'Title-Details', 'Image-Video-Button', 'AddSection']
    },
    {
        identity: 'why-thailand/category',
        heading: 'Why Thailand',
        subHeading: 'Category',
        button: ['', 'New Category'],
        components: ['NewTable'],
        backendFields: ['category', "pageId"],
        tableData: [
            {
                dataField: 'id',
                text: 'ID',
            },
            {
                dataField: 'category',
                text: 'Category',
            },
            {
                dataField: 'status',
                text: 'Status',
            },
            {
                dataField: 'date',
                text: 'Date',
            }
        ]
    },
    {
        identity: 'why-thailand/robust-industries',
        heading: 'Why Thailand',
        subHeading: 'Robust Industries(Overview page)',
        button: ['Update', 'Add New'],
        backendFields: ['title', 'detail', "pageId"],
        components: ['Heading-SubHeading', 'Title-Details-Image', 'Table'],
        tableData: [
            {
                dataField: 'id',
                text: 'ID',
            },
            {
                dataField: 'robustind',
                text: 'Robust industries',
            },
            {
                dataField: 'targeturl',
                text: 'Target Url',
            },
            {
                dataField: 'status',
                text: 'Status',
            },
            {
                dataField: 'ambassador',
                text: 'Ambassador',
            },
            {
                dataField: 'date',
                text: 'Date',
            }
        ]
    },
    {
        identity: 'why-thailand/destination',
        heading: 'Why Thailand',
        subHeading: 'Destination',
        button: ['Update', 'Add New'],
        backendFields: ['title', 'detail', "pageId"],
        components: ['Heading-SubHeading', 'Title-Details-Image', 'Table'],
        tableData: [
            {
                dataField: 'id',
                text: 'ID',
            },
            {
                dataField: 'destination',
                text: 'Destination',
            },
            {
                dataField: 'targeturl',
                text: 'Target Url',
            },
            {
                dataField: 'status',
                text: 'Status',
            },
            {
                dataField: 'date',
                text: 'Date',
            }
        ]
    },
    {
        identity: 'why-thailand/sustainability',
        heading: 'Why Thailand',
        subHeading: 'Sustainability',
        button: ['Update', 'Add New'],
        backendFields: ['title', 'detail', "pageId"],
        components: ['Heading-SubHeading', 'Title-Details-Image', 'Table'],
        tableData: [
            {
                dataField: 'id',
                text: 'ID',
            },
            {
                dataField: 'sustainability',
                text: 'Sustainability',
            },
            {
                dataField: 'targeturl',
                text: 'Target Url',
            },
            {
                dataField: 'status',
                text: 'Status',
            },
            {
                dataField: 'date',
                text: 'Date',
            }
        ]
    },
    {
        identity: 'convention-ambassadors',
        heading: 'Convention Ambassadors',
        subHeading: 'Overview',
        button: ['Update'],
        components: ['Heading-SubHeading'],
        backendFields: ['head', 'subhead', 'media', "pageId"],
        pageId: 2
    },
    {
        identity: 'convention-ambassadors/program-info',
        heading: 'Convention Ambassadors',
        subHeading: 'Program Info',
        button: ['Update'],
        components: ['Title-Details', 'Image-Video-Button'],
        backendFields: ['head', 'subhead', 'media', "pageId"],
        pageId: 2
    },
    {
        identity: 'convention-ambassadors/category',
        heading: 'Convention Ambassadors',
        subHeading: 'Category',
        button: ['Update'],
        components: ['AmbassadorNewTable'],
        backendFields: ['head', 'subhead', 'media', "pageId"],
        pageId: 2
    },
]