import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { FaEye, FaTelegramPlane, FaTrash } from "react-icons/fa";
import CardLayout from '../../components/Layout/CardLayout';
import CustomSelect from '../../components/Shared/components2/CustomSelect';
import SelectMedia from '../../components/Shared/Media/SelectMedia';
import PageHeader from "../../components/Shared/Section/PageHeader";
import useToasts from '../../components/Toast/toastProvider';
import { DataContext } from '../../context/CustomGlobalContext';
import APIService from '../../utils/APIService';
import { emailValidator, generateSlug, getCurrentDate, getImageUrl, targetUrlValidator } from '../../utils/Helper';
import TextEditor from "../../components/Shared/components/TextEditor";

export default function ResourcesCreate() {
  const visibilityOptions = ["Public", "Private"];
  const writerOptions = ["Admin", "User", "Other"];

  const [searchParams] = useSearchParams();
  const { toggleToast } = useToasts();
  const { setPageData } = useContext(DataContext);
  const navigate = useNavigate();
  const { state } = useLocation();

  const urlresource = searchParams.get('resource');
  const resourceId = searchParams.get('id');

  const headerData = {
    mainHeading: 'Resources',
    subHeadng: (resourceId) ? 'Update' : 'Create',
    buttonText: []
  }
  const logoImage = "logoImage";
  const coverImage1 = "coverImage1";
  const coverImage2 = "coverImage2";
  const manageError = {
    companyName: "company name",
    publishedDate: "published date",
    contactName: "contact person name",
    contactEmail: "contact person email",
    contactMobile: "contact person mobile",
    coverImage1: "cover image 1",
    coverImage2: "cover image 2"
  }
  const initialInputs = {
    companyName: "",
    description: "",
    tel: "",
    fax: "",
    mobile: "",
    email: "",
    region: "",
    district: "",
    city: "",
    zipCode: "",
    address: "",
    website: "",
    sqm: "",
    details: "",
    // map: "",
    visibility: "",
    publishedDate: "",
    writer: "",
    categories: (urlresource) ? [urlresource] : [],
    certifications: [],
    // contactPerson: [],
    logo: "",
    // coverImage1: "",
    // coverImage2: "",
  };
  const [formInputs, setFormInputs] = useState(initialInputs);
  const contactPersonInitialInputs = {
    contactName: "",
    contactEmail: "",
    contactMobile: ""
  };
  const [contactPersonInputs, setContactPersonInputs] = useState(contactPersonInitialInputs);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [certificationOptions, setCertificationOptions] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(true);
  const [previewId, setPreviewId] = useState("");

  const [regionCitiesApiResponse, setRegionCitiesApiResponse] = useState();
  const [regionOptions, setRegionOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  useEffect(() => {
    if (resourceId) {
      fetchResourceToUpdate();
    }
  }, [resourceId])

  const fetchResourceToUpdate = async () => {
    setLoadingStatus(false);
    const res = await APIService.get(`/admin/resources/createbyid/${resourceId}`)
    if (res.status === 200) {
      const allData = res?.data?.data?.[0];
      setFormInputs(allData);
      setPageData(prev => ({ ...prev, [logoImage]: getImageUrl(allData?.logo), [coverImage1]: getImageUrl(allData?.coverImage1), [coverImage2]: getImageUrl(allData?.coverImage2) }));
    }
    setLoadingStatus(true);
  }

  useEffect(() => {
    fetchRegionCities();
    fetchCategoryOptions();
    fetchCertificationOptions();
    setPageData(prev => ({ ...prev, [logoImage]: "", [coverImage1]: "", [coverImage2]: "" }))
  }, []);

  const fetchRegionCities = async () => {
    const res = await APIService.get(`/admin/resources/regions`)
    if (res.status === 200) {
      const allData = res?.data?.data;
      setRegionCitiesApiResponse(allData);
      let localRegionOptions = allData.length > 0 && allData.map((reg) => ({ label: reg.label, value: reg.value }));
      setRegionOptions(localRegionOptions);
    }
  }

  const fetchCategoryOptions = async () => {
    const res = await APIService.get(`/admin/resources/category`)
    if (res.status === 200) {
      const allData = res?.data?.data;
      let categories = [];
        allData.length > 0 && res?.data?.data.forEach(element => {
          if(element.publish !== false) categories.push({ label: element.name, value: element.slug })
        });
      if (urlresource) {
        if (!resourceId) {
          let defaultCategory = categories.filter(e => e.value === urlresource);
          setFormInputs(prev => ({ ...prev, categories: defaultCategory }));
        }
      }
      setCategoryOptions(categories);
    }
  }

  const fetchCertificationOptions = async () => {
    const res = await APIService.get(`/admin/resources/certification`)
    if (res.status === 200) {
      const allData = res?.data?.data;
      const options = allData.map(val => ({ value: val._id, label: val.name }));
      setCertificationOptions(options);
    }
  }

  const resourcesCRUD = async (data) => {
    const res = await APIService.post(`/admin/resources/create`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast('success', res.data.message);
      }
      else {
        toggleToast('success', res.data);
      }
      if (urlresource) {
        navigate(`/resources/${urlresource}`, { state });
      }
      else {
        navigate(`/resources/${formInputs?.categories?.[0]?.value}`, { state });
      }
    }
  }

  const addNewResource = async () => {
    let validation = formValidation(formInputs);
    if (validation !== false) {
      let newSlug = generateSlug(formInputs?.companyName);
      let validSlug = targetUrlValidator(newSlug);
      // let slug = (formInputs?.slug) ? formInputs.slug : validSlug.result;
      let slug = validSlug.result;
      let data = {
        ...formInputs,
        slug
      }
      resourcesCRUD(data);
    }
  }

  const formValidation = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === undefined || obj[key].length === 0) {
          let allowArray = ['companyName', 'visibility', 'publishedDate', 'writer', 'categories'];
          if (allowArray.includes(key)) {
            if (manageError.hasOwnProperty(key)) {
              toggleToast('danger', `Please enter ${manageError[key]}`)
              return false;
            }
            toggleToast('danger', `Please enter ${key}`)
            return false;
          }
        }
        if (formInputs.contactEmail && key === 'contactEmail') {
          if (!emailValidator(obj['contactEmail'])) {
            toggleToast('danger', `Please enter valid email.`)
            return false;
          }
        }
        if (formInputs.email && key === 'email') {
          if (!emailValidator(obj['email'])) {
            toggleToast('danger', `Please enter valid email.`)
            return false;
          }
        }
      }
    }
  }

  const handleInputChange = (e, fieldName) => {
    if (fieldName === "categories") {
      setFormInputs(prev => ({ ...prev, categories: e }));
      return
    }
    else if (fieldName === "certifications") {
      setFormInputs({ ...formInputs, certifications: e });
      return
    }
    else if (fieldName === "region") {
      let newCityOption = regionCitiesApiResponse.filter(city => city.value === e.value);
      setCityOptions(newCityOption[0].cities);
      setFormInputs({ ...formInputs, region: e, city: "" });
      return
    }
    else if (fieldName === "city") {
      setFormInputs({ ...formInputs, city: e });
      return
    }
    else if (fieldName === "details") {
      return setFormInputs({ ...formInputs, [fieldName]: e });
    }
    setFormInputs({ ...formInputs, [e.target.name]: e.target.value });
  }

  const handleInputChangeContactPerson = (e) => {
    setContactPersonInputs({ ...contactPersonInputs, [e.target.name]: e.target.value });
  }

  const addContactPerson = () => {
    let validation = formValidation(contactPersonInputs);
    let data = { ...formInputs }
    let arr = (data?.contactPerson) ? data?.contactPerson : [];
    if (validation !== false) {
      arr = [...arr, contactPersonInputs]
      data['contactPerson'] = arr;
      setFormInputs(data);
      setContactPersonInputs(contactPersonInitialInputs);
    }
  }

  const removeContactPreson = (index) => {
    let copyArr = [...formInputs?.contactPerson];
    let newArr = copyArr.filter((e, i) => i !== index);
    setFormInputs(prev => ({ ...prev, contactPerson: newArr }));
  }

  const previewCRUD = async (data) => {
    let redirectTo = 'resources';
    let slug = data.slug;
    if (previewId !== "") {
      data = { ...data, _id: previewId }
    };
    const res = await APIService.post(`/admin/resources/preview`, data)
    if (res.status === 200) {
      let allData = res?.data?.data;
      setPreviewId(allData?._id);
      window.open(`${process.env.REACT_APP_FORNTEND_HOST}${redirectTo}?ispreview=true&previewslug=${slug}`, '_blank', 'windowFeatures');
    }
  }

  const addPreview = async () => {
    let validation = formValidation(formInputs);
    if (validation !== false) {
      let newSlug = generateSlug(formInputs?.companyName);
      let validSlug = targetUrlValidator(newSlug);
      // let slug = (formInputs?.slug) ? formInputs.slug : validSlug.result;
      let slug = validSlug.result;
      let data = {
        ...formInputs,
        slug
      }
      previewCRUD(data);
    }
  }

  const selectFocus = () => {
    if (!formInputs.region) {
      toggleToast("danger", "Please select region first ...");
    }
    else {
      let newCityOption = regionCitiesApiResponse.filter(city => city.value === formInputs?.region?.value);
      if (newCityOption[0].cities.length > 0) {
        setCityOptions(newCityOption[0].cities);
      }
      else {
        toggleToast("warning", "No cities for this region ...");
      }
    }
  }

  const deleteMedia = (mediaId, mediaName) => {
    formInputs[mediaName] = '';
    setPageData(prev => {
      return { ...prev, [mediaId]: '' }
    });
  }

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate=""
      />

      {
        loadingStatus
        &&
        <Row>
          <Col xl={9} md={8} >

            <CardLayout>
              <h1 className="page-header">
                <span>New Resource</span>
              </h1>
              <Container>
                <Form.Group className="mb-3" controlId="forCompanyName">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    name='companyName'
                    value={(formInputs?.companyName) ? formInputs?.companyName : ""}
                    onChange={e => handleInputChange(e, 'companyName')}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="forDescription">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    name='description'
                    value={(formInputs?.description) ? formInputs?.description : ""}
                    onChange={e => handleInputChange(e, 'description')}
                  />
                </Form.Group>
                <hr className='mt-5 mb-5' />

                <Row>
                  <Col>

                    <Form.Group className="mb-3" controlId="forTel">
                      <Form.Label>Tel</Form.Label>
                      <Form.Control
                        type="tel"
                        name='tel'
                        value={(formInputs?.tel) ? formInputs?.tel : ""}
                        onChange={e => handleInputChange(e, 'tel')}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="forMobile">
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control
                        type="tel"
                        name='mobile'
                        value={(formInputs?.mobile) ? formInputs?.mobile : ""}
                        onChange={e => handleInputChange(e, 'mobile')}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="forRegion">
                      <Form.Label>Region</Form.Label>
                      <CustomSelect
                        options={regionOptions || []}
                        isMulti={false}
                        onChange={(data) => handleInputChange(data, "region")}
                        defaultOption={formInputs?.region}
                        selectedOption={formInputs?.region}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="forCity">
                      <Form.Label>City</Form.Label>
                      <CustomSelect
                        options={cityOptions || []}
                        isMulti={false}
                        onChange={(data) => handleInputChange(data, "city")}
                        defaultOption={formInputs?.city}
                        selectedOption={formInputs?.city}
                        onFocus={selectFocus}
                      />
                    </Form.Group>

                  </Col>
                  <Col>

                    <Form.Group className="mb-3" controlId="forFax">
                      <Form.Label>Fax</Form.Label>
                      <Form.Control
                        type="text"
                        name='fax'
                        value={(formInputs?.fax) ? formInputs?.fax : ""}
                        onChange={e => handleInputChange(e, 'fax')}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="forEmail">
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control
                        type="email"
                        name='email'
                        value={(formInputs?.email) ? formInputs?.email : ""}
                        onChange={e => handleInputChange(e, 'email')}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="froDistrict">
                      <Form.Label>District</Form.Label>
                      <Form.Control
                        type="text"
                        name='district'
                        value={(formInputs?.district) ? formInputs?.district : ""}
                        onChange={e => handleInputChange(e, 'district')}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="froZipcode">
                      <Form.Label>Zipcode</Form.Label>
                      <Form.Control
                        type="text"
                        name='zipCode'
                        value={(formInputs?.zipCode) ? formInputs?.zipCode : ""}
                        onChange={e => handleInputChange(e, 'zipCode')}
                      />
                    </Form.Group>

                  </Col>
                </Row>

                <Form.Group className="mb-3" controlId="forAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    name='address'
                    value={(formInputs?.address) ? formInputs?.address : ""}
                    onChange={e => handleInputChange(e, 'address')}
                  />
                </Form.Group>

                <Row>
                  <Col>

                    <Form.Group className="mb-3" controlId="froWebsite">
                      <Form.Label>Website</Form.Label>
                      <Form.Control
                        type="text"
                        name='website'
                        value={(formInputs?.website) ? formInputs?.website : ""}
                        onChange={e => handleInputChange(e, 'website')}
                      />
                    </Form.Group>

                  </Col>
                  <Col>

                    <Form.Group className="mb-3" controlId="forMainEventSpace">
                      <Form.Label>Main Event Space (sqm)</Form.Label>
                      <Form.Control
                        type="text"
                        name='sqm'
                        value={(formInputs?.sqm) ? formInputs?.sqm : ""}
                        onChange={e => handleInputChange(e, 'sqm')}
                      />
                    </Form.Group>

                  </Col>
                </Row>

                <Form.Group className="mb-3" controlId="froDetails">
                  <Form.Label>Details</Form.Label>
                  <TextEditor
                    value={formInputs.details ? formInputs.details : ""}
                    handleInputChange={(value) => setFormInputs({ ...formInputs, details: value })}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="forGoogleMaps">
                  <Form.Label>Google Maps</Form.Label>
                  <Form.Control
                    type="text"
                    name='map'
                    value={(formInputs?.map) ? formInputs?.map : ""}
                    onChange={e => handleInputChange(e, 'map')}
                  />
                </Form.Group>

              </Container>
            </CardLayout>

          </Col>

          <Col xl={3} md={4} >

            <Form.Label column sm={2}>
              Publish
            </Form.Label>

            <Form.Group as={Row} className="mb-3" controlId="forVisibility">
              <Form.Label column sm={4}>
                Visibility
              </Form.Label>
              <Col sm={8}>
                <Form.Select
                  name='visibility'
                  onChange={e => handleInputChange(e, "visibility")}
                >
                  <option selected={(formInputs?.visibility) ? false : true} disabled >Select Visibility...</option>
                  {
                    (visibilityOptions && visibilityOptions.length > 0 && visibilityOptions.map((val, i) => {
                      return (
                        <option
                          key={i}
                          value={val}
                          selected={formInputs?.visibility?.includes(val)}
                        >
                          {val}
                        </option>
                      );
                    }))
                  }
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="forPublished">
              <Form.Label column sm={4}>
                Published
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  // min={getCurrentDate()}
                  type="date"
                  name="publishedDate"
                  placeholder="yyyy/mm/dd"
                  value={(formInputs?.publishedDate) ? formInputs?.publishedDate : ""}
                  onChange={e => handleInputChange(e, 'publishedDate')}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="forWriter">
              <Form.Label column sm={4}>
                Writer
              </Form.Label>
              <Col sm={8}>
                <Form.Select
                  name='writer'
                  onChange={e => handleInputChange(e, "writer")}
                >
                  <option selected={(formInputs?.writer) ? false : true} disabled >Select Writer...</option>
                  {
                    (writerOptions && writerOptions.length > 0 && writerOptions.map((val, i) => {
                      return (
                        <option
                          key={i}
                          value={val}
                          selected={(formInputs?.writer === val) ? true : false}
                        >
                          {val}
                        </option>
                      );
                    }))
                  }
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="forCategories">
              <Form.Label column sm={4}>
                Categories
              </Form.Label>
              <Col sm={8}>
                <CustomSelect
                  options={categoryOptions || []}
                  isMulti={true}
                  onChange={(data) => handleInputChange(data, "categories")}
                  defaultOption={formInputs?.categories}
                  selectedOption={formInputs?.categories}
                />
              </Col>
            </Form.Group>
            <Row>
              <Col sm={6}>
                <Button
                  className='me-5'
                  variant='success'
                  onClick={addPreview}
                >
                  <FaEye className='me-2' />
                  Preview
                </Button>
              </Col>
              <Col sm={6} className="text-end">
                <Button onClick={addNewResource} ><FaTelegramPlane className='me-2' />Publish</Button>
              </Col>
            </Row>
            <Form.Group className="mt-5 mb-3" controlId="forCertification">
              <Form.Label>Select Credential</Form.Label>
              <CustomSelect
                options={certificationOptions || []}
                isMulti={true}
                onChange={(data) => handleInputChange(data, "certifications")}
                defaultOption={formInputs?.certifications}
                selectedOption={formInputs?.certifications}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="forContactPerson">
              <Form.Label>Contact Person</Form.Label>
              <div style={{ border: '1px solid #bdc3c9', minHeight: '85px' }} >
                <Table borderless >
                  <tbody>
                    {
                      formInputs?.contactPerson?.map((val, i) => {
                        return (
                          <tr key={i}>
                            <td className='text-align-left'>{i + 1}</td>
                            <td className='text-align-left'>{val.contactName}</td>
                            <td><FaTrash className='cursor-pointer' onClick={() => removeContactPreson(i)} /></td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="forContactPersonName">
              <Form.Control
                type="text"
                name='contactName'
                placeholder='Name ...'
                value={(contactPersonInputs?.contactName) ? contactPersonInputs?.contactName : ""}
                onChange={e => handleInputChangeContactPerson(e)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="forContactPersonEmail">
              <Form.Control
                type="email"
                name='contactEmail'
                placeholder='E-mail ...'
                value={(contactPersonInputs?.contactEmail) ? contactPersonInputs?.contactEmail : ""}
                onChange={e => handleInputChangeContactPerson(e)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="forContactPersonMobile">
              <Form.Control
                type="tel"
                name='contactMobile'
                placeholder="Mobile ..."
                value={(contactPersonInputs?.contactMobile) ? contactPersonInputs?.contactMobile : ""}
                onChange={e => handleInputChangeContactPerson(e)}
              />
            </Form.Group>
            <Button className='offset-sm-4 mb-2' onClick={addContactPerson} >Add</Button>
            <Form.Group className="mb-3" controlId="forLogo">
              <Form.Label>Logo</Form.Label>
              {formInputs?.logo &&
                <button
                  type="button"
                  className="btn btn-danger btn-circle float-end mb-2"
                  title='Remove Media'
                  onClick={() => deleteMedia(logoImage, 'logo')}
                >
                  X
                </button>
              }
              <SelectMedia
                type={"image"}
                name={"logo"}
                id={logoImage}
                imgSrc={(formInputs?.logo) ? formInputs?.logo : ''}
                updateInputState={setFormInputs}
              />
            </Form.Group>

            <Form.Label>Image Cover</Form.Label>
            <Row>
              <Col>
                {formInputs?.coverImage1 &&
                  <button
                    type="button"
                    className="btn btn-danger btn-circle float-end mb-2"
                    title='Remove Media'
                    onClick={() => deleteMedia(coverImage1, 'coverImage1')}
                  >
                    X
                  </button>
                }
                <Form.Group className="mb-3" controlId="forImageCover1">
                  <SelectMedia
                    type={"image"}
                    name={"coverImage1"}
                    id={coverImage1}
                    imgSrc={(formInputs?.coverImage1) ? formInputs?.coverImage1 : ''}
                    updateInputState={setFormInputs}
                  />
                </Form.Group>
              </Col>
              <Col>
                {formInputs?.coverImage2 &&
                  <button
                    type="button"
                    className="btn btn-danger btn-circle float-end mb-2"
                    title='Remove Media'
                    onClick={() => deleteMedia(coverImage2, 'coverImage2')}
                  >
                    X
                  </button>
                }
                <Form.Group className="mb-3" controlId="forImageCover2">
                  {/* <Form.Label>Image Cover 2</Form.Label> */}
                  <SelectMedia
                    type={"image"}
                    name={"coverImage2"}
                    id={coverImage2}
                    imgSrc={(formInputs?.coverImage2) ? formInputs?.coverImage2 : ''}
                    updateInputState={setFormInputs}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row >
      }

    </>
  )
}