import axios from 'axios';
// import { getCookies } from './Helper';

class Service {
    constructor() {
        // const token = getCookies('UserToken')
        this.service = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                // 'x-auth-token': token
                "Access-Control-Allow-Origin": "*",
            },
        });
    }

    async get(path) {
        try {
            const response = await this.service.get(path);
            return { status: response.status, data: response.data };
        } catch (error) {
            console.log('ERROR', error);
            return error;
        }
    }

    async patch(path, payload) {
        try {
            const response = await this.service.request({
                method: 'PATCH',
                url: path,
                responseType: 'json',
                data: payload,
            });
            return { status: response.status, data: response.data };
        } catch (error) {
            return error;
        }
    }

    async post(path, payload, headers={ 'content-type': 'application/json' }) {
        try {
           
            const response = await this.service.request({
                method: 'POST',
                url: path,
                responseType: 'json', 
                data: payload,
                headers: headers
            });
            return response;
        } catch (error) {
            return { status: error?.response?.status, data: error?.response?.data };
        }
    }

    async put(path, payload, headers = { 'content-type': 'application/json' }) {
        try {
            const response = await this.service.request({
                method: 'PUT',
                url: path,
                responseType: 'json',
                data: payload,
                headers: headers,
            });
            return { status: response.status, data: response.data };
        } catch (error) {
            console.log('error ', error)
            // return error;
            return { status: error?.response?.status, data: error?.response?.data };
        }
    }

    async delete(path, payload) {
        try {
            const response = await this.service.request({
                method: 'DELETE',
                url: path,
                responseType: 'json',
                data: payload,
            });
            return response;
        } catch (error) {
            return error;
        }
    }
}

export default new Service();
