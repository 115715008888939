import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa'
import CardLayout from '../../components/Layout/CardLayout'
import ModalComp from "../../components/Shared/components/ModalComp"
import SelectMedia from '../../components/Shared/Media/SelectMedia'
import PageHeader from '../../components/Shared/Section/PageHeader'
import useToasts from '../../components/Toast/toastProvider'
import { DataContext } from '../../context/CustomGlobalContext'
import APIService from '../../utils/APIService'
import { getImageUrl } from '../../utils/Helper'
import TextEditor from "../../components/Shared/components/TextEditor";
const Sustainability = () => {

  const [localdata2, setLocalData2] = useState({
    head: '',
    subhead: '',
    image: ''
  })

  const [pageDataApiResponse, setPageDataApiResponse] = useState();
  const imageId = 'sustainability_tableImage';
  const imageId1 = "sustainability_sectionImage1";
  const imageId2 = "sustainability_sectionImage2";
  const pagename = "whyThailandSustainability";

  // -----------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------

  const formControls = ['New Sustainability', 'name_noLabel', 'targeturl_noLabel', 'texteditor_noLabel', 'image'];

  const buildCols = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return rowIndex + 1;
        },
      },
      {
        dataField: "name",
        text: "Sustainability",
        style: () => ({ maxWidth: '150px' })
      },
      {
        dataField: "targeturl",
        text: "Target URL",
        style: () => ({ maxWidth: '150px' })
      },
      {
        dataField: "image",
        text: "Image",
        formatter: (cell, row) => {
          return (
            <img src={getImageUrl(row?.image)} alt='img' width="100" height="100" />
          )
        }
      },
      {
        dataField: "status",
        text: "Status",
        formatter: (cell, row) => (row?.publish) ? "Public" : "Private"
      },
      {
        dataField: "created",
        text: "Date",
        formatter: (cell, row) => {
          let date = new Date(row.created);
          let final = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          return final;
        }
      },
      {
        dataField: "edit",
        text: 'Edit',
        formatter: (cell, row) => {
          return (
            <>
              <FaRegEdit className='me-3 cursor-pointer' onClick={() => funUpdateData(row)} />
              <FaTrashAlt className='cursor-pointer' onClick={() => funDeleteData(row)} />
            </>
          )
        }
      },
      {
        dataField: "publish",
        text: 'Publish',
        formatter: (cell, row) => {
          return (
            <input className='cursor-pointer' type='checkbox' name='checkbox' onChange={e => UpdateActiveStatus(row)} defaultChecked={row.publish} />
          )
        }
      },
    ]
    return columnFields;
  }

  const { setPageData } = useContext(DataContext)
  const { toggleToast } = useToasts()

  const headerData = {
    mainHeading: 'Why Thailand',
    subHeadng: 'Sustainability',
    buttonText: ['Update', 'Add New']
  };

  const [search, setSearch] = useState("");
  const [tempData, setTempData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const manageError = {
    targeturl: "target url"
  }
  let initialInputState = {
    name: "",
    targeturl: "",
    details: "",
    image: "",
    status: "Public"
  }
  const [modalInputs, setModalInputs] = useState(initialInputState);

  useEffect(() => {
    const filtered = !search
      ? tempData
      : tempData.filter((person) =>
        person.name.toLowerCase().includes(search.toLowerCase()) ||
        person.targeturl.toLowerCase().includes(search.toLowerCase()) ||
        person.status.toLowerCase().includes(search.toLowerCase()) ||
        person.created.toLowerCase().includes(search.toLowerCase())
      );
    setTableData(filtered);
  }, [search])

  useEffect(() => {
    fetchTableData();
    fetchPageData();
  }, [])

  const fetchTableData = async () => {
    const res = await APIService.get(`/admin/why-thailand/sustainability`)
    if (res.status === 200) {
      setTableData(res.data.data);
      setTempData(res.data.data);
    }
  }

  const sustainabilityCRUD = async (data) => {
    const res = await APIService.post(`/admin/why-thailand/sustainability`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast('success', res.data.message);
      }
      else {
        toggleToast('success', res.data);
      }
      fetchTableData();
    }
  }

  const addNewSustainability = async () => {
    let validation = formValidation(modalInputs);
    if (validation !== false) {
      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        ...modalInputs
      }
      sustainabilityCRUD(data);
      handleModal();
    }
  }

  let funUpdateData = (rowData) => {
    setModalInputs(rowData);
    setShowModal(!showModal);
    setPageData(prev => ({ ...prev, [imageId]: getImageUrl(rowData?.image) }))
  }

  const funDeleteData = (row) => {
    const confirmBox = window.confirm(
      "Do you really want to delete?"
    )
    if (confirmBox === true) {
      let data = {
        _id: row._id,
        isDeleted: true
      }
      sustainabilityCRUD(data);
    }
  }

  const UpdateActiveStatus = (rowData) => {
    let data = {
      _id: rowData._id,
      publish: !rowData.publish,
    }
    sustainabilityCRUD(data);
  }

  const handleInputChange = (e) => {
    let key = e?.target?.name;
    let value = e?.target?.value;
    if (key === "search") {
      return setSearch(value);
    }
    else if (!key) {
      return setModalInputs({ ...modalInputs, ['details']: e });
    }
    setModalInputs({ ...modalInputs, [key]: value });
  }

  const formValidation = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === undefined) {
          if (manageError.hasOwnProperty(key)) {
            toggleToast('danger', `Please enter ${manageError[key]}`)
            return false;
          }
          toggleToast('danger', `Please enter ${key}`)
          return false;
        }
      }
    }
  }

  const handleModal = () => {
    setShowModal(!showModal);
    setModalInputs(initialInputState);
    setPageData(prev => ({ ...prev, [imageId]: '' }))
  }
  // ----------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------

  const defaultSection = {
    details: "",
    imageUrl: ""
  };

  const handleInputChange1 = (e, field, index) => {
    let pageData = { ...pageDataApiResponse };
    if (field === "checkbox") {
      pageData.sections[index] = { ...pageData.sections[index], [e.target.name]: e.target.checked };
    } else {
      pageData.sections[index] = { ...pageData.sections[index], [e.target.name]: e.target.value };
    }
    setPageDataApiResponse(pageData);
  };

  const fetchPageData = async () => {
    const res = await APIService.get(`/admin/page/item?pagename=${pagename}`);
    if (res.status === 200) {
      let allData = res.data?.data?.[0];
      let data = allData.sections?.[0];

      setLocalData2(allData?.overview);
      allData[`${imageId1}`] = getImageUrl(allData?.overview?.image);

      setPageDataApiResponse(allData);
      allData.sections.map((val, i) => {
        allData[`${imageId2}-${i}`] = getImageUrl(val?.imageUrl);
      });
      setPageData(allData);
    }
  };

  const submitOverviewData = async () => {
    let payload = {};
    payload.overview = localdata2;
    // call the put api for the data update
    const res = await APIService.put(`/admin/page/${pagename}`, { payload });
    if (res.status === 200) {
      toggleToast('success', 'Page updated successfully ')
    } else {
      toggleToast('failure', 'Page not updated successfully ')
    }
  }

  const submitData = async () => {
    let payload = { ...pageDataApiResponse };
    const res = await APIService.put(`/admin/page/${pagename}`, { payload });
    if (res.status === 200) {
      toggleToast("success", "Page updated successfully ");
    } else {
      toggleToast("failure", "Page not updated successfully ");
    }
  };

  const addNewSection = () => {
    let oldpageData = { ...pageDataApiResponse };
    let addNewSection = defaultSection
    let index = pageDataApiResponse?.sections.length;
    oldpageData[`${imageId1}-${index}`] = getImageUrl("");
    oldpageData.sections.push(addNewSection);
    setPageDataApiResponse(oldpageData);
    // setPageData({...pageData,sections:oldpageData})
  };

  const updatePageState = (updateSection, index) => {
    let pageData = { ...pageDataApiResponse };
    let updatedSection = updateSection(pageData.sections[index]);
    pageData.sections[index] = updatedSection;
    setPageDataApiResponse(pageData);
  };

  const deleteSection = (index) => {
    const confirmBox = window.confirm(
      "Do you really want to delete this section?"
    )
    if (confirmBox === true) {
      let oldpageData = { ...pageDataApiResponse };
      let sectionFilter = oldpageData.sections.filter((val, i) => (i !== index));
      oldpageData.sections = sectionFilter;
      setPageDataApiResponse(oldpageData);
    }
  }
  const handleInputChangeForEditor = (value, index) => {
    let pageData = { ...pageDataApiResponse };
    pageData.sections[index] = { ...pageData.sections[index], ['details']: value };
    setPageDataApiResponse(pageData);
  }

  return (
    <>
      {/* Page Header */}
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate={submitOverviewData}
      />
      <>
        <CardLayout>
          <Row>
            <Col md={8}>
              <Form>
                <Form.Group
                  className='mb-3'
                  controlId='exampleForm.ControlInput1'
                >
                  <Form.Label>Head</Form.Label>
                  <Form.Control
                    type='text'
                    value={localdata2?.head ? localdata2.head : ''}
                    name='head'
                    onChange={e => setLocalData2({ ...localdata2, ['head']: e.target.value })}
                  />
                </Form.Group>
                <Form.Group
                  className='mb-3'
                  controlId='exampleForm.ControlTextarea1'
                >
                  <Form.Label>Sub-head</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={2}
                    style={{ resize: 'none' }}
                    name='subhead'
                    value={localdata2?.subhead ? localdata2.subhead : ''}
                    onChange={e => setLocalData2({ ...localdata2, ['subhead']: e.target.value })}
                  />
                </Form.Group>
              </Form>
            </Col>
            <Col
              md={3}
              className='d-flex justify-content-center align-self-center offset-md-1'
            >
              <SelectMedia
                type={'image'}
                name="image"
                id={imageId1}
                imgSrc={(localdata2?.image) ? localdata2?.image : ''}
                updateInputState={setLocalData2}
              />
            </Col>
          </Row>
        </CardLayout>

        <hr />

        {/* Page Header */}
        <PageHeader
          buttonText={headerData.buttonText[0]}
          handleUpdate={submitData}
        />

        {pageDataApiResponse?.sections &&
          pageDataApiResponse?.sections.length > 0 &&
          pageDataApiResponse?.sections.map((localdata, index) => (
            <>

              <CardLayout>
                <Row>
                  <Col md={8}>
                    <Form>
                      <Form.Group
                        className='mb-3'
                        controlId='exampleForm.ControlInput1'
                      >
                        <Form.Label>Details</Form.Label>
                        <TextEditor
                          value={localdata.details ? localdata.details : ""}
                          handleInputChange={(value) => handleInputChangeForEditor(value, index)}
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col
                    md={3}
                    className='d-flex justify-content-center align-self-center offset-md-1'
                  >
                    <SelectMedia
                      type={'image'}
                      name="imageUrl"
                      id={`${imageId2}-${index}`}
                      imgSrc={(localdata?.imageUrl) ? localdata?.imageUrl : ''}
                      updateInputState={(data) => updatePageState(data, index)}
                    />
                  </Col>
                </Row>
                {
                  index && index > 0 && <Button onClick={e => deleteSection(index)} className="mt-3" variant="danger" >Delete Section</Button> || ''
                }
              </CardLayout>
            </>
          ))}

        <div className="add_section" onClick={addNewSection}>
          <span>add section</span> <span className="add_icon">+</span>
        </div>

        {/* <PageHeader
          buttonText={headerData.buttonText[1]}
          handleUpdate={handleModal}
        />
        <CardLayout>
          <Form.Group >
            <Form.Control
              name="search"
              placeholder='Search...'
              value={search}
              onChange={e => handleInputChange(e)}
              className='mb-2 w-25 ms-auto'
            ></Form.Control>
          </Form.Group>

          <Row>
            <BootstrapTable className="table-rc" keyField='id' data={tableData} columns={buildCols()} pagination={paginationFactory()} />
          </Row>
        </CardLayout> */}

        {
          showModal
            ?
            <ModalComp
              imageId={imageId}
              showModal={showModal}
              CUD_API={addNewSustainability}
              handleModal={handleModal}
              formControls={formControls}
              inputState={modalInputs}
              handleInputChange={handleInputChange}
              updateInputState={setModalInputs}
            />
            :
            null
        }

      </>
    </>
  )
}

export default Sustainability