import React, { useEffect, useState } from 'react'
import { Button, Form, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from 'react-csv';
import { FaFileExcel, FaRegEdit, FaTrashAlt } from 'react-icons/fa';
import CardLayout from '../../components/Layout/CardLayout';
import ModalComp from '../../components/Shared/components/ModalComp';
import PageHeader from '../../components/Shared/Section/PageHeader';
import useToasts from '../../components/Toast/toastProvider';
import APIService from '../../utils/APIService';
import { emailValidator, setRowIndex } from '../../utils/Helper';

export default function ContactSubscribeList() {

  const { toggleToast } = useToasts();

  const [tablePage, setTablePage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const paginationOption = setRowIndex(setTablePage, setSizePerPage);

  const buildCols = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return ((tablePage - 1) * sizePerPage + rowIndex + 1);
        },
      },
      { dataField: "name", text: "Name" },
      { dataField: "email", text: "E-mail" },
      { dataField: "phone", text: "Phone" },
      {
        dataField: "cname",
        text: "Company Name",
        formatter: (cell, row) => row.cname ? row.cname : '-'
      },
      {
        dataField: "position",
        text: "Position",
        formatter: (cell, row) => row.position ? row.position : '-'
      },
      { dataField: "created", text: "Date" },
      {
        dataField: "edit",
        text: 'Edit',
        formatter: (cell, row) => {
          return (
            <>
              <FaRegEdit className='me-3 cursor-pointer' onClick={() => funUpdateData(row)} />
              <FaTrashAlt className='cursor-pointer' onClick={() => funDeleteData(row)} />
            </>
          )
        }
      }
    ]
    return columnFields;
  }

  const headerData = {
    mainHeading: 'Contact Us',
    subHeadng: 'Subscribe List',
    buttonText: []
  };

  const [search, setSearch] = useState();
  const [tempData, setTempData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  let initialInputState = {
    name: "",
    position: "",
    email: "",
    details: "",
    category: "",
    country: "",
    image: ""
  }
  const [modalInputs, setModalInputs] = useState(initialInputState);

  const formControls = ['name', 'email', 'phone', 'cname', 'position'];

  const csvHeaders = [
    { label: "Name", key: "name" },
    { label: "E-mail", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "Company Name", key: "cname" },
    { label: "Position", key: "position" },
    { label: "Date", key: "created" }
  ];

  useEffect(() => {
    const filtered = !search
      ? tempData
      : tempData.filter((person) =>
        person.name.toLowerCase().includes(search.toLowerCase()) ||
        person.email.toLowerCase().includes(search.toLowerCase())
      );
    setTableData(filtered);
  }, [search]);

  useEffect(() => {
    fetchTableData();
  }, []);

  const fetchTableData = async () => {
    const res = await APIService.get(`/admin/contact-us/subscribe`)
    if (res.status === 200) {
      let apiData = res?.data?.data;
      const data = apiData.map(row => {
        let date = new Date(row.created);
        let final = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        return (
          { ...row, created: final }
        )
      })
      setTableData(data);
      setTempData(data);
    }
  }

  const handleInputChange = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    if (key === "search") {
      return setSearch(value);
    }
    setModalInputs({ ...modalInputs, [key]: value });
  }

  const updateSubscriber = async () => {
    let validation = formValidation(modalInputs);
    if (validation !== false) {
      delete modalInputs.created;
      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        ...modalInputs
      }
      subscriberCRUD(data);
      handleModal();
    }
  }

  const subscriberCRUD = async (data) => {
    const res = await APIService.post(`/admin/contact-us/subscribe`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast('success', res.data.message);
      }
      else {
        toggleToast('success', res.data);
      }
      fetchTableData();
    }
  }

  const funUpdateData = (rowData) => {
    setModalInputs(rowData);
    setShowModal(!showModal);
  }

  const funDeleteData = (row) => {
    const confirmBox = window.confirm(
      "Do you really want to delete?"
    )
    if (confirmBox === true) {
      let data = {
        _id: row._id,
        isDeleted: true
      }
      subscriberCRUD(data);
    }
  }

  const formValidation = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === undefined) {
          toggleToast('danger', `Please enter ${key}`)
          return false;
        }
      }
      if (key === 'email') {
        if (!emailValidator(obj['email'])) {
          toggleToast('danger', `Please enter valid email.`)
          return false;
        }
      }
    }
  }

  const handleModal = () => {
    setShowModal(!showModal);
    setModalInputs(initialInputState);
  }

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate=""
      />

      <CardLayout>

        <Button
          variant="outline-secondary"
          className="ms-2 float-end btn-outline-secondary"
          size='sm'
        >
          <CSVLink style={{ textDecoration: 'none' }} data={tableData} headers={csvHeaders}>
            <FaFileExcel /> Export
          </CSVLink>
        </Button>

        <Form.Group>
          <Form.Control
            name="search"
            placeholder="Search..."
            value={search}
            onChange={e => handleInputChange(e)}
            className='mb-2 w-25 ms-auto'
          ></Form.Control>
        </Form.Group>

        <Row>
          <BootstrapTable className="table-rc" keyField='id' data={tableData} columns={buildCols()} pagination={paginationFactory(paginationOption)} />
        </Row>

      </CardLayout>

      {
        showModal
          ?
          <ModalComp
            imageId=''
            showModal={showModal}
            CUD_API={updateSubscriber}
            handleModal={handleModal}
            formControls={formControls}
            inputState={modalInputs}
            handleInputChange={handleInputChange}
            updateInputState={setModalInputs}
          />
          :
          null
      }

    </>
  )
}
