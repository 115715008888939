import React, { useContext, useEffect, useState } from "react";
import PageHeader from "../../components/Shared/Section/PageHeader";
import useToasts from "../../components/Toast/toastProvider";
import APIService from "../../utils/APIService";

import { Button, Form, Row } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa';
import CardLayout from "../../components/Layout/CardLayout";
import ModalComp from "../../components/Shared/components/ModalComp";
import { getImageUrl, setRowIndex, targetUrlValidator } from "../../utils/Helper";
import { DataContext } from "../../context/CustomGlobalContext";

const ContentsCategory = () => {

  const { setPageData } = useContext(DataContext);
  const { toggleToast } = useToasts();
  const headerData = {
    mainHeading: 'Contents',
    subHeadng: 'Category',
    buttonText: ['New Category']
  };

  const imageId = 'contents_category_image';
  const formControls = ['name', 'slug'];

  const [search, setSearch] = useState("");
  const [tempData, setTempData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  let initialInputState = {
    name: "",
    slug: ""
  }
  const [modalInputs, setModalInputs] = useState(initialInputState);

  const [tablePage, setTablePage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const paginationOption = setRowIndex(setTablePage, setSizePerPage);

  const buildCols = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return ((tablePage - 1) * sizePerPage + rowIndex + 1);
        },
      },
      { dataField: "name", text: "Category Name" },
      {
        dataField: "edit",
        text: 'Edit',
        formatter: (cell, row) => {
          return (
            <>
              <FaRegEdit className='me-3 cursor-pointer' onClick={() => funUpdateData(row)} />
              <FaTrashAlt onClick={() => funDeleteData(row)} />
            </>
          )
        }
      },
      {
        dataField: "publish",
        text: 'Publish',
        formatter: (cell, row) => {
          return (
            <input className='cursor-pointer' type='checkbox' name='checkbox' onChange={e => UpdateActiveStatus(row)} defaultChecked={row.publish} />
          )
        }
      },
    ]
    return columnFields;
  }

  useEffect(() => {
    const filtered = !search
      ? tempData
      : tempData.filter((person) =>
        person.name.toLowerCase().includes(search.toLowerCase())
      );
    setTableData(filtered);
  }, [search])

  useEffect(() => {
    fetchTableData();
  }, [])

  const fetchTableData = async () => {
    const res = await APIService.get(`/admin/contents/category`)
    if (res.status === 200) {
      setTableData(res.data.data);
      setTempData(res.data.data);
    }
  }

  const addNewCategory = async () => {
    let validation = formValidation(modalInputs);
    if (validation !== false) {
      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        ...modalInputs
      }
      categoryCRUD(data);
      // handleModal();
    }
  }

  let funUpdateData = (rowData) => {
    setModalInputs(rowData);
    setShowModal(!showModal);
    setPageData(prev => ({ ...prev, [imageId]: getImageUrl(rowData?.image) }))
  }

  const funDeleteData = (row) => {
    const confirmBox = window.confirm(
      "Do you really want to delete?"
    )
    if (confirmBox === true) {
      let data = {
        _id: row._id,
        isDeleted: true
      }
      categoryCRUD(data);
    }
  }

  const UpdateActiveStatus = (rowData) => {
    let data = {
      _id: rowData._id,
      publish: !rowData.publish,
    }
    categoryCRUD(data);
  }

  const categoryCRUD = async (data) => {
    const res = await APIService.post(`/admin/contents/category`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast('success', res.data.message);
      }
      else {
        toggleToast('success', res.data);
      }
      fetchTableData();
      setModalInputs(initialInputState);
      setShowModal(false);
    }
    else {
      toggleToast('danger', res.data.message);
    }
  }

  const handleModal = () => {
    setShowModal(!showModal);
    setModalInputs(initialInputState);
    setPageData(prev => ({ ...prev, [imageId]: '' }));
  }

  const handleInputChange = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    if (key === "search") {
      return setSearch(value);
    }
    if (key === "slug") {
      let validValue = targetUrlValidator(e.target.value);
      if (validValue?.message !== "") {
        toggleToast('danger', validValue?.message);
      }
      return setModalInputs({ ...modalInputs, [key]: validValue.result });
    }
    setModalInputs({ ...modalInputs, [key]: value });
  }

  const formValidation = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === undefined) {
          toggleToast('danger', `Please enter ${key}`)
          return false;
        }
      }
    }
  }

  return (
    <>
      {/* Page Header */}
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate={() => setShowModal(!showModal)}
      />

      <CardLayout>
        <Form.Group>
          <Form.Control
            name="search"
            placeholder="Search..."
            value={search}
            onChange={e => handleInputChange(e)}
            className='mb-2 w-25 ms-auto'
          ></Form.Control>
        </Form.Group>
        <Row>
          <BootstrapTable className="table-rc" keyField='id' data={tableData} columns={buildCols()} pagination={paginationFactory(paginationOption)} />
        </Row>
      </CardLayout>

      {
        showModal
          ?
          <ModalComp
            imageId={imageId}
            showModal={showModal}
            CUD_API={addNewCategory}
            handleModal={handleModal}
            formControls={formControls}
            inputState={modalInputs}
            handleInputChange={handleInputChange}
            updateInputState={setModalInputs}
          />
          :
          null
      }

    </>
  );
};

export default ContentsCategory;