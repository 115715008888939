import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { FaEye, FaTelegramPlane } from "react-icons/fa";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CardLayout from "../../components/Layout/CardLayout";
import SelectMedia from "../../components/Shared/Media/SelectMedia";
import PageHeader from "../../components/Shared/Section/PageHeader";
import useToasts from "../../components/Toast/toastProvider";
import APIService from "../../utils/APIService";
import ReactDateRangePicker from "../../components/Shared/components2/ReactDateRangePicker";
import CustomSelect from "../../components/Shared/components2/CustomSelect";
import moment from "moment";
import { generateSlug, getCurrentDate, getImageUrl, targetUrlValidator } from "../../utils/Helper";
import { DataContext } from "../../context/CustomGlobalContext";
import TextEditor from "../../components/Shared/components/TextEditor"
export default function ContentsCreate() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setPageData } = useContext(DataContext);
  const queryParam = searchParams.get("category");
  const editQueryParam = searchParams.get("id");
  const { state } = useLocation();

  const { toggleToast } = useToasts();

  const headerData = {
    mainHeading: "Contents",
    subHeadng: (editQueryParam) ? 'Update' : 'Create',
    buttonText: [""],
  };

  let initialInputState = {
    contentsTitle: "",
    canAddSubtitle: "",
    contentssubtitle: "",
    contentsDescription: "",

    seoTitle: "",
    seoKeyKeyWords: "",
    seoDescription: "",

    body: "",
    visibility: "public",
    publishedDate: "",
    writer: "admin",
    category: (queryParam) ? [queryParam] : [],
    isInThespotlight: false,
    inThespotlightCategory: "",

    dateRangeStart: new Date(),
    dateRangeEnd: new Date(),
    city: "",
    venue: "",
    targeturl: "",
    isRedirectUrl: true,
    featureImage: "",
  };

  const imageId = "home-image-media";
  const [formInputs, setFormInputs] = useState(initialInputState);
  const [defaultCategory, setdefaultCategory] = useState([]);
  const [checkBox, setCheckBox] = useState(true);
  const [API_URL, setAPI_URL] = useState("");
  const [loadingState, setLoadingState] = useState(true);
  const [dataId, setDataId] = useState("");
  const [previewId, setPreviewId] = useState();

  useEffect(() => {
    fetchCategoryData();
    setPageData(prev => ({ ...prev, [imageId]: '' }))
  }, []);

  useEffect(() => {
    if (!editQueryParam) return;
    fetchEditData();
  }, [editQueryParam]);

  const fetchCategoryData = async () => {
    let res = await APIService.get(`/admin/contents/category`);
    if (res.status === 200) {
      let categories = res?.data?.data.length > 0 && res?.data?.data.map((cat) => ({ label: cat.name, value: cat.slug }));
      if (queryParam) {
        if (!editQueryParam) {
          let defaultCategory = categories.filter(e => e.value === queryParam);
          setFormInputs(prev => ({ ...prev, category: defaultCategory }));
        }
      }
      setdefaultCategory(categories);
    }
  };

  const fetchEditData = async () => {
    setLoadingState(false);
    let res = await APIService.get(`/admin/contents/createbyid/${editQueryParam}`);
    if (res.status === 200) {
      let postData = res?.data?.data[0];
      setDataId(postData?._id);
      setPageData(prev => ({ ...prev, [imageId]: getImageUrl(postData?.featureImage) }));
      setFormInputs(postData);
    }
    setLoadingState(true);
  };

  useEffect(() => {
    switch (queryParam) {
      // case "convention-lineup":
      //   setAPI_URL("/admin/calendar/convention-lineup");
      //   return;
      // case "news":
      //   setAPI_URL("/admin/news");
      //   return;
      default:
        setAPI_URL("/admin/contents/create");
    }
  }, [queryParam]);

  const handleInputChange = (e, name, type = "") => {
    let key = e?.target?.name;
    let value = e?.target?.value;
    if (name === "category") {
      let isInThespotlight = isCategoryExist(e, "in-the-spotlight");
      if (!isInThespotlight && formInputs.isInThespotlight)
        return setFormInputs((prev) => ({ ...prev, isInThespotlight: false, ['category']: e }));
      else if (isInThespotlight && !formInputs.isInThespotlight)
        return setFormInputs((prev) => ({ ...prev, isInThespotlight: true, ['category']: e }));
    }
    if (type === "checked") {
      return setFormInputs({ ...formInputs, [name]: e?.target?.checked });
    }
    if (!key) {
      return setFormInputs({ ...formInputs, [name]: e });
    }
    setFormInputs({ ...formInputs, [key]: value });
  };

  const handleInputChangeForEditor = (value, name) => {
    setFormInputs({ ...formInputs, [name]: value })
  }

  const isCategoryExist = (category = [], catSlug = "") => {
    if (category.length === 0) return false;
    let isExist = category.find((data) => createSlugFromWord(data?.value) === catSlug);
    if (isExist === undefined) return false;
    else return true;
  };

  const createSlugFromWord = (name = "") => {
    return name.toLowerCase().replaceAll(" ", "-");
  };

  const updateFeatureImage = (updateImage, index) => {
    let updatedImage = updateImage(formInputs);
    handleInputChange(updatedImage.image, "featureImage");
  };

  const CRUD_API = async (data) => {
    if (dataId !== "") {
      data = { ...data, _id: dataId }
    };
    const res = await APIService.post(API_URL, data);
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast("success", res.data.message);
      } else {
        toggleToast("success", res.data);
      }
      let returnUrl = getReturnUrl(queryParam);
      navigate(returnUrl, { state });
    }
  };

  const getReturnUrl = (category) => {
    if (category) {
      switch (category) {
        case 'convention-lineup':
          return `/calendar/convention-lineup`;

        case 'in-the-spotlight':
          return `/home/in-the-spotlight`;

        default:
          return `/contents?category=${queryParam}`;
      }
    } else {
      return `/contents`;
    }
  }

  const formValidation = (obj) => {
    let err = "";
    if (formInputs?.isInThespotlight && formInputs?.inThespotlightCategory === "") err = "Spotlight category is required";
    else if (formInputs?.contentsTitle === "") err = "Content Title cannot be empty";
    else if (!formInputs?.publishedDate) err = "Please add publish date ...";
    else if (!formInputs?.category.length) err = "Please select category ...";
    // else if (!formInputs?.featureImage) err = "Please add feature image ...";
    if (err) {
      toggleToast("danger", err);
      return false;
    }
  };

  const addNew = async () => {
    let validation = formValidation();
    if (validation !== false) {
      let newSlug = generateSlug(formInputs?.contentsTitle);
      let validSlug = targetUrlValidator(newSlug);
      // let slug = (formInputs?.slug) ? formInputs.slug : validSlug.result;
      let slug = validSlug.result;
      let data = {
        isActive: true,
        published: false,
        isDeleted: false,
        ...formInputs,
        slug
      };
      CRUD_API(data);
    }
  };

  const categoryArray = [
    {
      "label": "bangkok",
      "value": "conventions-held-in-bangkok",
    },
    {
      "label": "chiang-mai",
      "value": "conventions-held-in-chiang-mai",
    },
    {
      "label": "khon-kaen",
      "value": "conventions-held-in-khon-kaen",
    },
    {
      "label": "pattaya",
      "value": "conventions-held-in-pattaya",
    },
    {
      "label": "phuket",
      "value": "conventions-held-in-phuket",
    }
  ]

  const CRUD_Preview = async (data) => {
    let redirectTo = data.category[0].value;
    let slug = data.slug;
    if (previewId !== "") {
      data = { ...data, _id: previewId }
    };
    const res = await APIService.post(`/admin/contents/preview`, data);
    if (res.status === 200) {
      let allData = res?.data?.data;
      setPreviewId(allData?._id);
      if (allData?.category.length > 1) {
        allData?.category.map((val, i) => {
          let newRedirection = val.value;
          let checkExist = categoryArray.filter(e => e.value === newRedirection);
          if (val.value === 'in-the-spotlight') {
            newRedirection = "";
          }
          else if (val.value === 'case-studies' || val.value === 'previous-conventions') {
            newRedirection = "success-stories";
          }
          else if (val.value === 'convention-lineup') {
            newRedirection = "calendar";
          }
          else if (checkExist.length) {
            newRedirection = `convention/${checkExist?.[0].label}`;
          }
          window.open(
            `${process.env.REACT_APP_FORNTEND_HOST}${newRedirection}?content=${val.value}&ispreview=true&previewslug=${slug}`,
            `${val.value}${i}`,
            'noreferrer'
          );
        })
      }
      else {
        let newRedirection = redirectTo;
        let checkExist = categoryArray.filter(e => e.value === newRedirection);
        if (redirectTo === 'in-the-spotlight') {
          newRedirection = "";
        }
        else if (checkExist.length) {
          newRedirection = `convention/${checkExist?.[0].label}`;
        }
        else if (redirectTo === 'convention-lineup') {
          newRedirection = "calendar";
        }
        else if (redirectTo === 'case-studies' || redirectTo === 'previous-conventions') {
          newRedirection = "success-stories";
        }
        window.open(`${process.env.REACT_APP_FORNTEND_HOST}${newRedirection}?content=${redirectTo}&ispreview=true&previewslug=${slug}`, '_blank', 'windowFeatures');
      }
    }
  };

  const preview = async () => {
    let validation = formValidation();
    if (validation !== false) {
      let newSlug = generateSlug(formInputs?.contentsTitle);
      let validSlug = targetUrlValidator(newSlug);
      // let slug = (formInputs?.slug) ? formInputs.slug : validSlug.result;
      let slug = validSlug.result;
      let data = {
        isActive: true,
        published: false,
        isDeleted: false,
        ...formInputs,
        slug
      };
      CRUD_Preview(data);
    }
  }

  const deleteMedia = (mediaId, mediaName, index) => {
    formInputs[mediaName] = '';
    setPageData(prev => {
      return { ...prev, [mediaId]: '' }
    });
  }

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate=""
      />
      {
        loadingState
        &&
        <Row>
          <Col md={8}>
            <Tabs defaultActiveKey="contents">
              <Tab eventKey="contents" title="Contents">
                <CardLayout>
                  <Form>
                    <Form.Group className="mb-3" controlId="contentsTitle">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        name="contentsTitle"
                        value={formInputs?.contentsTitle}
                        type="text"
                        onChange={(e) => handleInputChange(e)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="contentssubtitle">
                      <div className="d-flex align-items-center d-flex mb-2">
                        <Form.Label className="mb-0">Sub Title</Form.Label>
                        <div>
                          <label className="mb-0 cursor-pointer">
                            <Form.Check
                              name="checkbox"
                              type="checkbox"
                              checked={formInputs?.canAddSubtitle}
                              onChange={(e) => handleInputChange(e, "canAddSubtitle", "checked")}
                              className={"ps-3 d-inline-block cursor-pointer"}
                            />
                          </label>
                        </div>
                      </div>
                      <Form.Control
                        name="contentssubtitle"
                        value={formInputs?.contentssubtitle}
                        disabled={!formInputs?.canAddSubtitle}
                        type="text"
                        onChange={(e) => handleInputChange(e)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="contentsDescription">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        name="contentsDescription"
                        value={formInputs?.contentsDescription}
                        as="textarea"
                        rows={2}
                        style={{ resize: "none" }}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </Form.Group>
                  </Form>
                </CardLayout>
              </Tab>
              <Tab eventKey="seo" title="SEO">
                <CardLayout>
                  <Form>
                    <Form.Group className="mb-3" controlId="seoTitle">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        name="seoTitle"
                        value={formInputs?.seoTitle}
                        type="text"
                        onChange={(e) => handleInputChange(e)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="seoKeyKeyWords">
                      <Form.Label>Keywords</Form.Label>
                      <Form.Control
                        name="seoKeyKeyWords"
                        value={formInputs?.seoKeyKeyWords}
                        type="text"
                        onChange={(e) => handleInputChange(e)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="seoDescription">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        name="seoDescription"
                        value={formInputs?.seoDescription}
                        as="textarea"
                        rows={2}
                        style={{ resize: "none" }}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </Form.Group>
                  </Form>
                </CardLayout>
              </Tab>
            </Tabs>

            <CardLayout>
              <Form.Group className="mb-3" controlId="body">
                <Form.Label>Body</Form.Label>
                <TextEditor
                  value={formInputs?.body ? formInputs?.body : ""}
                  handleInputChange={(value) => handleInputChangeForEditor(value, 'body')}
                />
              </Form.Group>
            </CardLayout>
          </Col>

          <Col md={4}>
            <CardLayout>
              <Form.Label>Publish</Form.Label>
              <Form.Group as={Row} className="mb-3" controlId="visibility">
                <Form.Label column sm={4}>
                  Visibility
                </Form.Label>
                <Col sm={7}>
                  <Form.Select
                    name="visibility"
                    defaultValue={"public"}
                    value={formInputs?.visibility}
                    onChange={(e) => handleInputChange(e)}
                  >
                    {/* <option selected disabled>
                  Select Visibility...
                </option> */}
                    <option value="public">Public</option>
                    <option value="private">Private</option>
                  </Form.Select>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="published">
                <Form.Label column sm={4}>
                  Published
                </Form.Label>
                <Col sm={7}>
                  {/* <ReactDateRangePicker
                type="single"
                stDate={moment(formInputs?.publishedDate)}
                updateRange={(sDate) => handleInputChange(sDate, "publishedDate")}
              /> */}
                  <Form.Control
                    // min={getCurrentDate()}
                    type="date"
                    name="publishedDate"
                    placeholder="yyyy/mm/dd"
                    value={(formInputs?.publishedDate) ? formInputs?.publishedDate : ""}
                    onChange={e => handleInputChange(e, 'publishedDate')}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="writer">
                <Form.Label column sm={4}>
                  Writer
                </Form.Label>
                <Col sm={7}>
                  <Form.Select
                    name="writer"
                    defaultValue={"admin"}
                    value={formInputs?.writer}
                    onChange={(e) => handleInputChange(e)}
                  >
                    {/* <option selected disabled>
                  Select Writer...
                </option> */}
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                    <option value="other">Other</option>
                  </Form.Select>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="category" /* onChange={(e) => handleInputChange(e)} */>
                <Form.Label>Category</Form.Label>
                <Col>
                  <CustomSelect
                    options={defaultCategory || []}
                    isMulti={true}
                    onChange={(data) => handleInputChange(data, "category")}
                    defaultOption={formInputs?.category}
                    selectedOption={formInputs?.category}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="isInThespotlight">
                <Form.Label>
                  <div className="d-flex align-items-center d-flex mb-2 justify-content-between">
                    In the Spotlight
                    <Form.Check
                      name="checkbox"
                      type="checkbox"
                      checked={formInputs?.isInThespotlight}
                      // onChange={(e) => setCheckBox(!checkBox)}
                      className={"ps-3 d-inline-block cursor-pointer"}
                      readOnly={true}
                    />
                  </div>
                </Form.Label>
                <Col>
                  <Form.Select
                    name="inThespotlightCategory"
                    value={formInputs?.inThespotlightCategory}
                    // defaultValue={"admin"}
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value="" disabled>
                      Select Spotlight Category...
                    </option>
                    <option value="What’s On">What’s On</option>
                    <option value="Next Convention">Next Convention</option>
                    <option value="Latest News">Latest News</option>
                    <option value="Highlight">Highlight</option>
                  </Form.Select>
                </Col>
              </Form.Group>
              {(isCategoryExist(formInputs?.category, "convention-lineup") ||
                isCategoryExist(formInputs?.category, "previous-conventions")) && (
                  <>
                    <Form.Group className="mb-3" controlId="daterange">
                      <Form.Label>Date Range</Form.Label>
                      <ReactDateRangePicker
                        updateRange={(sDate, eDate) => {
                          setFormInputs((prev) => ({ ...prev, dateRangeStart: sDate, dateRangeEnd: eDate }));
                        }}
                        stDate={moment(formInputs?.dateRangeStart)}
                        edDate={moment(formInputs?.dateRangeEnd)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="city">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        name="city"
                        value={formInputs?.city}
                        type="text"
                        placeholder="--- City ---"
                        onChange={(e) => handleInputChange(e)}
                      />
                    </Form.Group>
                  </>
                )}
              <Form.Group className="mb-3" controlId="venue">
                <Form.Label>Venue</Form.Label>
                <Form.Control
                  name="venue"
                  value={formInputs?.venue}
                  type="text"
                  placeholder="--- Venue ---"
                  onChange={(e) => handleInputChange(e)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="targeturl">
                <Form.Label>Target URL</Form.Label>
                <Form.Control
                  name="targeturl"
                  value={formInputs?.targeturl}
                  type="text"
                  placeholder="--- Target URL ---"
                  onChange={(e) => handleInputChange(e)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <label className="d-flex align-items-center d-flex mb-2 cursor-pointer">
                  <Form.Check
                    type="checkbox"
                    checked={formInputs?.isRedirectUrl}
                    className="cursor-pointer"
                    onChange={(e) => setFormInputs(prev => ({ ...prev, isRedirectUrl: !prev.isRedirectUrl }))}
                  />
                  <span className="ps-2">(Redirect to URL)</span>
                </label>
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Featured Image</Form.Label>
                {formInputs?.featureImage &&
                  <button
                    type="button"
                    className="btn btn-danger btn-circle float-end"
                    title='Remove Media'
                    onClick={() => deleteMedia(imageId, 'featureImage')}
                  >
                    X
                  </button>
                }
                <SelectMedia
                  name="featureImage"
                  type={"image"}
                  id={imageId}
                  imgSrc={(formInputs?.featureImage) ? formInputs?.featureImage : ''}
                  updateInputState={setFormInputs}
                />
              </Form.Group>
              <Row>
                <Col sm={6}>
                  <Button
                    className=""
                    variant="success"
                    onClick={preview}
                  >
                    <FaEye className="me-2" />
                    Preview
                  </Button>
                </Col>
                <Col sm={6}>
                  <Button onClick={addNew}>
                    <FaTelegramPlane className="me-2" />
                    Publish
                  </Button>
                </Col>
              </Row>
            </CardLayout>
          </Col>
        </Row>
      }
    </>
  );
}