import { useContext } from "react";
import { Link } from "react-router-dom";
import { DataContext } from "../../context/CustomGlobalContext";

const Header = () => {
  const { refreshToken } = useContext(DataContext);

  return (
    <div id="main-header">
      <nav className="navbar navbar-expand navbar-light bg-white gap-4">
        <button type="button" className="btn btn-light d-none d-xl-flex" data-toggle="mini-sidebar">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <button
          type="button"
          className="btn btn-light d-flex d-xl-none me-3"
          data-bs-toggle="offcanvas"
          data-bs-target="#sidebar"
          aria-controls="sidebar"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <ul className="navbar-nav align-items-center ms-auto">
          <li className="nav-item dropdown">
            {/* <a
              href="#"
              className="nav-link dropdown-toggle no-caret"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-auto-close="outside"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
            </a> */}
            {/* <div className="dropdown-menu dropdown-menu-end p-3">
              <form>
                <input
                  type="text"
                  className="form-control border-0 shadow-none px-3"
                  placeholder="Search..."
                  autofocus
                />
              </form>
              <div className="dropdown-divider"></div>
              <h6 className="dropdown-header d-flex justify-content-between">
                Recently searched:
                <a href="javascript:void(0)" className="text-muted ms-5">
                  Clear
                </a>
              </h6>
              <div className="max-h-[300px] overflow-auto">
                <a className="dropdown-item" href="javascript:void(0)">
                  Calendar
                </a>
                <a className="dropdown-item" href="javascript:void(0)">
                  Chat
                </a>
                <a className="dropdown-item" href="javascript:void(0)">
                  Email
                </a>
                <a className="dropdown-item" href="javascript:void(0)">
                  File manager
                </a>
                <a className="dropdown-item" href="javascript:void(0)">
                  Forum
                </a>
                <a className="dropdown-item" href="javascript:void(0)">
                  Invoice
                </a>
                <a className="dropdown-item" href="javascript:void(0)">
                  Photos
                </a>
                <a className="dropdown-item" href="javascript:void(0)">
                  Pricing
                </a>
                <a className="dropdown-item" href="javascript:void(0)">
                  Todo
                </a>
                <a className="dropdown-item" href="javascript:void(0)">
                  Blog
                </a>
                <a className="dropdown-item" href="javascript:void(0)">
                  Settings
                </a>
                <a className="dropdown-item" href="javascript:void(0)">
                  Profile
                </a>
              </div>
            </div> */}
          </li>
          <li className="nav-item dropdown">
            {/* <a
              href="#"
              className="nav-link dropdown-toggle no-caret"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-auto-close="outside"
            >
              <div className="position-relative">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                </svg>
                <span className="badge bg-danger position-absolute top-0 start-100 translate-middle p-1">
                  <span className="visually-hidden">unread notifications</span>
                </span>
              </div>
            </a> */}
            <div className="dropdown-menu dropdown-menu-end">
              <h6 className="dropdown-header d-flex justify-content-between">
                5 New notifications
                <a href="javascript:void(0)" className="text-muted ms-5">
                  Clear
                </a>
              </h6>
              <div className="dropdown-divider"></div>
              <div className="max-h-[300px] overflow-auto">
                <a className="dropdown-item d-flex align-items-center gap-3 py-2" href="javascript:void(0)">
                  <svg
                    className="text-muted"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                  <div className="vstack">
                    <p className="mb-0">New customer registered</p>
                    <small className="text-muted">5 min ago</small>
                  </div>
                </a>
                <a className="dropdown-item d-flex align-items-center gap-3 py-2" href="javascript:void(0)">
                  <svg
                    className="text-muted"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                    />
                  </svg>
                  <div className="vstack">
                    <p className="mb-0">New order received</p>
                    <small className="text-muted">11 min ago</small>
                  </div>
                </a>
                <a className="dropdown-item d-flex align-items-center gap-3 py-2" href="javascript:void(0)">
                  <svg
                    className="text-muted"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
                    />
                  </svg>
                  <div className="vstack">
                    <p className="mb-0">
                      Plugin updates available <span className="badge rounded-pill ms-1 bg-secondary align-bottom">3</span>
                    </p>
                    <small className="text-muted">30 min ago</small>
                  </div>
                </a>
                <a className="dropdown-item d-flex align-items-center gap-3 py-2" href="javascript:void(0)">
                  <svg
                    className="text-muted"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>
                  <div className="vstack">
                    <p className="mb-0">Download completed</p>
                    <small className="text-muted">35 min ago</small>
                  </div>
                </a>
                <a className="dropdown-item d-flex align-items-center gap-3 py-2" href="javascript:void(0)">
                  <svg
                    className="text-muted"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                    />
                  </svg>
                  <div className="vstack">
                    <p className="mb-0">Weekly usage report</p>
                    <small className="text-muted">40 min ago</small>
                  </div>
                </a>
              </div>
            </div>
          </li>
          <li className="nav-item vr mx-3"></li>
          <li className="nav-item dropdown">
            <a
              href="#"
              className="nav-link dropdown-toggle no-caret py-0 pe-0"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src="/assets/img/user1.svg" width="32" alt="User" className="rounded-circle" loading="lazy" />
            </a>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                {/* <a className="dropdown-item" href="javascript:void(0)">
                  Profile
                </a> */}
                <Link to="/adminProfile" className="me-5 btn" >Profile</Link>
              </li>
              <li>
                {/* <a className="dropdown-item" href="user-settings.html">
                  Settings
                </a> */}
                <Link to="/reset-password" className="me-5 btn" >Settings</Link>
              </li>
              <li>
                <div className="dropdown-divider"></div>
              </li>
              <li>
                <button className="dropdown-item" onClick={() => refreshToken()}>
                  Sign out
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
