import React from 'react';
import { Spinner } from 'react-bootstrap';

const RenderSpinner = () => (
    <div className="overlay position-fixed d-flex justify-content-center align-items-center w-75 h-75">
        <div className="overlay-spinner">
            <Spinner animation="border" variant="primary" />
        </div>
    </div>
);

export default RenderSpinner;