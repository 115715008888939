import React, { useContext, useEffect, useState } from 'react';
import { Form, Row } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import CardLayout from "../../components/Layout/CardLayout";
import PageHeader from "../../components/Shared/Section/PageHeader";
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa'
import ModalComp from '../../components/Shared/components/ModalComp';
import { DataContext } from '../../context/CustomGlobalContext';
import useToasts from '../../components/Toast/toastProvider';
import APIService from '../../utils/APIService';
import { getImageUrl, setRowIndex } from '../../utils/Helper';

export default function OurPartners() {

  const [tablePage, setTablePage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const paginationOption = setRowIndex(setTablePage, setSizePerPage);

  const buildCols = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return ((tablePage - 1) * sizePerPage + rowIndex + 1);
        },
      },
      {
        dataField: "name",
        text: "Our Partners",
        style: () => ({ maxWidth: '150px' })
      },
      {
        dataField: "targeturl",
        text: "Target URL",
        style: () => ({ maxWidth: '250px' })
      },
      {
        dataField: "image",
        text: "Photo",
        formatter: (cell, row) => {
          return (
            <img src={getImageUrl(row?.image)} alt='img' width="100" height="100" />
          )
        }
      },
      // {
      //   dataField: "order",
      //   text: 'Sort Order',
      //   formatter: (cell, row) => (row?.order) ? row.order : '-'
      // },
      {
        dataField: "edit",
        text: 'Edit',
        classes: 'w10',
        formatter: (cell, row) => {
          return (
            <>
              <FaRegEdit className='me-3 cursor-pointer' onClick={() => funUpdateData(row)} />
              <FaTrashAlt className='cursor-pointer' onClick={() => funDeleteData(row)} />
            </>
          )
        }
      },
      {
        dataField: "publish",
        text: 'Publish',
        formatter: (cell, row) => {
          return (
            <input className='cursor-pointer' type='checkbox' name='checkbox' onChange={e => UpdateActiveStatus(row)} defaultChecked={row.publish} />
          )
        }
      },
    ]
    return columnFields;
  }
  const { setPageData } = useContext(DataContext);
  const { toggleToast } = useToasts();

  const [headerData, setHeaderdata] = useState({
    mainHeading: 'Our Partners',
    subHeadng: '',
    buttonText: ['Add New']
  })

  const [tempData, setTempData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState("");
  let manageError = {
    targeturl: "target url",
    order: "order number"
  }
  let initialInputState = {
    name: "",
    targeturl: "",
    publish: true,
    order: (tableData?.length) ? tableData?.length + 1 : 1,
    image: ""
  }
  const [modalInputs, setModalInputs] = useState(initialInputState);
  const formControls = ['name', 'targeturl', 'status', 'shortNo', 'image'];
  const imageId = "settingsOurPartner_image";
  const regexp = /^\d+(-\d+)?(,\d+(-\d+)?)*$/;

  useEffect(() => {
    const filtered = !search
      ? tempData
      : tempData.filter((person) =>
        person.name.toLowerCase().includes(search.toLowerCase()) ||
        person.targeturl.toLowerCase().includes(search.toLowerCase())
      );
    setTableData(filtered);
  }, [search]);

  useEffect(() => {
    fetchTableData();
  }, [])

  const fetchTableData = async () => {
    const res = await APIService.get(`admin/settings/partners`)
    if (res.status === 200) {
      setTableData(res.data.data);
      setTempData(res.data.data);
    }
  }

  const handleInputChange = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    if (key === "search") {
      return setSearch(value);
    }
    setModalInputs({ ...modalInputs, [key]: value });
  }

  const handleModal = () => {
    setShowModal(!showModal);
    setModalInputs(initialInputState);
    setPageData(prev => ({ ...prev, [imageId]: '' }))
  }

  const funDeleteData = (row) => {
    let data = {
      '_id': row._id,
      "isDeleted": true
    }
    partnersCRUD(data);
  }

  const UpdateActiveStatus = (rowData) => {
    let data = {
      _id: rowData._id,
      publish: !rowData.publish,
    }
    partnersCRUD(data);
  }

  let funUpdateData = (rowData) => {
    setModalInputs(rowData);
    setShowModal(!showModal);
    setPageData(prev => ({ ...prev, [imageId]: getImageUrl(rowData?.image) }))
  }

  const partnersCRUD = async (data) => {
    const res = await APIService.post(`admin/settings/partners`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast('success', res.data.message);
      }
      else {
        toggleToast('success', res.data);
      }
      fetchTableData();
    }
  }

  const formValidation = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === undefined) {
          if (manageError.hasOwnProperty(key)) {
            toggleToast('danger', `Please enter ${manageError[key]}`)
            return false;
          }
          toggleToast('danger', `Please enter ${key}`)
          return false;
        }
      }
    }
  }

  const addPartners = async () => {
    let validation = formValidation(modalInputs);
    if (validation !== false) {
      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        ...modalInputs
      }
      partnersCRUD(data);
      handleModal();
    }
  }

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate={handleModal}
      />

      <CardLayout>
        <Form.Group>
          <Form.Control
            name="search"
            placeholder="Search..."
            value={search}
            onChange={e => handleInputChange(e)}
            className='mb-2 w-25 ms-auto'
          ></Form.Control>
        </Form.Group>
        <Row>
          <BootstrapTable className="table-rc" keyField='id' data={tableData} columns={buildCols()} pagination={paginationFactory(paginationOption)} />
        </Row>
      </CardLayout>

      {
        showModal
          ?
          <ModalComp
            imageId={imageId}
            showModal={showModal}
            CUD_API={addPartners}
            handleModal={handleModal}
            formControls={formControls}
            inputState={modalInputs}
            handleInputChange={handleInputChange}
            updateInputState={setModalInputs}
          />
          :
          null
      }

    </>
  )
}
