import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import CardLayout from "../../components/Layout/CardLayout";
import PageHeader from "../../components/Shared/Section/PageHeader";
import useToasts from "../../components/Toast/toastProvider";
import { DataContext } from "../../context/CustomGlobalContext";
import APIService from "../../utils/APIService";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaRegEdit, FaTrashAlt } from "react-icons/fa";
import ModalComp from "../../components/Shared/components/ModalComp";
import SectionBackground from "../../components/Shared/components2/SectionBackground";
import SectionButton from "../../components/Shared/components2/SectionButton";
import SectionImage from "../../components/Shared/components2/SectionImage";
import { getImageUrl, setRowIndex } from "../../utils/Helper";
import { useNavigate } from "react-router";
import TextEditor from "../../components/Shared/components/TextEditor";
const InThesSpotlight = () => {

  const navigate = useNavigate();
  const { toggleToast } = useToasts();
  const { setPageData } = useContext(DataContext);

  const [tablePage, setTablePage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const paginationOption = setRowIndex(setTablePage, setSizePerPage);
  const [buttonState, setButtonState] = useState(false);

  const buildCols = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return ((tablePage - 1) * sizePerPage + rowIndex + 1);
        },
      },
      {
        dataField: "contentsTitle",
        text: "Name",
        style: () => ({ maxWidth: '100px' })
      },
      {
        dataField: "contentsDescription",
        text: "Details",
        style: () => ({ maxWidth: '110px' })
      },
      {
        dataField: "targeturl",
        text: "Target URL",
        formatter: (cell, row) => (row?.targeturl) ? row.targeturl : '-',
        style: () => ({ maxWidth: '150px' })
      },
      {
        dataField: "inThespotlightCategory",
        text: "Category",
        formatter: (cell, row) => (row?.inThespotlightCategory) ? row.inThespotlightCategory : "-"
      },
      {
        dataField: "publishedDate",
        text: "Date",
        formatter: (cell, row) => {
          let date = new Date(row.publishedDate);
          let final = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          return final;
        }
      },
      {
        dataField: "featureImage",
        text: "Photo",
        formatter: (cell, row) => {
          return (
            <img src={getImageUrl(row?.featureImage)} alt='img' width="100" height="100" />
          )
        }
      },
      {
        dataField: "view",
        text: "View",
        formatter: (cell, row) => (row?.view) ? row?.view : 0
      },
      {
        dataField: "edit",
        text: 'Edit',
        formatter: (cell, row) => {
          return (
            <>
              <FaRegEdit className='me-3 cursor-pointer' onClick={() => funUpdateData(row)} />
              <FaTrashAlt className='cursor-pointer' onClick={() => funDeleteData(row)} />
            </>
          )
        }
      },
      {
        dataField: "publish",
        text: 'Publish',
        formatter: (cell, row) => {
          return (
            <input className='cursor-pointer' type='checkbox' name='checkbox' onChange={e => UpdateActiveStatus(row)} checked={row.publish} />
          )
        }
      },
    ]
    return columnFields;
  }

  const headerData = {
    mainHeading: 'In The Spotlight',
    subHeadng: '',
    buttonText: ["Update"]
  }

  // ======================= TABLE FUNCTIONALITY =======================

  const formControls = ['name', 'details', 'targeturl', 'category', 'image'];
  const imageId = 'inTheSpotLight_tableImage';

  const [search, setSearch] = useState("");
  const [tempData, setTempData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  let initialInputState = {
    name: "",
    details: "",
    targeturl: "",
    category: "",
    image: "",
  }
  const [modalInputs, setModalInputs] = useState(initialInputState);

  useEffect(() => {
    const filtered = !search
      ? tempData
      : tempData.filter((person) =>
        person.contentsTitle.toLowerCase().includes(search.toLowerCase()) ||
        person.contentsDescription.toLowerCase().includes(search.toLowerCase()) ||
        person.targeturl.toLowerCase().includes(search.toLowerCase())
      );
    setTableData(filtered);
  }, [search]);

  const fetchTableData = async () => {
    const res = await APIService.get(`/admin/contents/create/in-the-spotlight`)
    if (res.status === 200) {
      setTableData(res.data.data);
      setTempData(res.data.data);
    }
  }

  const inTheSpotLightCRUD = async (data) => {
    const res = await APIService.post(`/admin/contents/create`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast('success', res.data.message);
      }
      else {
        toggleToast('success', res.data);
      }
      fetchTableData();
    }
  }

  const addInTheSpotLight = async () => {
    let validation = formValidation(modalInputs);
    if (validation !== false) {
      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        ...modalInputs
      }
      inTheSpotLightCRUD(data);
      handleModal();
    }
  }

  let funUpdateData = (rowData) => {
    // setModalInputs(rowData);
    // setShowModal(!showModal);
    // setPageData(prev => ({ ...prev, [imageId]: getImageUrl(rowData?.image) }))
    navigate(`/contents/create?id=${rowData._id}&category=in-the-spotlight`);
  }

  const funDeleteData = (row) => {
    const confirmBox = window.confirm(
      "Do you really want to delete?"
    )
    if (confirmBox === true) {
      let data = {
        _id: row._id,
        isDeleted: true
      }
      inTheSpotLightCRUD(data);
    }
  }

  const UpdateActiveStatus = (rowData) => {
    let data = {
      _id: rowData._id,
      publish: !rowData.publish,
    }
    inTheSpotLightCRUD(data);
  }

  const handleInputChange1 = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    if (key === "search") {
      return setSearch(value);
    }
    setModalInputs({ ...modalInputs, [key]: value });
  }

  const formValidation = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === undefined) {
          toggleToast('danger', `Please enter ${key}`)
          return false;
        }
      }
    }
  }

  const handleModal = () => {
    setShowModal(!showModal);
    setModalInputs(initialInputState);
    setPageData(prev => ({ ...prev, [imageId]: '' }))
  }

  // ======================= SECTION FUNCTIONALITY =======================

  const pagename = "homeInTheSpotlight";
  const imageId1 = 'inTheSpotlight_sectionImage';
  const bgImageId1 = 'inTheSpotlight_sectionBgImage';

  const defaultSection = {
    title: "",
    details: "",
    align: "",

    isMediaChecked: false,
    imageUrl: "",
    videoUrl: "",
    isImageChecked: true,
    isVideoChecked: false,

    backgroundUrl: "",
    backgroundColor: "",
    isBackgroundImageChecked: false,
    isBackgroundColorChecked: false,
    isBackgroundChecked: false,

    buttonUrl: "",
    buttonText: "",
    buttonAlign: "",
    buttonColor: "",
    isButtonChecked: false,
  };

  const [pageDataApiResponse, setPageDataApiResponse] = useState();

  useEffect(() => {
    fetchPageData();
    fetchTableData();
  }, []);

  const fetchPageData = async () => {
    const res = await APIService.get(`/admin/page/item?pagename=${pagename}`);
    if (res.status === 200) {
      let allData = res.data?.data?.[0];
      let data = allData.sections?.[0];

      setPageDataApiResponse(allData);
      allData.sections.map((val, i) => {
        allData[`${imageId1}-${i}`] = getImageUrl(val?.imageUrl);
        allData[`${bgImageId1}-${i}`] = getImageUrl(val?.backgroundUrl);
      });
      setPageData(allData);
    }
  };

  const submitData = async () => {
    let payload = { ...pageDataApiResponse };
    const res = await APIService.put(`/admin/page/${pagename}`, { payload });
    if (res.status === 200) {
      toggleToast("success", "Page updated successfully ");
    } else {
      toggleToast("failure", "Page not updated successfully ");
    }
  };

  const updatePageState = (updateSection, index) => {
    let progressBar = updateSection();
    if (progressBar?.progressBar) {
      setButtonState(true);
      return
    }
    let pageData = { ...pageDataApiResponse };
    let updatedSection = updateSection(pageData.sections[index]);
    pageData.sections[index] = updatedSection;
    setPageDataApiResponse(pageData);
    setButtonState(false);
  };

  const handleInputChange = (e, field, index) => {
    let pageData = { ...pageDataApiResponse };
    if (field === "checkbox") {
      pageData.sections[index] = { ...pageData.sections[index], [e.target.name]: e.target.checked };
    } else {
      pageData.sections[index] = { ...pageData.sections[index], [e.target.name]: e.target.value };
    }
    setPageDataApiResponse(pageData);
  };

  const handleInputChangeForEditor = (value, index) => {
    let pageData = { ...pageDataApiResponse };
    pageData.sections[index] = { ...pageData.sections[index], ['details']: value };
    setPageDataApiResponse(pageData);
  }

  const addNewSection = () => {
    let oldpageData = { ...pageDataApiResponse };
    let addNewSection = defaultSection
    let index = pageDataApiResponse?.sections.length;
    oldpageData[`${imageId1}-${index}`] = getImageUrl("");
    oldpageData[`${bgImageId1}-${index}`] = getImageUrl("");
    oldpageData.sections.push(addNewSection);
    setPageDataApiResponse(oldpageData);
    // setPageData({...pageData,sections:oldpageData})
  };

  const deleteSection = (index) => {
    const confirmBox = window.confirm(
      "Do you really want to delete this section?"
    )
    if (confirmBox === true) {
      let oldpageData = { ...pageDataApiResponse };
      let sectionFilter = oldpageData.sections.filter((val, i) => (i !== index));
      oldpageData.sections = sectionFilter;
      setPageDataApiResponse(oldpageData);
    }
  }

  return (
    <>

      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        buttonState={buttonState}
        handleUpdate={submitData}
      />

      {pageDataApiResponse?.sections &&
        pageDataApiResponse?.sections.length > 0 &&
        pageDataApiResponse?.sections.map((localdata, index) => (
          <>
            <CardLayout>
              <Row>
                <Col md={1}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Title</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={9}>
                  <Form className="mb-3" controlId="title">
                    <Form.Control
                      type="text"
                      name="title"
                      value={localdata.title ? localdata.title : ""}
                      onChange={(e) => handleInputChange(e, "title", index)}
                    />
                  </Form>
                </Col>
                <Col md={2}></Col>
              </Row>
              <Row>
                <Col md={1}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Details</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={9}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <TextEditor
                      value={localdata.details ? localdata.details : ""}
                      handleInputChange={(value) => handleInputChangeForEditor(value, index)}
                    />
                  </Form.Group>
                </Col>
                <Col md={2}></Col>
              </Row>
            </CardLayout>

            <CardLayout>
              <Row className="section-config">
                <Col className="offset-md-1" md={3}>
                  <SectionImage
                    localdata={localdata}
                    setLocalData={(updateSection) => updatePageState(updateSection, index)}
                    locationid={"inthe-spotlight-media"}
                    handleInputChange={(e, field) => handleInputChange(e, field, index)}
                    imageId={`${imageId1}-${index}`}
                  />
                </Col>
                <Col className="offset-md-1" md={3}>
                  <SectionBackground
                    index={index}
                    localdata={localdata}
                    setLocalData={(updateSection) => updatePageState(updateSection, index)}
                    locationid={"inthe-spotlight-background-media"}
                    handleInputChange={(e, field) => handleInputChange(e, field, index)}
                    bgImageId={`${bgImageId1}-${index}`}
                  />
                </Col>
                <Col className="offset-md-1" md={3}>
                  <SectionButton
                    localdata={localdata}
                    setLocalData={(updateSection) => updatePageState(updateSection, index)}
                    handleInputChange={(e, field) => handleInputChange(e, field, index)}
                  />
                </Col>
              </Row>
              {
                index && index > 0 && <Button onClick={e => deleteSection(index)} className="mt-5" variant="danger" >Delete Section</Button> || ''
              }
            </CardLayout>
          </>
        ))}

      <div className="add_section mb-3" onClick={addNewSection}>
        <span>add section</span> <span className="add_icon">+</span>
      </div>

      <PageHeader
        buttonText='New Spotlight'
        handleUpdate={() => navigate(`/contents/create?category=in-the-spotlight`)}
      />

      <CardLayout>
        <Form.Group >
          <Form.Control
            name="search"
            placeholder='Search...'
            value={search}
            onChange={e => handleInputChange1(e)}
            className='mb-2 w-25 ms-auto'
          ></Form.Control>
        </Form.Group>

        <Row>
          <BootstrapTable className="table-rc" keyField='id' data={tableData} columns={buildCols()} pagination={paginationFactory(paginationOption)} />
        </Row>
      </CardLayout>
      {/* {showModal ? renderModal() : null} */}
      {
        showModal
          ?
          <ModalComp
            imageId={imageId}
            showModal={showModal}
            CUD_API={addInTheSpotLight}
            handleModal={handleModal}
            formControls={formControls}
            inputState={modalInputs}
            handleInputChange={handleInputChange1}
            updateInputState={setModalInputs}
          />
          :
          null
      }

    </>
  );
};

export default InThesSpotlight;