import React, { useState, useEffect, useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { DataContext } from "../../../context/CustomGlobalContext";
import SelectMedia from "../Media/SelectMedia";

const SectionBackground = ({
  localdata,
  setLocalData,
  locationid,
  handleInputChange,
  bgImageId,
  isDisable = false,
  index
}) => {

  const { pagedata, setPageData } = useContext(DataContext);
  const [mediaType, setMediaType] = useState();
  const [checkboxhandle, setCheckboxHandle] = useState(false)

  useEffect(() => {
    let savedMediaType = (localdata?.isBackgroundColorChecked) ? "color" : "image";
    setMediaType(savedMediaType);
  }, [])

  useEffect(() => {
    if (mediaType === 'color') {
      setLocalData(prev => ({ ...prev, isBackgroundImageChecked: false, isBackgroundColorChecked: true, backgroundColor: prev?.backgroundColor }))
    }
    else {
      setLocalData(prev => {
        return ({ ...prev, isBackgroundImageChecked: true, isBackgroundColorChecked: false, backgroundUrl: prev?.backgroundUrl || "" })
      })
    }
  }, [mediaType])

  const deleteSectionMedia = () => {
    let mediaName = 'backgroundUrl';
    setLocalData(prev => ({ ...prev, [mediaName]: '' }));
    setPageData(prev => {
      return { ...prev, [bgImageId]: '' }
    });
  }

  return (
    <Row className="d-flex justify-content-center">
      <Row className="d-flex justify-content-between mb-2">
        <Col className="section-primary">Background</Col>
        <Col className="d-flex justify-content-end align-items-center">
          <input
            title={isDisable ? "Disabled" : ''}
            disabled={isDisable}
            type="checkbox"
            className="cursor-pointer"
            name="isBackgroundChecked"
            value={checkboxhandle}
            checked={localdata.isBackgroundChecked}
            onChange={e => handleInputChange(e, "checkbox")}
          />
        </Col>
        {
          (mediaType === "image" && localdata?.backgroundUrl) &&
          <Col>
            <button
              type="button"
              className="btn btn-danger btn-circle float-end"
              title='Remove Media'
              onClick={deleteSectionMedia}
            >
              X
            </button>
          </Col>
        }
      </Row>
      <Row>
        <SelectMedia
          type={mediaType}
          name={(mediaType === "image") ? "backgroundUrl" : "backgroundColor"}
          id={bgImageId}
          imgSrc={(localdata?.backgroundUrl) ? localdata?.backgroundUrl : ''}
          updateInputState={setLocalData}
          localdata={localdata}
          isFor="backgroundImage"
        />
      </Row>
      <Row>
        <div className="mt-2">
          <Form
            className="d-flex justify-content-between"
          >
            <Form.Check
              inline
              label="Image"
              name="image"
              type={"radio"}
              value="image"
              checked={localdata.isBackgroundImageChecked}
              onChange={e => setMediaType('image')}
            />
            <Form.Check
              inline
              label="Color"
              name="color"
              type={"radio"}
              value="color"
              checked={localdata.isBackgroundColorChecked}
              onChange={e => setMediaType('color')}
            />

          </Form>
        </div>
      </Row>
    </Row>
  );
};

export default SectionBackground;