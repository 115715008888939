import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { FaEye, FaTelegramPlane } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import CardLayout from '../../components/Layout/CardLayout';
import SelectMedia from '../../components/Shared/Media/SelectMedia';
import PageHeader from "../../components/Shared/Section/PageHeader";
import useToasts from '../../components/Toast/toastProvider';
import { DataContext } from '../../context/CustomGlobalContext';
import APIService from '../../utils/APIService';
import { getImageUrl } from '../../utils/Helper';
import TextEditor from "../../components/Shared/components/TextEditor";
export default function RobustindustriesCreate() {

  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const { setPageData } = useContext(DataContext);
  const { toggleToast } = useToasts()
  const imageId = "create_robustIndustry_pageImage";
  const urlId = searchParams.get("id");

  const headerData = {
    mainHeading: 'Robust Industry',
    subHeadng: 'Create',
    buttonText: []
  };

  let manageError = {
    industrytitle: "industry title",
    industrydescription: "industry description",
    seotitle: "seo title",
    seokeywords: "seo key words",
    seodescription: "seo description"
  }

  let initialInputState = {
    industrytitle: "",
    industrydescription: "",
    seotitle: "",
    seokeywords: "",
    seodescription: "",
    body: "",
    visibility: "",
    published: "",
    writer: "",
    ambassadors: [],
    image: "",
  }
  const [formInputs, setFormInputs] = useState(initialInputState);
  const [loadind, setLoading] = useState(false);
  const [dropDownData, setDropDownData] = useState([]);

  useEffect(() => {
    if (urlId) {
      fetchPageData();
    }
  }, [urlId])

  const fetchPageData = async () => {
    setLoading(true);
    const res = await APIService.get(`/admin/why-thailand/robust-industries/${urlId}`)
    if (res.status === 200) {
      let allData = res?.data?.data;
      setFormInputs(allData?.[0]);
      setPageData(prev => ({ ...prev, [imageId]: getImageUrl(allData?.[0]?.image) }));
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchAmbassadorsList();
  }, [])

  const fetchAmbassadorsList = async () => {
    const res = await APIService.get(`/admin/convention/ambassadors`)
    if (res.status === 200) {
      let allName = (res?.data?.data && res?.data?.data.length > 0 && res?.data?.data.map(e => e.name)
      )
      setDropDownData(allName);
    }
  }

  const handleInputChange = (e) => {
    let key = e?.target?.name;
    let value = e?.target?.value;
    if (!key) {
      return setFormInputs({ ...formInputs, ['body']: e });
    }
    else if (key === "ambassadors") {
      let val = Array.from(e.target.selectedOptions, option => option.value);
      return setFormInputs({ ...formInputs, ['ambassadors']: [...val] });
    }
    setFormInputs({ ...formInputs, [key]: value });
  }
  const handleInputChangeForEditor = (value) => {
    setFormInputs({ ...formInputs, ['body']: value })
  }

  const robustIndustriesCRUD = async (data) => {
    const res = await APIService.post(`/admin/why-thailand/robust-industries`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast('success', res.data.message);
      }
      else {
        toggleToast('success', res.data);
      }
      setFormInputs(initialInputState);
      setPageData(prev => ({ ...prev, [imageId]: '' }))
      navigate(`/why-thailand/robust-industries`);
    }
  }

  const addNewRobustIndustry = async () => {
    let validation = formValidation(formInputs);
    if (validation !== false) {
      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        ...formInputs
      }
      robustIndustriesCRUD(data);
    }
  }

  const formValidation = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === undefined) {
          if (manageError.hasOwnProperty(key)) {
            toggleToast('danger', `Please enter ${manageError[key]}`)
            return false;
          }
          toggleToast('danger', `Please enter ${key}`)
          return false;
        }
      }
    }
  }

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate=""
      />
      {
        (!loadind)
        &&
        <Row>
          <Col md={8}>
            <Tabs
              defaultActiveKey="industry"
            >
              <Tab eventKey="industry" title="Industry">
                <CardLayout>
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="industry_title"
                    >
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        name="industrytitle"
                        type="text"
                        value={(formInputs.industrytitle) ? formInputs.industrytitle : ''}
                        onChange={e => handleInputChange(e)}
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="industry_description"
                    >
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        name="industrydescription"
                        value={formInputs.industrydescription}
                        as="textarea"
                        rows={2}
                        style={{ resize: "none" }}
                        onChange={e => handleInputChange(e)}
                      />
                    </Form.Group>

                  </Form>
                </CardLayout>
              </Tab>
              <Tab eventKey="seo" title="SEO">
                <CardLayout>
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="seo_title"
                    >
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        name="seotitle"
                        type="text"
                        value={formInputs.seotitle}
                        onChange={e => handleInputChange(e)}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="seo_keywords"
                    >
                      <Form.Label>Keywords</Form.Label>
                      <Form.Control
                        name="seokeywords"
                        type="text"
                        value={formInputs.seokeywords}
                        onChange={e => handleInputChange(e)}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="seo_description"
                    >
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        name="seodescription"
                        as="textarea"
                        rows={2}
                        style={{ resize: "none" }}
                        value={formInputs.seodescription}
                        onChange={e => handleInputChange(e)}
                      />
                    </Form.Group>
                  </Form>
                </CardLayout>
              </Tab>
            </Tabs>

            <CardLayout>
              <Form.Group
                className="mb-3"
                controlId="body"
              >
                <Form.Label>Body</Form.Label>
                 <TextEditor
                    value={formInputs.body ? formInputs.body : ""}
                    handleInputChange={(value) => handleInputChangeForEditor(value)}
                  />
              </Form.Group>
            </CardLayout>
          </Col>

          <Col md={4}>
            <CardLayout>

              <Form.Label column sm={2}>
                Publish
              </Form.Label>

              <Form.Group as={Row} className="mb-3" controlId="visibility">
                <Form.Label column sm={4}>Visibility</Form.Label>
                <Col sm={7}>
                  <Form.Select name='visibility' onChange={e => handleInputChange(e)} >
                    <option selected disabled >Select Visibility...</option>
                    <option value="public" selected={(formInputs.visibility === 'public') ? true : false} >Public</option>
                    <option value="private" selected={(formInputs.visibility === 'private') ? true : false} >Private</option>
                    <option value="none" selected={(formInputs.visibility === 'none') ? true : false} >None</option>
                  </Form.Select>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="published">
                <Form.Label column sm={4}>
                  Published
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    name="published"
                    type="date"
                    value={formInputs.published}
                    placeholder="yyyy/mm/dd"
                    onChange={e => handleInputChange(e)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="writer">
                <Form.Label column sm={4}>Writer</Form.Label>
                <Col sm={7}>
                  <Form.Select name="writer" onChange={e => handleInputChange(e)} >
                    <option selected disabled >Select Writer...</option>
                    <option value="admin" selected={(formInputs.writer === 'admin') ? true : false} >Admin</option>
                    <option value="user" selected={(formInputs.writer === 'user') ? true : false}>User</option>
                    <option value="other" selected={(formInputs.writer === 'other') ? true : false}>Other</option>
                  </Form.Select>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="ambassadors">
                <Form.Label column sm={4}>Ambassadors</Form.Label>
                <Col sm={7}>
                  <Form.Select name='ambassadors' onChange={e => handleInputChange(e)} >
                    <option selected={(formInputs.ambassadors) ? false : true} disabled >Select...</option>
                    {
                      dropDownData && dropDownData.length > 0 && dropDownData.map((val, i) => {
                        return (
                          <option key={i} value={val} selected={(formInputs.ambassadors.includes(val)) ? true : false} >{val}</option>
                        )
                      })
                    }
                  </Form.Select>
                </Col>
              </Form.Group>

              <Row>
                <Col>
                  <Button variant='success' ><FaEye className='me-2' />Preview</Button>
                </Col>
                <Col>
                  <Button onClick={addNewRobustIndustry} ><FaTelegramPlane className='me-2' />Publish</Button>
                </Col>
              </Row>

            </CardLayout>

            <CardLayout>
              <Form.Group
                className="mb-3"
                controlId="featured-image"
              >
                <Form.Label>Featured Image</Form.Label>
                <SelectMedia
                  type={'image'}
                  name='image'
                  id={imageId}
                  imgSrc={(formInputs?.image) ? formInputs?.image : ''}
                  updateInputState={setFormInputs}
                />
              </Form.Group>
            </CardLayout>

          </Col>
        </Row>
      }
    </>
  )
}
