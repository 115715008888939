import React, { useContext, useEffect, useState } from "react";
import { Col, Form, ProgressBar, Row } from "react-bootstrap";
import { DataContext } from "../../../context/CustomGlobalContext";
import { getImageUrl } from "../../../utils/Helper";
import SelectMedia from "../Media/SelectMedia";

const SectionImage = ({
  localdata,
  setLocalData,
  locationid,
  handleInputChange,
  imageId,
  isDisable = false
}) => {

  const { pagedata, setPageData } = useContext(DataContext);
  const [mediaType, setMediaType] = useState();
  const [checkboxhandle, setCheckboxHandle] = useState(false)
  const [locId, setLocId] = useState()
  const [progress, setProgress] = useState();

  useEffect(() => {
    let savedMediaType = (localdata?.isImageChecked) ? "image" : "video";
    setMediaType(savedMediaType);
    let updateContext = (localdata?.isImageChecked) ? localdata?.imageUrl : localdata?.videoUrl;
    setPageData(prev => ({ ...prev, [imageId]: getImageUrl(updateContext) }))
  }, [])

  useEffect(() => {
    let updateContext = (mediaType === "image") ? localdata?.imageUrl : localdata?.videoUrl;
    setPageData(prev => ({ ...prev, [imageId]: getImageUrl(updateContext) }))

    if (mediaType === "video") {
      setLocalData(prev => ({ ...prev, isVideoChecked: true, isImageChecked: false }));
    }
    else {
      setLocalData(prev => ({ ...prev, isVideoChecked: false, isImageChecked: true }));
    }
  }, [mediaType])

  const deleteSectionMedia = () => {
    let mediaName = (mediaType === "image") ? "imageUrl" : "videoUrl";
    setLocalData(prev => ({ ...prev, [mediaName]: '' }));
    setPageData(prev => {
      return { ...prev, [imageId]: '' }
    });
  }

  const createProgressBar = (data) => {
    let progressBar = data();
    if (progressBar?.progressBar) {
      setProgress(progressBar?.progressBar);
      return
    }
    setProgress();
  };

  const localdataSource = (mediaType === "image") ? "imageUrl" : "videoUrl";

  return (
    <Row className="d-flex justify-content-center">
      <Row className="mb-2 p-0" >
        <Col className="section-primary" md={4}>Image</Col>
        <Col className="p-0" md={4}>
          <Form.Select disabled={checkboxhandle}
            name="align"
            size="sm"
            value={localdata?.align ? localdata.align : ''}
            onChange={e => handleInputChange(e)}
          >
            <option disabled value="default">
              {" "}
              --Align--{" "}
            </option>
            <option value="left">Left</option>
            <option value="right">Right</option>
            <option value="center">Center</option>
          </Form.Select>
        </Col>
        <Col className="pt-1 text-center" md={2}>
          <input
            title={isDisable ? "Disabled" : ''}
            disabled={isDisable}
            type="checkbox"
            className="cursor-pointer"
            name="isMediaChecked"
            value={localdata.isMediaChecked}
            checked={localdata.isMediaChecked}
            onChange={e => handleInputChange(e, "checkbox")}
          />
        </Col>
        {
          localdata?.[localdataSource] &&
          <Col className="p-0 text-center" md={2}>
            <button
              type="button"
              className="btn btn-danger btn-circle"
              title='Remove Media'
              onClick={deleteSectionMedia}
            >
              X
            </button>
          </Col>
        }
      </Row>
      {progress && <ProgressBar animated now={progress} label={`${progress}%`} className='w-100 mb-2' />}
      <Row>
        <SelectMedia
          type={mediaType}
          name={(mediaType === "image") ? "imageUrl" : "videoUrl"}
          id={imageId}
          imgSrc={(localdata?.media) ? localdata?.media : ''}
          updateInputState={data => {
            createProgressBar(data);
            let locally = data();
            return setLocalData(prev => ({ ...prev, ...locally }))
          }}
          isFor="sectionImage"
        />
      </Row>

      <Row>
        <div className="mt-2">
          <Form
            className="d-flex justify-content-between"
          >
            <Form.Check
              inline
              label="Image"
              name="group1"
              type="radio"
              className="cursor-pointer"
              value="image"
              checked={mediaType === "image"}
              onChange={(e) => {
                setMediaType(e.target.value);
              }}
            />
            <Form.Check
              inline
              label="Video"
              name="group1"
              type="radio"
              className="cursor-pointer"
              value="video"
              checked={mediaType === "video"}
              onChange={(e) => {
                setMediaType(e.target.value);
              }}
            />
          </Form>
        </div>
      </Row>
    </Row>
  );
};

export default SectionImage;